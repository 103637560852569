import { Component, OnInit } from "@angular/core";
import { RouterEventsService } from "@shared/services/routing/router-events.service";

@Component({
  selector: "jbid-root",
  templateUrl: "app.component.html"
})
export class AppComponent implements OnInit {

  constructor(private urlService: RouterEventsService) {}

  ngOnInit(): void {
    this.urlService.setUpRouting();
  }
}
