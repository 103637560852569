import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";

@Component({
  selector: 'jbid-form-error-display',
  templateUrl: 'app-form-control-error-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFormControlErrorDisplayComponent {
  _text = "";
  _hide = true;

  @Input() set text(value: string) {
    if (value !== this._text) {
      this._text = value;
      this._hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
