import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "jbid-form-required-information",
  templateUrl: "app-form-required-information.component.html",
  styleUrls: ["app-form-required-information.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFormRequiredInformationComponent {
  @Input()
  content = 'All fields marked with a <span class="text-primary">*</span> are mandatory fields.';

  @Input()
  visible = true;
}
