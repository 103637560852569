import {Component, HostListener, OnDestroy, OnInit, signal} from "@angular/core";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {JobidCardService} from './services/jobid-card.service';
import {JobCardCreationService} from '../../../shared/services/job-card-creation/job-card-creation.service';
import {ProfileService} from '../../../shared/services/profile/profile.service';
import {filter, takeUntil} from "rxjs/operators";
import {JobCardMasterService} from '../../../shared/services/job-card-master/job-card-master.service';
import {environment} from '../../../../environments/environment';
import {JobidCardDetailService, ViewMode} from '../../../shared/services/jobid-card-detail/jobid-card-detail.service';
import {ExportExcelService} from '../../../shared/services/export-excel/export-excel.service';
import {TranslateService} from "@ngx-translate/core";
import {Tab} from '../../../shared/components/activities-navbar/activities-navbar.component';

@Component({
  selector: "jbid-card-list",
  templateUrl: "./jobid-card-list.component.html"
})
export class JobidCardListComponent implements OnDestroy, OnInit {
  protected readonly environment = environment;

  private readonly JAVA_MAX_INT = 2^32 - 1;

  /** Unsubscribe from observable streams when the component is destroyed or when the infinite scroll datasource is destoyed. */
  private readonly destroy = new Subject<void>();
  selectedLines = signal<number>(0);
  readonly JOBCARDS = Tab.JOBCARDS
  userHasAffiliates = false;
  selectedIds: string[] = [];
  _linkModeActive = false;
  private readonly view: ViewMode = "view";
  private affiliate: string = '';
  readonly JOB_CARDS_SHEET_NAME = "Job cards";
  readonly JOB_CARDS_EXPORT_FILE_NAME = "Job cards list.xlsx";

  constructor(
    private readonly router: Router,
    private readonly jobidCardService: JobidCardService,
    public readonly jobCardMasterService: JobCardMasterService,
    private readonly profileService: ProfileService,
    public readonly jobidCardDetailService: JobidCardDetailService,
    public readonly exportExcelService: ExportExcelService,
    private readonly translate: TranslateService,
    private readonly jobCardCreationService: JobCardCreationService
  ) {
  }

  @HostListener("unloaded")
  ngOnDestroy() {
    this.jobCardMasterService.activeLinkMode(false);
    this.destroy.next();
    this.destroy.complete();
  }

  openJobCardModal(): void {
    this.jobCardCreationService.openCreateJobCardModal();
  }

  openLinkToJobCardMasterModal() {
    this.jobCardMasterService.createLinkJobCardToJobCardMasterModal();
  }

  ngOnInit() {
    this.profileService.noAffiliates$.pipe(takeUntil(this.destroy)).subscribe((noAffiliates) => {
      this.userHasAffiliates = !noAffiliates;
    });
    this.profileService.affiliate$.pipe(filter(Boolean), takeUntil(this.destroy)).subscribe((affiliate) => {
      this.affiliate = affiliate.value;
    });
    this.jobCardMasterService.linkModeActive$.pipe(takeUntil(this.destroy)).subscribe((active) => {
      this._linkModeActive = active;
    });
    this.jobidCardDetailService.changeViewMode(this.view);
  }

  exportToExcel() {
    this.jobidCardService.readJobCards(this.affiliate, 0, this.JAVA_MAX_INT, []).subscribe(
      (data) => {
        const formattedData = this.prepareData(data.jobCardsAggregate);
        this.exportExcelService.exportToExcel(
          formattedData,
          this.JOB_CARDS_SHEET_NAME,
          this.JOB_CARDS_EXPORT_FILE_NAME,
          "Job cards exported successfully",
        );
      }
    )
  }

  goToJobCardDetails(id: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/jobid-card/detail/${this.affiliate}/${id}/general`]),
    );
    window.open(url, "_blank");
  }

  private dependency(isLeader: boolean, parentId: boolean) {
    if (isLeader) {
      return this.translate.instant("jobCardList.leader");
    } else if (parentId !== null && parentId !== undefined) {
      return this.translate.instant("jobCardList.dependent");
    } else {
      return this.translate.instant("jobCardList.none");
    }
  }

  private prepareData(rowDataList: any[]): any[] {
    return rowDataList.map((rowData: any) => {
      return {
        id: rowData.id,
        jobTitle: rowData.jobTitle,
        jobCardMasterTitle: rowData.jobCardMasterTitle,
        dependency: this.dependency(rowData.isLeader, rowData.parentId),
        jobCardMasterId: rowData.jobCardMasterId,
        masterClassification: rowData.masterClassification,
        workOrderNumber: rowData.workOrderNumber,
        priority: rowData.priority,
        sitePlatform: rowData.sitePlatform,
        generalStatus: rowData.generalStatus,
        actualStatus: rowData.actualStatus,
        potentialReadiness: rowData.potentialReadiness,
        startDate: rowData.startDate,
        endDate: rowData.endDate,
        owner: rowData.owner,
        discipline: rowData.discipline,
        revisionCode: rowData.revisionCode,
        masterNotification: rowData.masterNotification,
        workOrderUserStatus: rowData.workOrderUserStatus,
        workOrderSystemStatus: rowData.workOrderSystemStatus,
        functionalLocation: rowData.functionalLocation,
        attributes: rowData.attributes,
        dgsValue: rowData.dgsValue,
        lotiValue: rowData.lotiValue,
        campaignOption: rowData.campaignOption,
        workOrderType: rowData.workOrderType,
        maximumPob: rowData.maximumPob,
        fromSap: rowData.fromSap,
        jobLeader: rowData.jobLeader,
        creationDate: rowData.creationDate,
      };
    });
  }

  updateSelectedLines(selectedLines: number): void {
    this.selectedLines.set(selectedLines);
  }
}
