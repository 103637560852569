
export type JobCardLinkOperationContext = {
  linkDestinationJobCardMaster: { title: string; id: string } | null;
  jobCardIdsToLinkList: string[];
};

export type JobCardUnLinkOperationContext = {
  jobCardIdsToUnlinkList: string[];
};

export interface Classification {
  label: string;
  count: number;
  countReady: number;
  countAwaitingPreparation: number;
  countDelayed: number;
  countReadyToActivate: number;
  countDraft: number;
}

export enum ClassificationLabels {
  DRIVER = "DRIVER",
  BUILDING_BLOCK = "BUILDING_BLOCK",
  OPPORTUNITY = "OPPORTUNITY",
  NOT_DEFINED = "NOT DEFINED",
}

export type JobCardMasterKPIs = {
  jobCardMasterImpactKPISitePlateform: { label: string; count: number }[];
  jobCardMasterImpactKPIDisciplineCategory: { label: string; count: number }[];
  jobCardMasterKPIMasterClassification: Classification[];
};

export type JobCardMasterHighlights = {
  scaffoldingOverlaps: OverlapByFloc[];
  liftingOverlaps: OverlapByFloc[];
  isolationOverlaps: OverlapByFloc[];
  inhibitionOverlaps: OverlapByFloc[];
  temporaryEquipmentOverlaps: OverlapByFloc[];
};

export type OverlapByFloc = {
  baseFloc: string;
  jobCardList: JobCardOverlap[];
}

export type JobCardOverlap = {
  id: number;
  jobTitle: string;
  functionalLocation: string;
  startDate: Date;
  endDate: Date
};

export type JobCardMasterAggregateDTO = {
  id: number
  title: string
  actualStatus: string
  affiliate: string
  startDate: Date
  endDate: Date
  owner: string
  nbrOfJobCards: never
  isScopeFrozen: boolean
  jobCards: JobCardReducedInfoDTO[]
}

export type JobCardReducedInfoDTO = {
  id: number
  jobTitle: string
  actualStatus: string
  maximumPob: number
  workOrderId: number
}

export type JobCardsMasterPage = {
  jobCardMastersList: JobCardMasterAggregateDTO[]
  totalItems: number
  totalPages: number
  currentPage: number
}
