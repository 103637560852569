import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteInfo } from '@shared/models/siteplatform/site-platform.model';
import { ProfileService, SitePlatformsService } from '@shared/services';
import { JobidCardDetailService } from '@shared/services/jobid-card-detail/jobid-card-detail.service';
import { buildSearchFunction } from '@shared/utils';
import { ToastrService } from 'ngx-toastr';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'jbid-duplication-dialog',
  templateUrl: './duplication-dialog.component.html',
  styleUrls: ['./duplication-dialog.component.css']
})
export class DuplicationDialogComponent implements OnInit, OnDestroy {

  readonly INVALID_WORK_ORDER = 'This work order number is invalid.';
  readonly WORK_ORDER_ALREADY_USED = 'This work order is already used by another job card.';

  invalidWorkOrder = false;
  workOrderAlreadyUsed = false;

  private destroy = new Subject<void>();
  private numberOfDigitsWorkOrder = 0;

  siteInfos: SiteInfo[] = [];
  duplicateFormGroup: FormGroup = new FormGroup({
    site: new FormControl('', [Validators.required, this.validSiteName()]),
    sapWorkOrder: new FormControl('', this.validWorkOrder()),
    jobCardName: new FormControl('', Validators.required),
  });
  search = buildSearchFunction((term: string) =>
    term === ""
      ? []
      : this.siteInfos
          .filter((v) => v.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10)
          .map((v) => v.displayName),
  );
  siteFormatter = (result: string) => result.toUpperCase();


  constructor(private sitePlatformService: SitePlatformsService, private activeModal: NgbActiveModal, private profileService: ProfileService, private jobcardService: JobidCardDetailService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.sitePlatformService.siteInfoDefaults$.pipe(takeUntil(this.destroy)).subscribe((siteInfos) => {
      this.siteInfos = siteInfos;
    });
    this.profileService.affiliateConfig$.pipe(takeUntil(this.destroy)).subscribe((affiliateConfig) => {
      this.numberOfDigitsWorkOrder = affiliateConfig.workOrderDigits;
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onDuplicate() {
    this.invalidWorkOrder = false;
    this.workOrderAlreadyUsed = false;
    const site = this.duplicateFormGroup.controls.site.value;
    const workOrder = this.duplicateFormGroup.controls.sapWorkOrder.value;
    const jobCardName = this.duplicateFormGroup.controls.jobCardName.value;
    if (workOrder.trim()) {
      this.jobcardService.loadWorkorderJobCardLink(workOrder).pipe(map(res => res.data?.findJobCardAndWorkOrderLink)).subscribe((workOrderDetails) => {
        if (!workOrderDetails) {
          this.invalidWorkOrder = true;
        } else if (workOrderDetails.id > 0) {
          this.workOrderAlreadyUsed = true;
        } else {
          this.duplicateJobCard(workOrder, jobCardName, site);
        }
      });
    } else {
      this.duplicateJobCard(null, jobCardName, site);
    }
  }

  duplicateJobCard(workOrder: string | null, jobCardName: string, site: string): void {
    this.jobcardService.duplicateJobCard(site, workOrder, jobCardName).subscribe({
      next: (res) => {
        this.activeModal.close(res.data?.duplicateJobCard?.jobCardBasicInfoDTO?.id);
        this.toastrService.success('You can now review the information in the duplicated job card', 'Job card duplicated successfully!');
      },
      error: (err) => {
        this.toastrService.error('An error occurred while duplicating the job card. Please try again later.', 'Error');
        console.error(err);
      }
  });
  }

  validSiteName(): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.siteInfos.findIndex((v) => v.displayName === control.value) === -1) {
        return { invalidSite: true };
      }
      return null;
    };
  }

  validWorkOrder(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value.trim().length > 0 && control.value.trim().length !== this.numberOfDigitsWorkOrder) {
        return { invalidWorkOrder: true };
      }
      return null;
    };
  }

}
