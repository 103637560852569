<div class="container-fluid d-flex w-100 header-with-tabs-for-desktop">
  <!-- TotalEnergies Logo -->
  <a class="navbar-brand text-primary" routerLink="/">
    <img src="/assets/design-system/TotalEnergies_logo_2x.png" alt="" />
  </a>

  <div class="w-100">
    <div class="container-fluid d-flex pt-2">
      <!-- Application Name -->
      <span class="text-primary d-none d-md-block align-middle">Job ID Card</span>

      <!-- Navbar content -->
      <ul class="navbar-nav ms-auto d-flex flex-row">
        <li class="nav-item">
          <div class="d-flex ms-md-4 flex-row">
            <span class="navbar-text ms-2 d-none d-md-inline">{{'common.affiliate' | translate}}:</span>

            <div ngbDropdown class="dropdown d-inline-block" *ngIf="isJobCardListRoute && affiliates.length > 1">
              <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="affiliate" aria-expanded="false">
                {{ selectedAffiliate?.name | titlecase }}
              </button>
              <div
                *ngIf="affiliates.length > 1"
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="affiliate"
              >
                <button
                  class="dropdown-item affiliate"
                  *ngFor="let affiliate of affiliates"
                  ngbDropdownItem
                  (click)="changeAffiliate(affiliate)"
                >
                  {{ affiliate.name }}
                </button>
              </div>
            </div>
            <div
              id="onlyAffiliate"
              class="navbar-text ms-2 d-none d-md-inline"
              *ngIf="!isJobCardListRoute || affiliates.length <= 1"
            >
              {{ selectedAffiliate?.name | titlecase }}
            </div>
          </div>
        </li>
        <li class="nav-item">
          <div class="d-flex ms-md-4 flex-row">
            <span class="navbar-text ms-2 d-none d-md-inline data-hj-suppress">{{ userName$ | async }}</span>
            <div ngbDropdown class="dropdown">
              <button ngbDropdownToggle class="btn btn-tertiary dropdown-toggle" id="userAccount" type="button" aria-expanded="false">
                <span class="material-icons-outlined">account_circle</span>
              </button>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="userAccount">
                <a ngbDropdownItem class="dropdown-item text-end" id="admin" (click)="goToAdminPage()"
                   *ngIf="displayAdminLink">{{ 'common.admin' | translate }}</a>
                <a ngbDropdownItem class="dropdown-item text-end" id="logout" (click)="logout()">{{ 'common.logout' | translate }}</a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item" *ngIf="translationEnabled">
          <div class="d-flex ms-md-2 flex-row">
            <div ngbDropdown class="dropdown d-inline-block">
              <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="language" aria-expanded="false">
                {{ currentLanguage | titlecase }}
              </button>
              <div
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="language"
              >
                <button
                  class="dropdown-item"
                  (click)="changeLanguage(Language.EN)"
                  [ngClass]="{ active: currentLanguage == Language.EN }">
                  {{ 'common.en' | translate }}
                </button>
                <button
                  class="dropdown-item"
                  (click)="changeLanguage(Language.FR)"
                  [ngClass]="{ active: currentLanguage == Language.FR }">
                  {{ 'common.fr' | translate }}
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <hr class="separator" />
    <div class="container-fluid">
      <!-- Tab menu -->
      <ul class="nav nav-tabs bg-white">
        <li class="nav-item" *ngFor="let tab of jobCardTabsHeader; let i = index">
          <a class="nav-link text-capitalize"
             id="tab-{{ tab.id }}"
             [ngClass]="{'active': i === selectedTabIndex}"
             [attr.aria-controls]="tab.id"
             (click)="selectTab(i)"
             [routerLink]="tab.route"
          > {{ tab.title | translate}} </a>
        </li>
      </ul>
    </div>
  </div>
</div>
