import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, of, Subject } from "rxjs";
import { catchError, distinctUntilChanged, finalize, map, switchMap, takeUntil } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  GeneralStatus,
  JobCardCreationInput,
  JobCardDetailsInput,
  JobCardDuplicationInput,
  JobidCard,
} from "@shared/models/jobid-card/jobid-card.model";
import {
  AppYesNoModalComponent,
  YesNoModalContent,
} from "@shared/components/modal/app-yes-no-modal/app-yes-no-modal.component";
import { ToastrService } from "ngx-toastr";
import { JobidCardDetailHelperService } from "@shared/services/jobid-card-detail/jobid-card-detail-helper.service";
import { DocumentLinkModalComponent } from "../../components/modal/document-link-modal/document-link-modal.component";
import { JobCardGeneralDetailsAggregate } from "@shared/models/jobid-card/GeneralDetailsAggregate.model";
import {
  WorkOrderDetails,
  WorkOrderMaterial,
  WorkOrderPrerequisite,
} from "@shared/models/work-orders/workorder-details.model";
import { GET_GENERAL_DETAILS_QUERY } from "@graphql/jobid-card/detail/general-details/queries/general-details.gql";
import { Apollo } from "apollo-angular";
import { GET_WORK_ORDER_DETAILS_QUERY } from "@graphql/jobid-card/detail/general-details/queries/work-order-details.gql";
import { GeneralDetailsFormService } from "../general-details/general-details-form.service";
import { Prerequisites } from "@shared/models/prerequisites/prerequisites.model";
import { GET_PREREQUISITES_QUERY } from "@graphql/jobid-card/detail/prerequisites/queries/prerequisites.gql";
import { JOB_CARD_CREATION_MUTATION } from "@graphql/jobid-card/mutation/job-card-creation-mutation.gql";
import { InsightsService, ProfileService, SitePlatformsService } from "@shared/services";
import { JOB_CARD_DETAILS_UPDATE_MUTATION } from "@graphql/jobid-card/mutation/job-card-details-update-mutation.gql";
import { CHECK_WORK_ORDER_JOB_CARD_CREATION_QUERY } from "@graphql/jobid-card/queries/check-work-order-job_card-creation.gql";
import { ApolloQueryResult } from "@apollo/client";
import { IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { GET_MOBILIZATIONS_QUERY } from "@graphql/jobid-card/detail/mobilizations/queries/mobilizations.gql";
import { GET_MOBILIZATIONS_LAST_SCAN } from "@graphql/jobid-card/detail/mobilizations/queries/last-scan.gql";
import { UPDATE_JOB_CARD_CLASSIFICATION_MUTATION } from "@graphql/jobid-card/detail/jobcard-master/mutation/editClassification.gql";
import { GET_JOB_CARD_HISTORY_PAGE } from "@graphql/jobid-card/detail/history/job-card-history.gql";
import { UPDATE_WORKORDERS_IN_SAP } from "@graphql/jobid-card/detail/general-details/queries/sapWriteBack.gql";
import { CREATE_JOB_CARDS_BY_REVISON_CODE } from "@graphql/job-card-master/queries/massive_create_job_cards.gql";
import { DELETE_JOBCARD_MUTATION } from "@graphql/jobid-card/mutation/job-card-delete.gql";
import { DUPLICATE_JOB_CARD_MUTATION } from "@graphql/jobid-card/mutation/duplicate-job-card.gql";
import { Material } from "@shared/models/prerequisites/material.model";

export type ViewMode = "view" | "edit" | "create";
export type ActionType = "edit" | "cancel" | "validate" | "delete" | "duplicate";

@Injectable({
  providedIn: "root",
})
export class JobidCardDetailService {
  private _viewMode: ViewMode = "view"; // "edit"
  private _viewMode$: BehaviorSubject<ViewMode> = new BehaviorSubject<ViewMode>(this._viewMode);
  viewMode$: Observable<ViewMode> = this._viewMode$.asObservable();
  isModeReadOnly$: Observable<boolean> = this.viewMode$.pipe(
    distinctUntilChanged(),
    map((viewMode: ViewMode) => viewMode === "view"),
    // startWith(false) // true
  );
  _generalStatus: GeneralStatus = GeneralStatus.DRAFT;
  private _generalStatus$: BehaviorSubject<GeneralStatus> = new BehaviorSubject<GeneralStatus>(this._generalStatus);
  generalStatus$: Observable<GeneralStatus> = this._generalStatus$.asObservable();

  _generalStatusBackUp: GeneralStatus = GeneralStatus.DRAFT;
  private _generalStatusBackUp$: BehaviorSubject<GeneralStatus> = new BehaviorSubject<GeneralStatus>(
    this._generalStatusBackUp,
  );
  generalStatusBackUp$: Observable<GeneralStatus> = this._generalStatusBackUp$.asObservable();
  private _jobCard!: JobidCard;
  private _jobCard$: BehaviorSubject<JobidCard> = new BehaviorSubject<JobidCard>(this._jobCard);

  _generalDetails!: JobCardGeneralDetailsAggregate;
  private _generalDetails$: BehaviorSubject<JobCardGeneralDetailsAggregate> =
    new BehaviorSubject<JobCardGeneralDetailsAggregate>(this._generalDetails);
  generalDetails$: Observable<JobCardGeneralDetailsAggregate> = this._generalDetails$.asObservable();

  _prerequisites!: Prerequisites;
  private _prerequisites$: BehaviorSubject<Prerequisites> = new BehaviorSubject<Prerequisites>(this._prerequisites);
  prerequisites$: Observable<Prerequisites> = this._prerequisites$.asObservable();

  _workOrderDetails!: WorkOrderDetails;
  _workOrderDetails$: BehaviorSubject<WorkOrderDetails> = new BehaviorSubject<WorkOrderDetails>(this._workOrderDetails);
  workOrderDetails$: Observable<WorkOrderDetails> = this._workOrderDetails$.asObservable();

  private createdJcId!: number;
  private _createdJcId$: Subject<number> = new Subject<number>();
  createdJcId$: Observable<number> = this._createdJcId$.asObservable();

  isReadyFor2WF = false;
  private _isReadyFor2WF$ = new BehaviorSubject<boolean>(this.isReadyFor2WF);
  isReadyFor2WF$: Observable<boolean> = this._isReadyFor2WF$.asObservable();

  public loadingMassiveCreation$ = new BehaviorSubject<boolean>(false);
  isWorkOrderNotExisting = false;
  getWoDigitMax = 0;

  private affiliate!: string;
  private _currentId!: string;
  updateSuccessful = true;
  destroy = new Subject<void>();
  defaultSizePerPage = 10;

  readonly toastrOptions = {
    disableTimeOut: true,
    closeButton: false,
    tapToDismiss: false,
    enableHtml: true,
  };

  public static isGeneralStatusDraftOrReadyToActivate(status: string | undefined): boolean {
    return (
      this.matchGeneralStatusByString(status, GeneralStatus.DRAFT) ||
      this.matchGeneralStatusByString(status, GeneralStatus.READY_TO_ACTIVATE)
    );
  }

  public static matchGeneralStatusByString(
    statusAsString: string | undefined,
    statusEnum: GeneralStatus | string,
  ): boolean {
    return statusEnum.toString() === statusAsString;
  }

  constructor(
    private modalService: NgbModal,
    public toastrService: ToastrService,
    public detailComponentService: JobidCardDetailHelperService,
    public generalDetailsFormService: GeneralDetailsFormService,
    public apollo: Apollo,
    private profileService: ProfileService,
    private insightsService: InsightsService,
    private sitePlatformsService: SitePlatformsService,
  ) {
    this.setAffiliate();
    this.loadingMassiveCreation$.subscribe((loading) => {
      if (loading) {
        this.toastrService.info("Massive creation in progress...", "", this.toastrOptions);
      }
    });
  }

  get currentId(): string {
    return this._currentId;
  }

  get isSiteVisitOnly() {
    return this._generalDetails ? this._generalDetails.siteVisitOnly : false;
  }

  changeGeneralStatus(generalStatus: GeneralStatus) {
    this._generalStatus = generalStatus;
    this._generalStatus$.next(this._generalStatus);
  }

  onGeneralStatusChange(generalStatus: any) {
    let yesOrNoModalData!: YesNoModalContent;

    switch (generalStatus) {
      case GeneralStatus.ACTIVE:
        yesOrNoModalData = {
          title: "Job Card Activation",
          content:
            "The job card is about to be activated, and start to progress to the planning gates through the completion of the pre-requisites.",
          subContent:
            "This action will trigger the creation of an activity into the Integrated Operational Planning, except if the activity ID on the general detail page had been already filled.",
          yesLabel: "Confirm",
          noLabel: "Cancel",
        };
        return this.openYesOrNoModal(yesOrNoModalData).subscribe((modalResult: boolean) => {
          if (modalResult) {
            this.changeGeneralStatus(generalStatus);
          } else {
            this._generalStatusBackUp = this._generalStatus;
            this._generalStatusBackUp$.next(this._generalStatusBackUp);
          }
        });

      case GeneralStatus.CANCELLED:
        yesOrNoModalData = {
          title: "Job Card Cancellation",
          content:
            "You are about to cancel this job card. It means that the job originally planned has not been executed. After cancellation, this job card will be removed from your planning but you will still be able to access to the job card in “View Only”.",
          subContent: "Do you confirm the cancellation ?",
          yesLabel: "Confirm",
          noLabel: "Cancel",
        };
        return this.openYesOrNoModal(yesOrNoModalData).subscribe((modalResult: boolean) => {
          if (modalResult) {
            this.changeGeneralStatus(generalStatus);
          } else {
            this._generalStatusBackUp = this._generalStatus;
            this._generalStatusBackUp$.next(this._generalStatusBackUp);
          }
        });

      case GeneralStatus.CLOSED:
        yesOrNoModalData = {
          title: "Job Card Closure",
          content:
            "You are about to close this job card. It means that the job has been executed. After closure, this job card will be removed from your planning but you will still be able to access to the job card in “View Only”",
          subContent: "Do you confirm the closure ?",
          yesLabel: "Confirm",
          noLabel: "Cancel",
        };
        return this.openYesOrNoModal(yesOrNoModalData).subscribe((modalResult: boolean) => {
          if (modalResult) {
            this.changeGeneralStatus(generalStatus);
          } else {
            this._generalStatusBackUp = this._generalStatus;
            this._generalStatusBackUp$.next(this._generalStatusBackUp);
          }
        });

      case GeneralStatus.DRAFT:
      case GeneralStatus.READY_TO_ACTIVATE:
        return this.changeGeneralStatus(generalStatus);
    }
  }

  changeViewMode(newViewMode: ViewMode) {
    this._viewMode = newViewMode;
    this._viewMode$.next(this._viewMode);
  }

  openDocumentLinkModal() {
    return this.modalService.open(DocumentLinkModalComponent, { centered: true, size: "lg" });
  }

  resetIsReadyFor2WF() {
    this.isReadyFor2WF = false;
    this._isReadyFor2WF$.next(this.isReadyFor2WF);
  }

  loadGeneralDetails(id: string, affiliate: string): void {
    this._currentId = id;
    this.getGeneralDetailsOfJobCard(Number(id), affiliate);
  }

  getGeneralDetailsOfJobCard(id: number, affiliate: string): void {
    this.apollo
      .query<any>({
        query: GET_GENERAL_DETAILS_QUERY,
        variables: {
          affiliate: affiliate,
          id: id,
        },
      })
      .subscribe(({ data }) => {
        this._generalDetails = data.jobCardGeneralDetailsAggregated;
        this._generalDetails$.next(data.jobCardGeneralDetailsAggregated);
        this._prerequisites = data.jobCardGeneralDetailsAggregated.prerequisites;
        this._prerequisites$.next(data.jobCardGeneralDetailsAggregated.prerequisites);
      });
  }

  getWorkOrderDetails(id: string): Observable<WorkOrderDetails> {
    const workOrderUpdate = new Subject<WorkOrderDetails>();
    this.getWorkOrderDetailsSilently(id).subscribe(({ data, errors }) => {
      this.isWorkOrderNotExisting = !!errors;
      if (data.getWorkOrderDetailsAggregate) {
        this._workOrderDetails = data.getWorkOrderDetailsAggregate;
        this._workOrderDetails$.next(data.getWorkOrderDetailsAggregate);
        const materials = data.getWorkOrderDetailsAggregate.materials;
        const updatedPrerequisites = this.deepcopy(this._prerequisites);
        updatedPrerequisites.materialListDTO.materials = materials.map((material: WorkOrderMaterial) =>
          this.convertMaterial(material),
        );
        this._prerequisites$.next(updatedPrerequisites);
        workOrderUpdate.next(this._workOrderDetails);
      }
    });
    return workOrderUpdate.asObservable();
  }

  getWorkOrderDetailsSilently(id: string): Observable<ApolloQueryResult<any>> {
    return this.apollo.query<any>({
      query: GET_WORK_ORDER_DETAILS_QUERY,
      variables: {
        id: parseFloat(id),
        affiliate: this.affiliate,
      },
      errorPolicy: "all",
    });
  }

  private convertMaterial(workOrderMaterial: WorkOrderMaterial): Material {
    return {
      affiliate: workOrderMaterial.affiliate,
      eta: workOrderMaterial.eta,
      id: workOrderMaterial.id,
      itemNumber: workOrderMaterial.itemNumber,
      jobCardId: workOrderMaterial.jobCardId,
      materialDescription: workOrderMaterial.description,
      materialNumber: workOrderMaterial.materialNumber,
      materialQuantityRequested: workOrderMaterial.quantityRequested,
      materialQuantitySite: workOrderMaterial.quantitySite,
      procurementStatus: workOrderMaterial.procurementStatus,
      qaQcCertificate: workOrderMaterial.qaQcCertificate,
      sapOrigin: workOrderMaterial.sapOrigin,
      unloadingPoint: workOrderMaterial.unloadingPoint,
      workOrderNumber: workOrderMaterial.workOrderNumber,
      readiness: "",
    };
  }

  loadPrerequisitesOfJobCard(id: string): void {
    this.apollo
      .query<any>({
        query: GET_PREREQUISITES_QUERY,
        variables: {
          id: Number(id),
          affiliate: this.affiliate,
        },
      })
      .subscribe(({ data }) => {
        this._prerequisites = data.getPrerequisites;
        this._prerequisites$.next(data.getPrerequisites);
      });
  }

  handleJobCardDeletion(): Observable<boolean> {
    const deletionDone = new Subject<boolean>();
    this.openYesOrNoModal({
      title: "Delete job card",
      content: "You are about to definitely delete this job card. Are you sure?",
      yesLabel: "Yes",
      noLabel: "No",
    }).subscribe((modalResult: boolean) => {
      if (modalResult) {
        this.apollo
          .mutate({
            mutation: DELETE_JOBCARD_MUTATION,
            variables: {
              id: Number(this.currentId),
              affiliate: this.affiliate,
            },
          })
          .subscribe({
            next: () => {
              this.toastrService.success(`Job card #${this._currentId} has been deleted.`, "Success");
              deletionDone.next(true);
              deletionDone.complete();
            },
            error: (e) => {
              this.toastrService.error(`Error deleting jobId #${this._currentId}: ${e.message}`, "Error");
              deletionDone.next(false);
              deletionDone.complete();
            },
          });
      } else {
        deletionDone.next(false);
        deletionDone.complete();
      }
    });
    return deletionDone.asObservable();
  }

  handleJobCardUpdate() {
    this.openYesOrNoModal({
      title: "Validate job card edition",
      content: "You are going to validate job card edition. All changes will be saved.",
      yesLabel: "Validate",
      noLabel: "Cancel",
    }).subscribe((modalResult: boolean) => {
      if (modalResult) {
        if (this.validateForm()) {
          this.performUpdate()
            .pipe(
              finalize(() => {
                if (this.updateSuccessful) {
                  const message = `All data loaded for jobId #${this._currentId}`;
                  const title = "Success";
                  this.toastrService.success(message, title);
                }
                this.closeEditMode();
                this.loadGeneralDetails(this._currentId, this.affiliate);
              }),
            )
            .subscribe({
              next: (data) => {
                this.refreshData(data);
              },
              error: (e) => {
                this.toastrService.error(`Error saving jobId #${this._currentId}: ${e.message}`, "Error");
                this.updateSuccessful = false;
              },
            });
        }
      } else {
        this.closeEditMode();
      }
    });
  }

  validateForm(): boolean {
    //FIXME since the html template keep triggering the validation I need to add this manual validation
    //Since the validateForm can be called while the errors are temporarly resetted
    this.generalDetailsFormService.form?.get("workOrder")?.updateValueAndValidity();
    if (this.generalDetailsFormService.form?.get("workOrder")?.invalid) {
      //this.detailComponentService.submitForms(true);
      this.toastrService.error("work order is invalid.", "Error");
      return false;
    }
    //
    // Update generalStatus before update
    if (this._generalStatus === GeneralStatus.DRAFT) {
      if (this._generalDetails.jobTitle === "") {
        this.toastrService.error("Job card name is empty.", "Error");
        return false;
      }
    }
    this.detailComponentService.submitForms(true);
    if (
      this._generalStatus != GeneralStatus.DRAFT &&
      this._generalStatus != GeneralStatus.READY_TO_ACTIVATE &&
      this._generalStatus != GeneralStatus.CANCELLED &&
      !this.detailComponentService.areFormsValid()
    ) {
      let message = "Compulsory fields with error:";
      message += this.detailComponentService
        .getAllFormsErrorsList()
        .reduce((acc, val) => acc.concat("<br>- " + val), "");
      this.toastrService.error(message, "Error");
      return false;
    }
    return true;
  }

  private closeEditMode() {
    this.detailComponentService.submitForms(false);
    this.changeViewMode("view");
  }

  private getStatusChange(): string {
    const newStatus = this._generalStatus;
    const oldStatus = this._generalDetails.generalStatus;
    if (this.isActivation(oldStatus, newStatus)) {
      return InsightsService.JBID_ACTIVATION_EVENT;
    } else if (this.isClosing(oldStatus, newStatus)) {
      return InsightsService.JBID_COMPLETION_EVENT;
    } else if (this.isCancellation(oldStatus, newStatus)) {
      return InsightsService.JBID_CANCELLATION_EVENT;
    } else {
      return "";
    }
  }

  private isActivation(oldStatus: string, newStatus: string): boolean {
    return oldStatus !== GeneralStatus.ACTIVE && newStatus === GeneralStatus.ACTIVE;
  }

  private isClosing(oldStatus: string, newStatus: string): boolean {
    return oldStatus !== GeneralStatus.CLOSED && newStatus === GeneralStatus.CLOSED;
  }

  private isCancellation(oldStatus: string, newStatus: string): boolean {
    return oldStatus !== GeneralStatus.CANCELLED && newStatus === GeneralStatus.CANCELLED;
  }

  performUpdate() {
    const jobCardDetailsInputNewData = this.getJobCardDetailsInputNewData();
    return this.performJobCardDetailsUpdate(jobCardDetailsInputNewData);
  }

  getJobCardDetailsInputNewData() {
    const generalDetails = this.generalDetailsFormService.getFormData(
      this._generalDetails,
      this._generalStatus,
      this.isReadyFor2WF,
    );
    generalDetails.activationPending = this._generalDetails.activationPending;
    const prerequisitesInput = this.detailComponentService.constructPrerequisitesInputFromForms(this._prerequisites);

    if (this._workOrderDetails) {
      generalDetails.sitePlatform = this.sitePlatformsService.mapSiteNameToDisplayName(
        this._workOrderDetails.sitePlatform,
      );
      generalDetails.metier = this._workOrderDetails.metier;
      generalDetails.disciplineCategory = this._workOrderDetails.disciplineCategory;
    }

    const jobCardDetails: JobCardDetailsInput = {
      prerequisitesInput: prerequisitesInput,
      generalDetailsInput: generalDetails,
    };
    return jobCardDetails;
  }

  handleJobCardCancel() {
    return this.openYesOrNoModal({
      title: "Cancel job card edition",
      content: "You are about to cancel job card creation. All changes will be lost.",
      yesLabel: "Confirm",
      noLabel: "Cancel",
    }).subscribe((modalResult: boolean) => {
      if (modalResult) {
        this.detailComponentService.submitForms(false);
        this.isWorkOrderNotExisting = false;
        this._generalStatus = Object.assign({}, this._generalDetails.generalStatus);
        this._generalStatus$.next(this._generalStatus);

        this._jobCard$.next(Object.assign({}, this._jobCard));
        this._generalDetails$.next(Object.assign({}, this._generalDetails));
        this._prerequisites$.next(Object.assign({}, this._prerequisites));

        this.changeViewMode("view");
      }
    });
  }

  handleWorkOrderReplacement(selectedValue: string): Observable<WorkOrderDetails | null> {
    if (this.modalService.hasOpenModals()) {
      return of(null);
    }
    return this.openYesOrNoModal({
      title: "Change  Work Order",
      content: "By changing the Work Order, you will automatically import the information from the job identification.",
      yesLabel: "Confirm",
      noLabel: "Cancel",
    }).pipe(
      switchMap((modalResult: boolean) => {
        if (modalResult) {
          return this.getWorkOrderDetails(selectedValue);
        } else {
          return of(null);
        }
      }),
    );
  }

  handle2wfGateActivation() {
    if (this.modalService.hasOpenModals()) {
      this.resetIsReadyFor2WF();
      return of(null);
    }
    return this.openYesOrNoModal({
      title: "Job Card status",
      content:
        "You are about to pass this Job Card into the 2WF period and the start of the preparation by offshore sites.",
      yesLabel: "Confirm",
      noLabel: "Cancel",
    }).subscribe((modalResult: boolean) => {
      if (modalResult) {
        this.isReadyFor2WF = true;
        this.performUpdate()
          .pipe(
            finalize(() => {
              if (this.updateSuccessful) {
                const message = "Job card has been passed into the 2WF period.";
                const title = "Success";
                this.toastrService.success(message, title);
              }
              this.loadPrerequisitesOfJobCard(this._currentId);
            }),
          )
          .subscribe({
            next: (data) => {
              this.refreshData(data);
            },
            error: (e) => {
              console.error(e.message);
              this.resetIsReadyFor2WF();
              this.updateSuccessful = false;
              this.toastrService.error("Job card has not successfully passed into the 2WF period.", "Error");
            },
          });
      } else {
        this.resetIsReadyFor2WF();
      }
    });
  }

  openYesOrNoModal(data: YesNoModalContent): Observable<boolean> {
    const modalRef = this.modalService.open(AppYesNoModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.setData(data);

    return from(modalRef.result).pipe(
      map((value) => !!value),
      catchError((_error: any) => {
        return of(false);
      }),
    );
  }

  private refreshData(data: any) {
    // If no data received
    const checkError = (dataParam: any, defaultValue: any = []) =>
      this.hasError(dataParam) ? defaultValue : dataParam;

    this._generalDetails = checkError(data.generalDetails, null);
    this._generalDetails$.next(this._generalDetails);
    this._prerequisites = checkError(data.prerequisites, null);
    this._prerequisites$.next(this._prerequisites);
  }

  hasError(item: any) {
    return Object.prototype.hasOwnProperty.call(item, "errors") && item.errors !== 0;
  }

  createJobCard(jobCardCreationInput: JobCardCreationInput) {
    return this.apollo
      .mutate({
        mutation: JOB_CARD_CREATION_MUTATION,
        variables: {
          affiliate: this.affiliate,
          input: jobCardCreationInput,
        },
        errorPolicy: "all",
      })
      .subscribe(({ data, errors }) => {
        if (errors) {
          this.toastrService.error(errors.toString(), "Error");
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.createdJcId = data?.createJobCard?.jobCardBasicInfoDTO?.id;
          if (!this.createdJcId) {
            this.toastrService.error("Error creating JobCard", "Error");
            return;
          }
          this._createdJcId$.next(this.createdJcId);
          this.insightsService.logEvent(InsightsService.JBID_EVENT, {
            status: InsightsService.JBID_CREATION_EVENT,
            affiliate: this.affiliate,
          });
        }
      });
  }

  performJobCardDetailsUpdate(jobCardTracking: JobCardDetailsInput) {
    const jobCardSubject = new Subject<any>();
    this.apollo
      .mutate<any>({
        mutation: JOB_CARD_DETAILS_UPDATE_MUTATION,
        variables: {
          affiliate: this.affiliate,
          input: jobCardTracking,
        },
        errorPolicy: "all",
      })
      .subscribe(({ data, errors }) => {
        if (errors) {
          jobCardSubject.error(errors);
        } else {
          this.logStatusChange();
          jobCardSubject.next(data.saveJobCardDetails);
        }
        jobCardSubject.complete();
      });
    return jobCardSubject.asObservable();
  }

  logStatusChange(): void {
    const statusChange = this.getStatusChange();
    if (statusChange) {
      this.insightsService.logEvent(InsightsService.JBID_EVENT, {
        status: statusChange,
        affiliate: this.affiliate,
      });
    }
  }

  setAffiliate(): void {
    this.profileService.affiliate$.subscribe((affiliate) => {
      this.affiliate = affiliate.value;
    });
    this.profileService.affiliateConfig$.subscribe((config) => {
      this.getWoDigitMax = config.workOrderDigits;
    });
  }

  /*If the workorder exists it loads the linked jc details. If there is a linked active/draft jc
   *  it returns a JC workorder aggregate,
   *    + if the work order does not exist and empty value,
   *   + if not there no linked jc the jc part it will be empty,
   *   + if there is a linked with an active/draft jc
   */
  loadWorkorderJobCardLink(workOrderNumber: string): Observable<ApolloQueryResult<any>> {
    return this.apollo.query<any>({
      query: CHECK_WORK_ORDER_JOB_CARD_CREATION_QUERY,
      variables: {
        affiliate: this.affiliate,
        workOrderNumber: parseFloat(workOrderNumber),
      },
      errorPolicy: "all",
    });
  }

  duplicateJobCard(site: string, sapWorkOrder: string | null, jobCardName: string) {
    const duplicationInput: JobCardDuplicationInput = {
      affiliate: this.affiliate,
      jobTitle: jobCardName,
      workOrderNumber: sapWorkOrder ? parseFloat(sapWorkOrder) : null,
      sitePlatform: site,
      jobCardId: Number(this._generalDetails.id),
    };
    return this.apollo.mutate<any>({
      mutation: DUPLICATE_JOB_CARD_MUTATION,
      variables: {
        affiliate: this.affiliate,
        input: duplicationInput,
      },
      errorPolicy: "all",
    });
  }

  getMobilizationList(
    affiliate: string,
    defaultSizePerPage: number,
    totalItemsCallback: (totalItems: number) => void,
    object: any,
  ): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const startRow = params.request.startRow;
        const pageToGet = startRow ? startRow / defaultSizePerPage : 0;
        this.apollo
          ?.query<any>({
            query: GET_MOBILIZATIONS_QUERY,
            variables: {
              affiliate: affiliate,
              page: pageToGet,
              size: defaultSizePerPage,
              jobCardId: Number(this._prerequisites.jobId),
            },
          })
          .pipe(takeUntil(this.destroy))
          .subscribe((result) => {
            totalItemsCallback.apply(object, [result.data.getMobilizationList.totalItems]);
            params.success({
              rowData: result.data.getMobilizationList.data,
              rowCount: result.data.getMobilizationList.totalItems < 0 ? 0 : result.data.getMobilizationList.totalItems,
            });
          });
      },
      destroy: () => this.destroy.next(),
    };
  }

  getJobCardHistory(defaultSizePerPage: number): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const startRow = params.request.startRow;
        const pageToGet = startRow ? startRow / defaultSizePerPage : 0;
        this.apollo
          ?.query<any>({
            query: GET_JOB_CARD_HISTORY_PAGE,
            variables: {
              affiliate: this.profileService.getAffiliate().value,
              jobCardId: Number(this._currentId),
              filter: {
                page: pageToGet,
                size: defaultSizePerPage,
                sortBy: params.request.sortModel.length == 0 ? "modificationDate" : params.request.sortModel[0]?.colId,
                direction: params.request.sortModel.length == 0 ? "desc" : params.request.sortModel[0]?.sort,
              },
            },
          })
          .pipe(takeUntil(this.destroy))
          .subscribe((result) => {
            params.success({
              rowData: result.data.getJobCardHistoryPage.data,
              rowCount:
                result.data.getJobCardHistoryPage.totalItems < 0 ? 0 : result.data.getJobCardHistoryPage.totalItems,
            });
          });
      },
      destroy: () => this.destroy.next(),
    };
  }

  getMobilizationLastScanInfo(affiliate: string): Observable<string> {
    return this.apollo
      .query<any>({
        query: GET_MOBILIZATIONS_LAST_SCAN,
        variables: {
          affiliate: affiliate,
        },
        errorPolicy: "all",
      })
      .pipe(map((result) => result.data.getMobilizationScanInfo.lastScanDate));
  }

  editJobCardClassification(jobCardId: string, classification: string) {
    return this.apollo
      .mutate({
        mutation: UPDATE_JOB_CARD_CLASSIFICATION_MUTATION,
        variables: {
          affiliate: this.affiliate,
          jobCardId: Number(jobCardId),
          classification: classification,
        },
        errorPolicy: "all",
      })
      .subscribe((result) => {
        if (result.errors) {
          this.toastrService.error(result.errors.toString(), "Error");
        } else {
          this.toastrService.success("JobCard classification updated successfully", "Success");
        }
      });
  }

  updateWorkOrderInSap(jobCardId: number) {
    const sapWriteBack = new Subject<any>();
    this.apollo
      .mutate<any>({
        mutation: UPDATE_WORKORDERS_IN_SAP,
        variables: {
          affiliate: this.affiliate,
          id: Number(jobCardId),
        },
      })
      .subscribe({
        next: ({ data, errors }) => {
          if (errors) {
            sapWriteBack.error(errors[0]);
          } else {
            sapWriteBack.next(data.updateWorkOrderInSap);
            sapWriteBack.complete();
          }
        },
        error: (err) => {
          sapWriteBack.error(err);
        },
      });
    return sapWriteBack.asObservable();
  }

  createJobCardsWithRevisionCode(revisionCode: string) {
    this.loadingMassiveCreation$.next(true);
    this.apollo
      .mutate({
        mutation: CREATE_JOB_CARDS_BY_REVISON_CODE,
        variables: {
          affiliate: this.affiliate,
          revisionCode: revisionCode,
        },
        errorPolicy: "all",
      })
      .subscribe({
        next: (result) => {
          this.loadingMassiveCreation$.next(false);
          if (result.errors) {
            this.toastrService.error(result.errors[0].message, "Error");
          } else {
            const data = result.data as any;
            const createdCards = `${data.createAllByRevisionCode.totalJobCardCreated}/${data.createAllByRevisionCode.totalWorkOrder}`;
            this.toastrService.success(`Massive creation of ${createdCards} job cards completed.`, "");
          }
        },
        error: (err) => {
          this.loadingMassiveCreation$.next(false);
          this.toastrService.error(err.message, "Error");
        },
      });
  }

  private deepcopy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  selectPrerequisites(prerequisites: WorkOrderPrerequisite[]): boolean {
    if (this._prerequisites) {
      const itemAdded = this.detailComponentService.selectPrerequisites(
        prerequisites,
        this._prerequisites,
        this.isSiteVisitOnly,
      );
      this._prerequisites$.next(this._prerequisites);
      return itemAdded;
    } else {
      // No prerequisites loaded, it means we are creating a job card without having loaded any before
      return true;
    }
  }
}
