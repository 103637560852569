import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {DetailFormService} from "./detail-form.service";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";
import {filter, takeUntil} from "rxjs/operators";

export abstract class AbstractArrayFormService extends DetailFormService {
  abstract get formArrayName(): string;

  get formArray() {
    return this.form.controls[this.formArrayName] as UntypedFormArray;
  }

  protected constructor(protected fb: UntypedFormBuilder) {
    super();
  }

  protected setInitialData(
    items: any[]|undefined,
    documents?: Map<string, DocumentDTO[]>
  ) {

    const toGroups =
      items?.map((item, index: number) => {
        const filteredDocuments = this.filterDocumentsBySection(documents, index);
        return this.createFormGroup(item, filteredDocuments);
      }) || [];

    return this.fb.array(toGroups);
  }

  private filterDocumentsBySection(documentsMap: Map<string, DocumentDTO[]> | undefined, section: number): Map<string, DocumentDTO[]> {
    const documentsMapResult = new Map<string, DocumentDTO[]>();
    documentsMap?.forEach((documentList: DocumentDTO[], key: string) => {
      documentsMapResult.set(key, documentList.filter(doc => doc.sectionIndex === section))
    })
    return documentsMapResult;
  }

  abstract createFormGroup(dto: any, documents?: Map<string, DocumentDTO[]>): UntypedFormGroup;

  createForm(isModeReadOnly: boolean, dto: any, documents?: Map<string, DocumentDTO[]>) {
    this.form = this.fb.group({
      checked: [dto?.checked, []],
      items: this.setInitialData(dto?.items, documents),
    });

    return this.form;
  }

  arrayControl(index: number, field: string): UntypedFormControl {
    const formArray = this.formArray as UntypedFormArray;
    const documentFieldsToInitialize = ['liftingDocumentLink','calculationNoteStatusDocumentLink',
    'schematicStatusDocumentLink','locationStatusDocumentLink','customPrerequisiteDocumentLink',
    'liftingProcedureDocumentLink','excavationDocumentLink','securityBridgingDocumentLink','calorifugeDocumentLink'];

    if( documentFieldsToInitialize.includes(field) && formArray?.at(index)?.get(field)?.value == null)  {
      // Set empty array instead of null value to fix error when adding link for document
      formArray?.at(index)?.get(field)?.setValue([]);
    }

    return formArray?.at(index)?.get(field) as UntypedFormControl;
  }

  getFormData(_dto?: any): any {
    const data: any = {
      ...this.form?.value,
    };

    return data;
  }

  removeItem(i: number) {
    this.formArray?.removeAt(i);
  }

  addNewItem(item?: any) {
    this.formArray.push(this.createFormGroup(item));
  }

  clearFormOnUnchecked() {
    this.form?.get("checked")?.valueChanges.pipe(takeUntil(this.destroy), filter(displayed => !displayed)).subscribe(() => {
      this.formArray.clear();
    });
  }

  disableRequiredFieldsOnChanges(parentField: string, fieldsToChange: string[]): void {
    this.getControl(parentField)?.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
      if (value) {
        fieldsToChange.forEach((field) => {
          // parent field is enabled, so it needs to be set as required
          this.getControl(field)?.setValidators([Validators.required]);
        });
      } else {
        fieldsToChange.forEach((field) => {
          // parent field is disabled, to fields need to be set as not required and reset
          this.getControl(field)?.setValidators(null);
          this.getControl(field)?.patchValue(null);
        });
      }
    });
  }
}
