import {Injectable} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BehaviorSubject, from, Observable, of} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {
    aPrerequisiteTags,
    Discussion,
    DiscussionFromDtoAdapterForDisplay,
    DiscussionToDtoAdapter,
} from "@shared/models/discussions/discussions.model";
import {ProfileService} from "@shared/services/profile/profile.service";
import {CustomValidators} from "@shared/components/forms/custom.validators";
import {JobidCardDetailService} from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import {GET_DISCUSSIONS_QUERY} from "@graphql/jobid-card/detail/discussions/queries/discussions.gql";
import {Apollo} from "apollo-angular";
import {CREATE_DISCUSSION_MUTATION} from "@graphql/jobid-card/detail/discussions/mutation/discussions-mutation.gql";
import {
    UPDATE_DISCUSSION_CONTENT_MUTATION
} from "@graphql/jobid-card/detail/discussions/mutation/update-discussion-content-mutation.gql";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
    AppYesNoModalComponent,
    YesNoModalContent
} from "@shared/components/modal/app-yes-no-modal/app-yes-no-modal.component";
import {catchError, map} from "rxjs/operators";
import {MARK_DISCUSSION_AS_DELETED} from "@graphql/cockpit/markedDiscussionAsDelete.gql";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class DiscussionsFacadeService {
    private userName = "";
    private _jobCardId!: string;
    private readonly _searchForm!: UntypedFormGroup;
    private readonly _searchFiltersForm: UntypedFormGroup;

    submittedState = {submitted: false};

    _discussions!: Discussion[];
    private _discussions$: BehaviorSubject<Discussion[]> = new BehaviorSubject<Discussion[]>(this._discussions);
    discussions$: Observable<Discussion[]> = this._discussions$.asObservable();

    constructor(
        private fb: UntypedFormBuilder,
        public toastrService: ToastrService,
        private profileService: ProfileService,
        private jobCardDetailsService: JobidCardDetailService,
        private discussionFromDtoAdapterForDisplay: DiscussionFromDtoAdapterForDisplay,
        private discussionToDtoAdapter: DiscussionToDtoAdapter,
        private modalService: NgbModal,
        private apollo: Apollo,
        private translate: TranslateService,
    ) {
        this._searchForm = this.fb.group({
            searchCriteria: [""],
        });

        this._searchFiltersForm = this.fb.group(
            {
                senderName: [""],
                messageObject: [""],
                dateFrom: [null],
                dateTo: [null],
                prerequisiteTags: [""],
            },
            {
                validators: [
                    CustomValidators.startDateAfterEndDate("dateFrom", "dateTo"),
                    CustomValidators.dateFromOrDateToNull("dateFrom", "dateTo"),
                ],
            },
        );
        this._jobCardId = this.jobCardDetailsService.currentId;
        this.profileService.userName$.subscribe((value: string) => (this.userName = value));
    }

    get jobCardId() {
        return this._jobCardId;
    }

    get searchForm() {
        return this._searchForm;
    }

    get searchFiltersForm() {
        return this._searchFiltersForm;
    }

    setJobCardId(jobId: string) {
        this._jobCardId = jobId;
    }

    searchMessagesWithFilters(): Discussion[] {
        this.submittedState = Object.assign({}, {submitted: true});
        if (!this._searchFiltersForm.valid) {
            return this._discussions;
        }
        const searchFilters = this._searchFiltersForm.value;
        return this.filterDiscussionByTag(this._discussions, searchFilters?.prerequisiteTags || [])
            .filter((discussion: Discussion) => {
                if (discussion.dateCreated == null) {
                    return false;
                }
                if (searchFilters.dateFrom == null || searchFilters.dateTo == null) {
                    return true;
                }
                const createDate = new Date(discussion.dateCreated);
                return createDate > searchFilters?.dateFrom && createDate < searchFilters?.dateTo;
            })
            .filter((discussion: Discussion) => {
                if ((searchFilters?.messageObject?.trim() || "").length <= 0) {
                    return true;
                }
                return discussion.messageObject
                    ?.toLowerCase()

                    .includes(searchFilters?.messageObject.toLowerCase());
            })
            .filter((discussion: Discussion) => {
                if ((searchFilters?.senderName?.trim() || "").length <= 0) {
                    return true;
                }
                return discussion.senderName?.toLowerCase().includes(searchFilters?.senderName.toLowerCase());
            });
    }

    private filterDiscussionByTag(
        fullDiscussionList: Discussion[],
        prerequisitesTagFilters: string[] = [],
    ): Discussion[] {
        if (prerequisitesTagFilters.length <= 0) {
            return fullDiscussionList;
        }
        const filteredByTag: Discussion[] = [];
        fullDiscussionList.forEach((discussion) =>
            discussion.prerequisiteTags?.forEach((element) => {
                prerequisitesTagFilters.forEach((tag: string) => {
                    if (aPrerequisiteTags.find((preTag) => preTag.name === element)?.value === tag)
                        filteredByTag.push(discussion);
                });
            }),
        );
        return filteredByTag;
    }

    loadDiscussionOfJobCard(): void {
        const discussionMessages: Discussion[] = [];
        this.apollo
            .watchQuery<any>({
                query: GET_DISCUSSIONS_QUERY,
                variables: {
                    id: Number(this.jobCardId),
                },
                errorPolicy: "all",
            })
            .valueChanges.subscribe(({data, errors}) => {
            if (errors) {
                this.toastrService.error(
                    `An error occurred while searching discussion messages for jobId #${this._jobCardId}`,
                    "Error",
                );
                return;
            }

            data.getDiscussionMessagesByJobCardId.map((discussion: Discussion) => {
                discussionMessages.push(this.transformDtoForDisplay(discussion));
            });
            this._discussions = discussionMessages;
            this._discussions$.next(this._discussions);
            this.toastrService.success(`All discussion messages data loaded for jobId #${this._jobCardId}`, "Success");
        });
    }

    createNewDiscussionMessage(changes: Partial<Discussion>) {
        const discussionInput = {
            ...this.discussionToDtoAdapter.adapt(changes),
            jobCardId: Number(this._jobCardId),
            senderName: this.userName,
        };
        return this.apollo
            .mutate({
                mutation: CREATE_DISCUSSION_MUTATION,
                variables: {
                    input: discussionInput,
                },
                errorPolicy: "all",
            })
            .subscribe(({data, errors}) => {
                if (errors) {
                    this.toastrService.error(
                        `An error occurred while creating new discussion messages for jobId #${this._jobCardId}`,
                        "Error",
                    );
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this._discussions.push(this.transformDtoForDisplay(data.createDiscussionMessage));
                this._discussions$.next(this._discussions);
                this.toastrService.success(`New discussion message has been added for jobId #${this._jobCardId}`, "Success");
            });
    }

    private transformDtoForDisplay(item: Discussion): Discussion {
        const itemForDisplay = this.discussionFromDtoAdapterForDisplay.adapt(item);
        return itemForDisplay;
    }

    updateDiscussionContent(id: number, discussion: Partial<Discussion>) {
        const discussionInput = {
            messageObject: discussion?.messageObject,
            prerequisiteTags: discussion?.prerequisiteTags?.join("/") || null,
            messageContent: discussion?.messageContent,
        };
        return this.apollo
            .mutate({
                mutation: UPDATE_DISCUSSION_CONTENT_MUTATION,
                variables: {
                    id: Number(id),
                    affiliate: this.profileService?.getAffiliate()?.value,
                    input: discussionInput
                },
                errorPolicy: "all",
            })
            .subscribe(({data, errors}) => {
                if (errors) {
                    this.toastrService.error(
                        `An error occurred while updating  discussion messages with id #${id}`,
                        "Error",
                    );
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.refreshDiscussion(this.transformDtoForDisplay(data?.updatedDiscussionContent));
                this.toastrService.success(`the discussion with id #${id} has been updated`, "Success");
            });
    }

    refreshDiscussion(discussion: Discussion) {
        const discussionToUpdate = this._discussions.find((item) => item.id === discussion.id);
        if (discussionToUpdate) {
            discussionToUpdate.messageObject = discussion.messageObject;
            discussionToUpdate.prerequisiteTags = discussion.prerequisiteTags;
            discussionToUpdate.messageContent = discussion.messageContent;
            discussionToUpdate.lastModified = discussion.lastModified;
            this._discussions = this._discussions.filter(item => item.id !== discussion.id)
            this._discussions.push(discussionToUpdate);
            this._discussions$.next(this._discussions);
        }
    }

    handleDiscussionAsDelete(selectedValue: number) {
        if (this.modalService.hasOpenModals()) {
            return of(null);
        }
        return this.openYesOrNoModal({
            title: this.translate.instant('discussion.deleteMsg'),
            content: this.translate.instant('discussion.deleteMsgConfirmation'),
            yesLabel: this.translate.instant('discussion.confirm'),
            noLabel: this.translate.instant('discussion.cancel'),
            showModalPreferences: true,
        }).pipe(result => result).subscribe((result: boolean) => {
            this.markedDiscussionAsDelete(selectedValue);
        });
    }

    private openYesOrNoModal(data: YesNoModalContent): Observable<boolean> {
        const modalRef = this.modalService.open(AppYesNoModalComponent, {
            centered: true,
        });
        modalRef.componentInstance.setData(data);

        return from(modalRef.result).pipe(
            map((value) => !!value),
            catchError((_error: any) => {
                return of(false);
            }),
        );
    }

    markedDiscussionAsDelete(id: number) {
        return this.apollo
            .mutate({
                mutation: MARK_DISCUSSION_AS_DELETED,
                variables: {
                    id: Number(id),
                    affiliate: this.profileService.getAffiliate().value,
                },
                errorPolicy: "all",
            })
            .subscribe(({data, errors}) => {
                if (errors) {
                    this.toastrService.error(errors.toString(), "Error");
                }
                if (data) {
                    const index = this._discussions.findIndex((item) => item.id === id);
                    if (index > -1) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const discussion = this.transformDtoForDisplay(data?.markDiscussionAsDeleted)
                        this._discussions.splice(index, 1, discussion)
                        this._discussions$.next(this._discussions);
                    }
                }
            });
    }
}
