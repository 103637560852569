import {Injectable} from "@angular/core";
import {FormArray, UntypedFormArray, UntypedFormBuilder, Validators} from "@angular/forms";
import {ResourceLiftingDTO, ResourceLiftingDTOInput} from "@shared/models/prerequisites/resources-lifting.model";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";
import filter from "lodash-es/filter";

@Injectable({
  providedIn: "root",
})
export class LiftingFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  defaultDTO: ResourceLiftingDTO = {
    criticalLifting: false,
    readiness: undefined,
    slsRequired: false,
    craneRequired: false,
    craneDescription: '',
    liftingProcedureRequired: false,
  };

  get formArrayName() {
    return "items";
  }

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  private setUpOnChanges(formArray: UntypedFormArray): void {
    const controlsLength = formArray.controls?.length;
    for (let i = 0; i < controlsLength; i++) {
      const criticalLiftingControl = this.arrayControl(i, "criticalLifting");
      criticalLiftingControl.valueChanges.subscribe((val) => {
        if (!val) {
          this.arrayControl(i, "liftingPlanStatus").setValidators(null);
          this.arrayControl(i, "liftingPlanStatus").patchValue(null);
          this.arrayControl(i, "liftingDescription").patchValue(null);
        } else {
          this.arrayControl(i, "liftingPlanStatus").setValidators([Validators.required]);
        }
      });
    }
  }

  createFormGroup(dto?: ResourceLiftingDTO, documents?: Map<string, DocumentDTO[]>) {
    return this.fb.group({
      criticalLifting: [dto?.criticalLifting, [Validators.required]],
      liftingPlanStatus: [dto?.liftingPlanStatus, dto?.criticalLifting ? [Validators.required] : []],
      liftingDescription: [dto?.liftingDescription],
      liftingDocumentLink: [documents?.get("liftingDocumentLink"), []],
      slsRequired: [dto?.slsRequired, [Validators.required]],
      craneRequired: [dto?.craneRequired, [Validators.required]],
      craneDescription: [dto?.craneDescription, []],
      liftingProcedureRequired: [dto?.liftingProcedureRequired || false, [Validators.required]],
      liftingProcedureDocumentLink: [documents?.get("liftingProcedureDocumentLink"), []],
      jobCardId: [dto?.jobCardId],
      id: [dto?.id],
    });
  }

  createForm(isDisabled: boolean, resourceLiftingData?: ResourceLiftingDTO[], documents?: Map<string, DocumentDTO[]>) {
    if(this.form != undefined && !isDisabled){
      return this.form;
    }

    if(isDisabled || this.form == undefined){
      this.data = resourceLiftingData;

      const isChecked =
        (resourceLiftingData?.length !== 0 && resourceLiftingData?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isDisabled }, []],
        items: this.setInitialData(resourceLiftingData, documents),
      });

      this.setUpOnChanges(this.formArray);
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(resourceLifting: ResourceLiftingDTO[]): ResourceLiftingDTOInput[] {
    if (!resourceLifting) {
      return [];
    }
    if (!this.form) return resourceLifting.map((lifting) => this.copy(lifting));

    const formItems: FormArray = this.form.controls.items as FormArray;
    let items: ResourceLiftingDTO[] = [];

    if (formItems && formItems.controls.length !== 0) {
      items = formItems.controls.map((item: any) => {
        item = this.copyFromFormItem(item.controls);
        item.jobCardId = this.jobCardId;
        item.displayed = this.form.controls.checked.value;
        return item;
      });
    }

    return items;
  }

  private copyFromFormItem(formItem : any){
    return {
      id: formItem.id?.value,
      criticalLifting: formItem.criticalLifting.value,
      liftingPlanStatus: formItem.liftingPlanStatus.value,
      liftingDescription: formItem.liftingDescription.value,
      slsRequired: formItem.slsRequired.value,
      craneRequired: formItem.craneRequired.value,
      craneDescription: formItem.craneDescription.value,
      liftingProcedureRequired: formItem.liftingProcedureRequired.value,
    };
  }

  private copy(lifting: ResourceLiftingDTO): ResourceLiftingDTOInput {
    return {
      id: lifting.id,
      jobCardId: lifting.jobCardId,
      displayed: lifting.displayed,
      criticalLifting: lifting.criticalLifting,
      liftingPlanStatus: lifting.liftingPlanStatus,
      liftingDescription: lifting.liftingDescription,
      slsRequired: lifting.slsRequired,
      craneRequired: lifting.craneRequired,
      craneDescription: lifting.craneDescription,
      liftingProcedureRequired: lifting.liftingProcedureRequired,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
      if (!documentDTOList && !this.form) {
        return [];
      }

      if(!this.form){
        const filteredDocs :DocumentDTO[] = filter(documentDTOList, {'section': 'LIFTING'});
        return filteredDocs.map(doc => this.copyDocument(doc));
      }
    const liftingDocumentLink: DocumentDTO[] = [];
    const liftingProcedureDocumentLink: DocumentDTO[] = [];
    this.form?.controls["items"]?.value?.forEach(
      (item: {
         liftingDocumentLink: DocumentDTO[];
         _colorCodingDocumentLink: DocumentDTO[];
         liftingProcedureDocumentLink: DocumentDTO[];
        }) => {
        item.liftingDocumentLink?.forEach((doc: DocumentDTO) => liftingDocumentLink.push(this.copyDocument(doc)));
        item.liftingProcedureDocumentLink?.forEach((doc: DocumentDTO) => liftingProcedureDocumentLink.push(this.copyDocument(doc)));
      },
    );

    return [
      liftingDocumentLink,
      liftingProcedureDocumentLink
    ].reduce((acc, val) => acc.concat(val), []);
  }
}
