import gql from "graphql-tag";

const GET_MOBILIZATIONS_QUERY = gql`
  query ($jobCardId: Int, $affiliate: String, $page: Int, $size: Int) {
    getMobilizationList(affiliate: $affiliate, jobCardId: $jobCardId, page: $page, size: $size) {
      data {
        transportationDate
        startLocation
        endLocation
        accommodationLocation
        companyName
        collaboratorFirstName
        collaboratorLastName
        collaboratorNationality
        transportationMode
        reservationStatus
        lastUpdate
      }
      totalPages
      currentPage
      totalItems
    }
  }
`;

export {GET_MOBILIZATIONS_QUERY};
