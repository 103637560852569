import {Routes} from "@angular/router";
import {UnauthorizedComponent} from "@core/components/unauthorized/unauthorized.component";
import {LoginComponent} from "@core/components/login/login.component";
import {RedirectPageComponent} from "@core/components/login/redirect-page/redirect-page.component";
import {MainLayoutComponent} from "@core/components/main-layout/main-layout.component";
import {MsalGuard} from "@azure/msal-angular";

export const APP_ROUTES_NAMES = {
  ADMIN: "admin",
  COCKPIT: "cockpit",
  JOB_ID_CARD: "jobid-card",
  READINESS_DASHBOARD: "readiness-dashboard",
  JOB_CARD_MASTER: "job-card-master",
  TASK: "task",
  METRICS: "metrics",
};

export const APP_ROUTES: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/login" },
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      {
        path: APP_ROUTES_NAMES.ADMIN,
        loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: APP_ROUTES_NAMES.COCKPIT,
        loadChildren: () => import("./cockpit/cockpit.module").then((m) => m.CockpitModule),
      },
      {
        path: APP_ROUTES_NAMES.JOB_ID_CARD,
        loadChildren: () => import("./jobid-card/jobid-card.module").then((m) => m.JobidCardModule),
      },
      {
        path: APP_ROUTES_NAMES.READINESS_DASHBOARD,
        loadChildren: () => import("./readiness-dashboard/readiness-dashboard.module").then((m) => m.ReadinessDashboardModule),
      },
      {
        path: APP_ROUTES_NAMES.JOB_CARD_MASTER,
        loadChildren: () => import("./job-card-master/job-card-master.module").then((m) => m.JobCardMasterModule),
      },
      {
        path: APP_ROUTES_NAMES.METRICS,
        loadChildren: () => import("./metrics/metrics.module").then((m) => m.MetricsModule),
      },
    ],
  },
  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "login", component: LoginComponent },
  {
    path: "callback",
    component: RedirectPageComponent,
  },
];
