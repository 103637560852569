import gql from "graphql-tag";

const DUPLICATE_JOB_CARD_MUTATION = gql`
  mutation duplicateJobCard($affiliate: String, $input: JobCardDuplicationInput!) {
    duplicateJobCard(affiliate: $affiliate, input: $input) {
      jobCardBasicInfoDTO {
        id
      }
    }
  }
`;

export { DUPLICATE_JOB_CARD_MUTATION };
