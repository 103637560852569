export interface Inhibition {
  id: number | undefined;
  description: string;
  status: InhibitionStatus;
  equipmentTag: string;
  displayed: boolean;
  jobCardId: number;
  readiness: string | undefined;
}

export interface InhibitionInput {
  id: number | undefined;
  description: string;
  status: InhibitionStatus;
  equipmentTag: string;
  displayed: boolean;
  jobCardId: number;
}

export enum InhibitionStatus {
  NOT_STARTED = "Not Started",
  PENDING = "Pending",
  VALIDATED = "Validated",
}
