import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import {Inhibition, InhibitionInput} from "@shared/models/prerequisites/inhibition.model";

@Injectable({
  providedIn: "root",
})
export class InhibitionFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(inhibition?: Inhibition) {
    return this.fb.group({
      id: [inhibition?.id],
      description: [inhibition?.description, [Validators.required]],
      status: [inhibition?.status],
      equipmentTag: [inhibition?.equipmentTag, [Validators.required]],
      displayed: [inhibition?.displayed, []],
      jobCardId: [this.jobCardId],
    });
  }

  createForm(isDisabled: boolean, inhibitions?: Inhibition[]) {
    if(this.form != undefined && !isDisabled){
      return this.form;
    }

    if(isDisabled || this.form == undefined){
      this.data = inhibitions;

      const isChecked = (inhibitions?.length !== 0 && inhibitions?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isDisabled }, []],
        items: this.setInitialData(inhibitions),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(inhibitions: Inhibition[]): InhibitionInput[] {
    if (!inhibitions) {
      return [];
    }
    if (!this.form) return inhibitions.map((inhibition) => this.copy(inhibition));

    let itemsInput: InhibitionInput[] = [];
    if (this.form?.value && this.form?.value[this.formArrayName] && this.form?.value[this.formArrayName].length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return item.equipmentTag || item.description || item.status;
      }).map((item: Inhibition) => {
        const itemInput = this.copy(item);
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }
    return itemsInput;
  }

  private copy(inhibition: Inhibition): InhibitionInput{
    return {
      id: inhibition.id,
      description: inhibition.description,
      status: inhibition.status,
      equipmentTag: inhibition.equipmentTag,
      displayed: inhibition.displayed,
      jobCardId: inhibition.jobCardId,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
