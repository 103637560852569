<div class="container-fluid w-100">
    <div class="d-flex align-items-center">
      <!-- Application Name -->
      <a routerLink="/" class="pe-4">
        <img class="logo ps-3 my-2" src="/assets/logo.jpeg" alt="Job id card logo" />
        <span class="app-title ps-3 pe-4 text-body">Job Id Card</span>
      </a>
      <!-- Tab menu -->
       <ul class="nav nav-tabs bg-white">
         @for (tab of displayJobCardTabsHeader; let i = $index; track tab) {
           <li class="nav-item">
             <a class="nav-link text-capitalize py-3" id="tab-{{ tab.id }}"
                [ngClass]="{'active': i === selectedTabIndex}"
                [attr.aria-controls]="tab.id"
                (click)="selectTab(i)"
                [routerLink]="tab.route"
             > {{ tab.title | translate}} </a>
           </li>
         }
      </ul>
      <!-- Navbar content -->
      <ul class="navbar-nav ms-auto d-flex flex-row">
        <li class="nav-item">
          <div class="d-flex ms-md-4 flex-row">
            <span class="navbar-text ms-2 d-none d-md-inline">{{'common.affiliate' | translate}}:</span>

            @if (affiliates.length === 1) {
              <span class="navbar-text ms-2" id="one-affiliate">
                {{ selectedAffiliate?.name | titlecase }}
              </span>
            } @else if (affiliates.length > 1) {
              <div ngbDropdown class="dropdown d-inline-block" >
                <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="affiliate" aria-expanded="false">
                  {{ selectedAffiliate?.name | titlecase }}
                </button>

                <div class="dropdown-menu dropdown-menu-end"
                    ngbDropdownMenu
                    aria-labelledby="affiliate">

                    @for (affiliate of affiliates; track affiliate.name) {
                      <button class="dropdown-item affiliate"
                        ngbDropdownItem
                        (click)="changeAffiliate(affiliate)"
                      >
                        {{ affiliate.name }}
                      </button>
                    }

                  </div>
              </div>
            }
          </div>

        </li>
        <li class="nav-item">
          <div class="d-flex ms-md-4 flex-row">
            <span class="navbar-text ms-2 d-none d-md-inline data-hj-suppress">{{ userName$ | async }}</span>
            <div ngbDropdown class="dropdown">
              <button ngbDropdownToggle class="btn btn-tertiary dropdown-toggle" id="userAccount" type="button" aria-expanded="false">
                <span class="material-icons-outlined">account_circle</span>
              </button>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="userAccount">
                @if (displayAdminLink){
                  <a ngbDropdownItem class="dropdown-item text-end" id="admin" (click)="goToAdminPage()">
                    {{ 'common.admin' | translate }}</a>
                }
                <a ngbDropdownItem class="dropdown-item text-end" id="logout" (click)="logout()">{{ 'common.logout' | translate }}</a>
              </div>
            </div>
          </div>
        </li>

        @if (translationEnabled){
          <li class="nav-item" >
            <div class="d-flex flex-row">
              <div ngbDropdown class="dropdown d-inline-block">
                <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="language" aria-expanded="false">
                  {{ currentLanguage.toString()| titlecase }}
                </button>
                <div
                  ngbDropdownMenu
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="language"
                >
                  <button
                    class="dropdown-item"
                    (click)="changeLanguage(Language.EN)"
                    [ngClass]="{ active: currentLanguage === Language.EN }">
                    {{ 'common.en' | translate }}
                  </button>
                  <button
                    class="dropdown-item"
                    (click)="changeLanguage(Language.FR)"
                    [ngClass]="{ active: currentLanguage === Language.FR }">
                    {{ 'common.fr' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </li>
        }

      </ul>
      <!-- TotalEnergies Logo -->
      <a routerLink="/">
        <img class="logo py-1 px-2" src="/assets/design-system/TotalEnergies_logo_2x.png" alt="" />
      </a>
    </div>
</div>
