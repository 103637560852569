import { Injectable } from "@angular/core";
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  static readonly DELIMITER = "/";

  parse(value: string): NgbDate | null {
    if (value) {
      const date = new Date(value);
      const result = {
        day: date.getUTCDate(),
        month: date.getUTCMonth() + 1, //months from 1-12
        year: date.getUTCFullYear(),
      };
      return NgbDate.from(result);
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
   return date
      ? date.day + CustomDateParserFormatter.DELIMITER + date.month + CustomDateParserFormatter.DELIMITER + date.year : "";
  }
}
