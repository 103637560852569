import {Injectable, signal} from "@angular/core";

import { Apollo } from "apollo-angular";
import { GET_JOB_CARD_LIST_QUERY } from '../../../../graphql/jobid-card/queries/jobid-card-list.gql';
import { Observable, map } from "rxjs";
import { JobCardInfo, PaginatedResponse } from '../../../../shared/models/response.model';
import {GET_JOB_CARD_MEETING_BOARD} from "@graphql/meeting-boards/getJobCardsMeetingBoard.gql";
import {SET_JOB_CARD_BREAK_OUT_STATUS} from "@graphql/meeting-boards/setJobCardBreakoutStatus.gql";
import {JobGateReadiness} from "@shared/models/jobid-card/jobid-card.model";

@Injectable({
  providedIn: "root",
})
export class JobidCardService {
  readonly DEFAULT_SORT_COLUMN = "id";
  readonly DEFAULT_SORT_DIRECTION = "desc";
  meetingBoardActivities  = signal<JobCardInfo[]>([]);

  constructor(private readonly apollo: Apollo) {
  }

  readJobCards(affiliate: string, page: number, pageSize: number, filter: any[]): Observable<PaginatedResponse<JobCardInfo>> {
    return this.apollo
      .query<any>({
        query: GET_JOB_CARD_LIST_QUERY,
        variables: {
          affiliate: affiliate,
          page: Number(page),
          size: Number(pageSize),
          sortBy: this.DEFAULT_SORT_COLUMN,
          direction: this.DEFAULT_SORT_DIRECTION,
          filter: filter,
        },
      }).pipe(map((result) => result.data.jobCardAggregated));
  }

  getMeetingBoardActivitiesJobCards(affiliate: string, site: string): void {
    this.apollo
      .query<any>({
        query: GET_JOB_CARD_MEETING_BOARD,
        variables: {
          affiliate: affiliate,
          site: site,
          actualStatusList: [JobGateReadiness.SITE_EXECUTION_PREPARATION],
        },
      }).pipe(
        map((result) => this.meetingBoardActivities.set(result.data.searchForMeetingBoard)
    )).subscribe();
  }

  setBreakOutFlag(affiliate: string, site:string, jobcardId: string, isBreakout: boolean): void {

    this.apollo
      .query<any>({
        query: SET_JOB_CARD_BREAK_OUT_STATUS,
        variables: {
          affiliate: affiliate,
          jobCardId: Number(jobcardId),
          isBreakout: isBreakout,
        },
      }).subscribe(() => this.getMeetingBoardActivitiesJobCards(affiliate, site)
    );
  }
}
