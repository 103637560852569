export interface CoreCrewAssistance {
  id: number | undefined;
  description: string;
  status: CoreCrewAssistanceStatus;
  discipline: Discipline;
  displayed: boolean;
  jobCardId: number;
  readiness: string | undefined;
}

export interface CoreCrewAssistanceInput {
  id: number | undefined;
  description: string;
  status: CoreCrewAssistanceStatus;
  discipline: Discipline;
  displayed: boolean;
  jobCardId: number;
}

export enum CoreCrewAssistanceStatus {
  NOT_STARTED = "Not Started",
  PENDING = "Pending",
  VALIDATED = "Validated",
}

export enum Discipline {
  OPS = "OPS",
  INST = "INST",
  ELEC = "ELEC",
  SCAFF = "SCAFF",
  RIGG = "RIGG",
  MECH = "MECH",
  ROPEACCESS = "ROPE Access",
  OTHER = "OTHER",
  "IST" = "IST",
}
