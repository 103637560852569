export function constructFilter(filterModel: any): any[] {
  const filtersToSend = [];
  for (const k in filterModel) {
    if (Object.prototype.hasOwnProperty.call(filterModel, k)) {
      if (filterModel[k].filterType == "date") {
        filtersToSend.push(filtersForDateType(filterModel, k));
      }

      if (filterModel[k].filterType == "text") {
        filtersForTextType(filterModel, k, filtersToSend);
      }
      if (filterModel[k].filterType == "set") {
        filtersToSend.push({ column: k, filter: filterModel[k].filter, type: "in", values: filterModel[k].values });
      }

      if (filterModel[k].filterType == "number") {
        filtersToSend.push(filtersForNumberType(filterModel, k));
      }
    }
  }
  return filtersToSend;
}

function filtersForNumberType(filterModel: any, k: string) {
  if (filterModel[k].type == "inRange") {
    return {
      column: k,
      filter: filterModel[k].filter.toString(),
      type: filterModel[k].type,
      values: [filterModel[k].filter.toString(), filterModel[k].filterTo.toString()],
    };
  } else {
    return {
      column: k,
      filter: filterModel[k].filter.toString(),
      type: filterModel[k].type,
      values: filterModel[k].values,
    };
  }
}

function filtersForTextType(filterModel: any, k: string, filtersToSend: any[]) {
  filtersToSend.push({
    column: k,
    filter: filterModel[k].filter,
    type: filterModel[k].type,
    values: filterModel[k].values,
  });
}

function filtersForDateType(filterModel: any, k: string) {
  if (filterModel[k].type == "inRange") {
    return {
      column: k,
      filter: filterModel[k].filter,
      type: "inRangeDate",
      values: [filterModel[k].dateFrom, filterModel[k].dateTo],
    };
  } else {
    return {
      column: k,
      filter: filterModel[k].dateFrom,
      type: filterModel[k].type,
      values: filterModel[k].values,
    };
  }
}
