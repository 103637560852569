// Used by all select inside Scaffolding
// - CalculationNoteStatus
// - ScaffoldingSchematicStatus
// - ScaffoldingLocationStatus
export enum ScaffoldingGenericEnum {
  NOT_PROVIDED = "Not provided",
  PROVIDED = "Provided",
  VALIDATED = "Validated",
}

export const mScaffoldingGenericEnum: ReadonlyMap<string, string> = new Map(Object.entries(ScaffoldingGenericEnum));

export enum ScaffoldingStatus {
  NOT_STARTED = "Not Started",
  CREATED = "Created",
  VALIDATED = "Scaffolding plan validated",
  READY = "Scaffold ready for use (checked & safety tagged)",
}

export const mScaffoldingStatus: ReadonlyMap<string, string> = new Map(
  Object.entries(ScaffoldingStatus),
);

export enum ScaffoldingDesignSpecificity {
  LOAD_BEARING = "Load bearing",
  OVERBOARD = "Overboard",
  SUSPENDED = "Suspended",
  HEIGHT_MORE_THAN_24 = "Height >24m",
  SPECIFIC_SHAPE = "Specific shape to be deployed",
  GUYING = "Guying",
  BALLAST = "Ballast",
  MORE_THAN_600 = "> 600 kg/sqm on deck",
  INSTALLED_ON_ROOFING = "Installed on roofing",
  LOCATED_ON_AN_INCLINE = "Located on an incline > 10%",
}

export const mScaffoldingDesignSpecificity: ReadonlyMap<string, string> = new Map(
  Object.entries(ScaffoldingDesignSpecificity),
);

export interface ResourceScaffoldingDTO {
  id?: number;
  jobCardId?: number;
  displayed?: boolean;
  description?: string;
  status?: string;
  jobId?: string;
  designSpecificity?: string;
  calculationNoteStatus?: string;
  schematicStatus?: string;
  locationStatus?: string;
  dimensionLength?: string;
  dimensionWidth?: string;
  dimensionHeight?: string;
  volume?: string;
  readiness: string | undefined;
}

export interface ResourceScaffoldingDTOInput {
  id?: number;
  jobCardId?: number;
  displayed?: boolean;
  description?: string;
  status?: string;
  jobId?: string;
  designSpecificity?: string;
  calculationNoteStatus?: string;
  schematicStatus?: string;
  locationStatus?: string;
  dimensionLength?: string;
  dimensionWidth?: string;
  dimensionHeight?: string;
  volume?: string;
}
