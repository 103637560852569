import {Injectable} from "@angular/core";
import {FormArray, UntypedFormBuilder, Validators} from "@angular/forms";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";
import filter from "lodash-es/filter";
import {
  ResourceOnshoreWorksDTO,
  ResourceOnshoreWorksDTOInput
} from "@shared/models/prerequisites/resource-onshore-works.model";
import {AbstractArrayFormService} from "../../abstract-array-form.service";

@Injectable({
  providedIn: "root",
})
export class OnshoreWorksFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  defaultDTO: ResourceOnshoreWorksDTO ={
    excavationRequired: false,
    pipesAndCableIdentificationRequired: false,
    grassCuttingRequired: false,
    securityBridgingDocumentRequired: false,
    communityEngagementThroughCAPDRequired: false,
    readiness: undefined,
  }

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  createFormGroup(dto?: ResourceOnshoreWorksDTO, documents?: Map<string, DocumentDTO[]>) {
    return this.fb.group({
      excavationRequired: [dto?.excavationRequired || false, [Validators.required]],
      pipesAndCableIdentificationRequired: [dto?.pipesAndCableIdentificationRequired || false, [Validators.required]],
      excavationDocumentLink: [documents?.get("excavationDocumentLink"), []],
      grassCuttingRequired: [dto?.grassCuttingRequired || false, [Validators.required]],
      securityBridgingDocumentRequired: [dto?.securityBridgingDocumentRequired || false, [Validators.required]],
      communityEngagementThroughCAPDRequired: [dto?.communityEngagementThroughCAPDRequired || false, [Validators.required]],
      securityBridgingDocumentLink: [documents?.get("securityBridgingDocumentLink"), []],
      jobCardId: [dto?.jobCardId],
      id: [dto?.id],
    });
  }

createForm(isDisabled: boolean, onshoreWorksData?: ResourceOnshoreWorksDTO[], documents?: Map<string, DocumentDTO[]>) {
  if(this.form != undefined && !isDisabled){
    return this.form;
  }

  if(isDisabled || this.form == undefined){
    this.data = onshoreWorksData;

    const isChecked =
      (onshoreWorksData?.length !== 0 && onshoreWorksData?.every((item) => item.displayed)) || false;

    this.form = this.fb.group({
      checked: [{ value: isChecked, disabled: isDisabled }, []],
      items: this.setInitialData(onshoreWorksData, documents),
    });
  }
  this.clearFormOnUnchecked()
  return this.form;
}
getFormData(resourceOnshore: ResourceOnshoreWorksDTO[]): ResourceOnshoreWorksDTOInput[] {
  if (!resourceOnshore) {
    return [];
  }
  if (!this.form) return resourceOnshore.map((work) => this.copy(work));

  const formItems: FormArray = this.form.controls.items as FormArray;
  let items: ResourceOnshoreWorksDTO[] = [];

  if (formItems && formItems.controls.length !== 0) {
    items = formItems.controls.map((item: any) => {
      item = this.copyFromFormItem(item.controls);
      item.jobCardId = this.jobCardId;
      item.displayed = this.form.controls.checked.value;
      return item;
    });
  }

  return items;
}

get formArrayName() {
  return "items";
}

private copyFromFormItem(formItem : any){
  return {
    id: formItem.id?.value,
    excavationRequired: formItem.excavationRequired.value,
    pipesAndCableIdentificationRequired: formItem.pipesAndCableIdentificationRequired.value,
    grassCuttingRequired: formItem.grassCuttingRequired.value,
    securityBridgingDocumentRequired: formItem.securityBridgingDocumentRequired.value,
    communityEngagementThroughCAPDRequired: formItem.communityEngagementThroughCAPDRequired.value,
  };
}

  private copy(onshore: ResourceOnshoreWorksDTO): ResourceOnshoreWorksDTOInput {
    return {
      id: onshore.id,
      jobCardId: onshore.jobCardId,
      displayed: onshore.displayed,
      excavationRequired: onshore.excavationRequired,
      pipesAndCableIdentificationRequired: onshore.pipesAndCableIdentificationRequired,
      grassCuttingRequired: onshore.grassCuttingRequired,
      securityBridgingDocumentRequired: onshore.securityBridgingDocumentRequired,
      communityEngagementThroughCAPDRequired: onshore.communityEngagementThroughCAPDRequired,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
      if (!documentDTOList && !this.form) {
        return [];
      }

      if(!this.form){
        const filteredDocs :DocumentDTO[] = filter(documentDTOList, {'section': 'ONSHORE_WORKS'});
        return filteredDocs.map(doc => this.copyDocument(doc));
      }
      const excavationDocumentLink: DocumentDTO[] = [];
      const securityBridgingDocumentLink: DocumentDTO[] = [];
      this.form?.controls["items"]?.value?.forEach(
        (item: {
          excavationDocumentLink: DocumentDTO[];
          securityBridgingDocumentLink: DocumentDTO[];
          }) => {
          item.excavationDocumentLink?.forEach((doc: DocumentDTO) => excavationDocumentLink.push(this.copyDocument(doc)));
          item.securityBridgingDocumentLink?.forEach((doc: DocumentDTO) => securityBridgingDocumentLink.push(this.copyDocument(doc)));
        },
      );

      return [
        excavationDocumentLink,
        securityBridgingDocumentLink
      ].reduce((acc, val) => acc.concat(val), []);
  }
}
