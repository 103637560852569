import {Injectable} from "@angular/core";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ResourceScaffoldingDTO, ResourceScaffoldingDTOInput,} from "@shared/models/prerequisites";
import {startWith, takeUntil} from "rxjs/operators";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";
import filter from "lodash-es/filter";

@Injectable({
  providedIn: "root",
})
export class ScaffoldingFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  defaultDTO: ResourceScaffoldingDTO = {
    readiness: undefined
  };

  expandedStates: boolean[] = [];

  isDisable!: boolean;

  get formArrayName() {
    return "items";
  }

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  createFormGroup(
    dto: ResourceScaffoldingDTO,
    documents?: Map<string, DocumentDTO[]>
  ) {
    const formGroup = this.fb.group({
      description: [dto?.description, [Validators.required]],
      status: [dto?.status, [Validators.required]],
      jobId: [dto?.jobId],
      designSpecificity: [dto?.designSpecificity?.split(",") || []],
      calculationNoteStatus: [dto?.calculationNoteStatus],
      calculationNoteStatusDocumentLink: [documents?.get("calculationNoteStatusDocumentLink"), []],
      schematicStatus: [dto?.schematicStatus],
      schematicStatusDocumentLink: [documents?.get("schematicStatusDocumentLink"), []],
      locationStatus: [dto?.locationStatus],
      locationStatusDocumentLink: [documents?.get("locationStatusDocumentLink"), []],
      dimensionLength: [dto?.dimensionLength],
      dimensionWidth: [dto?.dimensionWidth],
      dimensionHeight: [dto?.dimensionHeight],
      volume: [dto?.volume],
      jobCardId: [dto.jobCardId]
    });

    formGroup
      .get("designSpecificity")
      ?.valueChanges.pipe(startWith(dto?.designSpecificity), takeUntil(this.destroy))
      .subscribe((value) => {
        if (value && value.length > 0) {
          formGroup.get("schematicStatus")?.reset();
          formGroup.get("schematicStatus")?.disable();
          formGroup.get("calculationNoteStatus")?.enable();
          this.isDisable = true;
          formGroup.patchValue({
            schematicStatusDocumentLink: [],
          });
        } else {
          formGroup.get("calculationNoteStatus")?.disable();
          formGroup.get("calculationNoteStatus")?.reset();
          formGroup.get("schematicStatus")?.enable();
          this.isDisable = false;
          formGroup.patchValue({
            calculationNoteStatusDocumentLink: [],
          });
        }
      });

    return formGroup;
  }

  removeItem(i: number) {
    super.removeItem(i);
    this.expandedStates.splice(i, 1);
  }

  addNewItem(item?: any) {
    super.addNewItem(item);
    this.expandedStates.push(false);
  }

  createForm(
    isModeReadOnly: boolean,
    data?: ResourceScaffoldingDTO[],
    documents?: Map<string, DocumentDTO[]>,
    isPrerequisitesPage?: boolean
  ) {

    if (this.form != undefined && !isModeReadOnly) {
      return this.form;
    }

    if (isModeReadOnly || this.form == undefined) {
      this.data = data;

      const isChecked = (data?.length !== 0 && data?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{value: isChecked, disabled: isModeReadOnly}, []],
        items: this.setInitialData(data, documents),
      });

      this.expandedStates =
        this.expandedStates?.length === 0 ? this.formArray.controls.map(() => !isPrerequisitesPage) : this.expandedStates;
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(resourceScaffolding: ResourceScaffoldingDTO[]): ResourceScaffoldingDTOInput[] {
    if (!resourceScaffolding) {
      return [];
    }
    if (!this.form) return resourceScaffolding.map(scaffolding => this.copy(scaffolding.id, scaffolding));

    let items: ResourceScaffoldingDTO[] = [];
    if (this.formArray.getRawValue() && this.formArray.getRawValue().length !== 0) {
      items = this.formArray.getRawValue().map((item: any, i: number) => {
        item = this.copy(resourceScaffolding[i]?.id, item);
        item.jobCardId = this.jobCardId;
        item.displayed = this.form.controls.checked.value;
        item.designSpecificity = this.arrayControl(i, "designSpecificity").value.join(",");
        return item;
      });
    }

    return items;
  }

  private copy(id: number | undefined, scaffolding: ResourceScaffoldingDTO): ResourceScaffoldingDTOInput {
    return {
      id: id,
      jobCardId: scaffolding.jobCardId,
      displayed: scaffolding.displayed,
      description: scaffolding.description,
      status: scaffolding.status,
      jobId: scaffolding.jobId,
      designSpecificity: scaffolding.designSpecificity,
      calculationNoteStatus: scaffolding.calculationNoteStatus,
      schematicStatus: scaffolding.schematicStatus,
      locationStatus: scaffolding.locationStatus,
      dimensionLength: scaffolding.dimensionLength,
      dimensionWidth: scaffolding.dimensionWidth,
      dimensionHeight: scaffolding.dimensionHeight,
      volume: scaffolding.volume,
    };
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
    if (!documentDTOList && !this.form) {
      return [];
    }

    if (!this.form) {
      const filteredDocs: DocumentDTO[] = filter(documentDTOList, {'section': 'SCAFFOLDING'});
      return filteredDocs.map(doc => this.copyDocument(doc));
    }

    const calculationNoteStatus: DocumentDTO[] = [];
    const schematicStatus: DocumentDTO[] = [];
    const locationStatus: DocumentDTO[] = [];
    this.form?.controls["items"]?.value?.forEach(
      (item: {
        calculationNoteStatusDocumentLink: DocumentDTO[];
        schematicStatusDocumentLink: DocumentDTO[];
        locationStatusDocumentLink: DocumentDTO[];
      }) => {
        item.calculationNoteStatusDocumentLink?.forEach((doc: DocumentDTO) => calculationNoteStatus.push(this.copyDocument(doc)));
        item.schematicStatusDocumentLink?.forEach((doc: DocumentDTO) => schematicStatus.push(this.copyDocument(doc)));
        item.locationStatusDocumentLink?.forEach((doc: DocumentDTO) => locationStatus.push(this.copyDocument(doc)));
      });

    return [
      calculationNoteStatus,
      schematicStatus,
      locationStatus
    ].reduce((acc, val) => acc.concat(val), []);
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
