import { Component, Input, OnChanges } from "@angular/core";
import { Classification } from "@shared/services/job-card-master/job-card-master.service";

@Component({
  selector: "jbid-jcm-classification",
  templateUrl: "./jcm-classification.component.html",
  styleUrls: ["./jcm-classification.component.scss"],
})
export class JcmClassificationComponent implements OnChanges {
  @Input() classifications: Classification[] = [];
  totalCounts: Classification = {
    label: "Job Cards linked",
    count: 0,
    countReady: 0,
    countAwaitingPreparation: 0,
    countDelayed: 0,
    countReadyToActivate: 0,
    countDraft: 0,
  };
  ngOnChanges(): void {
    this.totalCounts = {
      label: "Job Cards linked",
      count: 0,
      countReady: 0,
      countAwaitingPreparation: 0,
      countDelayed: 0,
      countReadyToActivate: 0,
      countDraft: 0,
    };
    for (const c of this.classifications) {
      this.totalCounts.count += c.count;
      this.totalCounts.countReady += c.countReady;
      this.totalCounts.countAwaitingPreparation += c.countAwaitingPreparation;
      this.totalCounts.countDelayed += c.countDelayed;
      this.totalCounts.countReadyToActivate += c.countReadyToActivate;
      this.totalCounts.countDraft += c.countDraft;
    }
  }
}
