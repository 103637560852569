import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from '@shared/@types/i18n';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  currentLanguage = new BehaviorSubject<Language>(Language.EN);

  constructor(private translate: TranslateService) {}

  public init(languages: Language[] = [Language.EN, Language.FR]): void {
      this.translate.addLangs(languages);
      this.translate.setDefaultLang(Language?.EN );

      const browserLang = this.translate.getBrowserLang() ?? Language.EN;
      this.translate.use(/en|fr/.exec(browserLang)? browserLang : Language.EN)
  }

  public changeLanguage(langCode: Language): void {
      this.translate.use(langCode).subscribe(() => {
          this.currentLanguage.next(langCode);
      });
  }

  public getCurrentLanguage(): any {
    return this.currentLanguage.value;
  }
}
