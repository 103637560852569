import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Apollo } from "apollo-angular";
import { IServerSideGetRowsParams } from "ag-grid-enterprise";
import { ProfileService } from "@shared/services";
import {
  GET_ALL_JOB_CARD_MASTER_LIST_QUERY,
  GET_JOB_CARD_MASTER_LIST_QUERY,
} from "@graphql/job-card-master/queries/job-card-master-list.gql";
import { MUTATE_LINK_JOB_CARDS_TO_JOB_CARD_MASTER } from "@graphql/job-card-master/mutation/link-job-cards-to-job-card-master-mutation.gql";
import { MUTATE_UNLINK_JOB_CARDS_TO_JOB_CARD_MASTER } from "@graphql/job-card-master/mutation/unlink-job-cards-to-job-card-master-mutation.gql";
import { GET_JCM_KPIS } from "@graphql/job-card-master/queries/general-details-kpis.gql";
import { GET_JCM_HIGHLIGHTS } from "@graphql/job-card-master/queries/general-details-highlights.gql";
import { GET_COMPUTE_JOBCARD_COUNT_BY_STATUS_QUERY } from "@graphql/job-card-master/queries/getComputedJobCardCountByStatus.gql";
import { MUTATE_LINK_JOB_CARD_TO_JOB_CARD_MASTER_BY_REVCODE } from "@graphql/job-card-master/mutation/link-job-cards-to-job-card-master-by-revcode-mutation.gql";
import { JobCardMasterAggregate, MassiveLinkReportDTO } from "@shared/models/job-card-master/job-card-master.model";
import { GET_JOB_CARD_ORGANIZATION } from "@graphql/job-card-master/queries/organization.gql";
import { constructFilter } from "@shared/table-utils";
import { FREEZE_JCM } from "@graphql/job-card-master/queries/freezeJcm.gql";

@Injectable({
  providedIn: "root",
})
export class JobCardMasterApiService {
  private destroy = new Subject<void>();

  defaultSizePerPage = 30;
  readonly MAX_NUM_KEY = 100000;
  constructor(private apollo: Apollo, private profileService: ProfileService) {}

  getDatasource() {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const pageToGet =
          params?.request && params?.request?.startRow ? params?.request?.startRow / this.defaultSizePerPage : 0;
        const filterToSend = constructFilter(params.request.filterModel);
        this.apollo
          ?.query<any>({
            query: GET_JOB_CARD_MASTER_LIST_QUERY,
            variables: {
              affiliate: this.profileService.getAffiliate().value,
              page: pageToGet,
              size: this.defaultSizePerPage,
              sortBy: params.request.sortModel.length == 0 ? "" : params.request.sortModel[0]?.colId,
              direction: params.request.sortModel.length == 0 ? "" : params.request.sortModel[0]?.sort,
              filter: filterToSend,
            },
          })
          .subscribe((result) => {
            params.success({
              rowData: result.data.getJobCardsMasterPage.jobCardMastersList,
              rowCount: result.data.getJobCardsMasterPage.totalItems,
            });
          });
      },
      destroy: () => this.destroy.next(),
    };
  }

  getJobCardMasterLinkedAllowed() {
    return this.apollo.query<{
      getJobCardMasterLinkedAllowed: {
        id: number;
        title: string;
        owner: string;
        isScopeFrozen: boolean;
      }[];
    }>({
      query: GET_ALL_JOB_CARD_MASTER_LIST_QUERY,
      variables: {
        affiliate: this.profileService.getAffiliate().value,
      },
    });
  }

  linkJobCardsToJobCardMaster(jobCardIdsToLinkList: string[], id: any) {
    return this.apollo.mutate({
      mutation: MUTATE_LINK_JOB_CARDS_TO_JOB_CARD_MASTER,
      variables: {
        affiliate: this.profileService.getAffiliate().value,
        id: Number(id),
        jobCardIds: jobCardIdsToLinkList.map((jobCardId) => Number(jobCardId)),
      },
    });
  }
  unlinkJobCardsToJobCardMaster(jobCardIdsToUnlinkList: string[], id: any) {
    return this.apollo.mutate({
      mutation: MUTATE_UNLINK_JOB_CARDS_TO_JOB_CARD_MASTER,
      variables: {
        affiliate: this.profileService.getAffiliate().value,
        id: Number(id),
        jobCardIds: jobCardIdsToUnlinkList.map((jobCardId) => Number(jobCardId)),
      },
    });
  }
  linkJobCardToJobCardMasterByRevcode(affiliate: string, masterId: string, revisionCode: string) {
    return this.apollo.mutate<{ linkByRevisionCode: MassiveLinkReportDTO }>({
      mutation: MUTATE_LINK_JOB_CARD_TO_JOB_CARD_MASTER_BY_REVCODE,
      variables: {
        affiliate: affiliate,
        masterId: Number(masterId),
        revisionCode: revisionCode,
      },
    });
  }

  loadJCMKpis(affiliate: string, id: number) {
    return this.apollo.query<any>({
      query: GET_JCM_KPIS,
      variables: {
        affiliate: affiliate,
        id: Number(id),
      },
    });
  }

  loadJCMHighlights(affiliate: string, id: number, flocDetectionLevel: number) {
    return this.apollo.query<any>({
      query: GET_JCM_HIGHLIGHTS,
      variables: {
        affiliate: affiliate,
        id: Number(id),
        flocDetectionLevel: Number(flocDetectionLevel),
      },
    });
  }

  computeJCMCounts(affiliate: string, masterId: number | undefined) {
    return this.apollo.query<any>({
      query: GET_COMPUTE_JOBCARD_COUNT_BY_STATUS_QUERY,
      variables: {
        affiliate: affiliate,
        masterId: masterId ? Number(masterId) : -1,
      },
    });
  }

  loadJobCardOrganization(affiliate: string, id: number) {
    return this.apollo.query<any>({
      query: GET_JOB_CARD_ORGANIZATION,
      variables: {
        affiliate: affiliate,
        id: Number(id),
      },
    });
  }

  freezeJcm(id: number) {
    return this.apollo.mutate<{ freezeJobCardMasterScope: JobCardMasterAggregate }>({
      mutation: FREEZE_JCM,
      variables: {
        affiliate: this.profileService.getAffiliate().value,
        id: Number(id),
      },
    });
  }
}
