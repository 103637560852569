import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import {SpecificLogistic, SpecificLogisticInput} from "@shared/models/prerequisites/specific-logistic.model";

@Injectable({
  providedIn: "root",
})
export class SpecificLogisticFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(specificLogistic?: SpecificLogistic) {
    return this.fb.group({
      id: [specificLogistic?.id],
      description: [specificLogistic?.description, [Validators.required]],
      status: [specificLogistic?.status],
      displayed: [specificLogistic?.displayed, []],
      jobCardId: [this.jobCardId],
    });
  }

  createForm(isModeReadOnly: boolean, specificLogistics?: SpecificLogistic[]) {
    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = specificLogistics;

      const isChecked = (specificLogistics?.length !== 0 && specificLogistics?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isModeReadOnly }, []],
        items: this.setInitialData(specificLogistics),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(specificLogistics: SpecificLogistic[]): SpecificLogisticInput[] {
    if (!specificLogistics) {
      return [];
    }
    if (!this.form) return specificLogistics.map((specificLogistic) => this.copy(specificLogistic));

    let itemsInput: SpecificLogisticInput[] = [];
    if (this.form?.value && this.form?.value[this.formArrayName] && this.form?.value[this.formArrayName].length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return item.description || item.status;
      }).map((item: SpecificLogistic) => {
        const itemInput = this.copy(item);
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }

    return itemsInput;
  }

  private copy(specificLogistic: SpecificLogistic): SpecificLogisticInput {
    return {
      id: specificLogistic.id,
      description: specificLogistic.description,
      status: specificLogistic.status,
      displayed: specificLogistic.displayed,
      jobCardId: specificLogistic.jobCardId,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
