import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { JobCardCreationInput } from "@shared/models/jobid-card/jobid-card.model";
import { ProfileService } from "@shared/services/profile/profile.service";
import { JobidCardDetailService } from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import { buildSearchFunction } from "@shared/utils";
import { ApolloQueryResult } from "@apollo/client";
import { SitePlatformsService } from "@shared/services";
import { RevisionCodeService } from "@shared/services/revision-code/revision-code.service";
import { SiteInfo } from "@shared/models/siteplatform/site-platform.model";

@Component({
  selector: "jbid-add-new-job-card-modal",
  templateUrl: "./add-new-job-card-modal.component.html",
  styleUrls: ["./add-new-job-card-modal.component.scss"],
})
export class AddNewJobCardModalComponent implements OnInit, OnDestroy {
  public isBtValidateActivate!: boolean;
  public woNotExisting!: boolean;
  jobidCardGroupForm!: UntypedFormGroup;
  siteInfos: SiteInfo[] = [];
  getWoDigitMax = this.profileService.getAffiliateConfig().workOrderDigits;
  workOrderDetails!: {
    disciplineCategory: any;
    sitePlatform: any;
    flocPlatform: any;
    description: any;
    discipline: any;
    metier: any;
  };
  revisionCodes: string[] = [];
  flocsitesList: string[] = [];
  flocPlatformsforSite: string[] = [];
  existingJobCardId!: string | undefined;
  newWorkOrderNumber!: string;

  search = buildSearchFunction((term: string) =>
    term === ""
      ? []
      : this.siteInfos
          .filter((v) => v.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10)
          .map((v) => v.displayName),
  );
  private destroy = new Subject<void>();
  massiveUpload = new BehaviorSubject(false);

  searchRevisionCode = buildSearchFunction((term: string) =>
    term ? this.revisionCodes.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10) : [],
  );
  searchFlocPlatform = buildSearchFunction((term: string) =>
    term ? this.flocPlatformsforSite.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10) : [],
  );

  constructor(
    public activeModal: NgbActiveModal,
    private jobCardDetailService: JobidCardDetailService,
    private sitePlatformsService: SitePlatformsService,
    private profileService: ProfileService,
    private revisionCodeService: RevisionCodeService,
  ) {}

  ngOnInit(): void {
    this.jobidCardGroupForm = new UntypedFormGroup(
      {
        workOrder: new UntypedFormControl("", [
          Validators.required,
          Validators.maxLength(this.getWoDigitMax),
          Validators.pattern("^[0-9]*$"),
        ]),
        siteVisitOnly: new UntypedFormControl(false),
        site: new UntypedFormControl("", [Validators.required]),
        flocPlatform: new UntypedFormControl(""),
        jobTitle: new UntypedFormControl("", [Validators.required]),
        revisionCode: new UntypedFormControl("", [Validators.required]),
        creationConfirmation: new UntypedFormControl(false),
      },
      { validators: this.jobCardNoWorkOrder },
    );

    this.jobidCardGroupForm.get("flocPlatform")?.disable();
    this.massiveUpload.pipe(takeUntil(this.destroy)).subscribe((isMassiveUpload) => {
      if (isMassiveUpload) {
        this.jobidCardGroupForm.get("workOrder")?.disable();
        this.jobidCardGroupForm.get("site")?.disable();
        this.jobidCardGroupForm.get("jobTitle")?.disable();
        this.jobidCardGroupForm.get("revisionCode")?.enable();
      } else {
        this.jobidCardGroupForm.get("workOrder")?.enable();
        this.jobidCardGroupForm.get("site")?.enable();
        this.jobidCardGroupForm.get("jobTitle")?.enable();
        this.jobidCardGroupForm.get("revisionCode")?.disable();
      }
      this.jobidCardGroupForm.updateValueAndValidity();
    });
    this.jobidCardGroupForm.get("workOrder")?.valueChanges.subscribe((selectedValue) => {
      this.isBtValidateActivate = false;
      this.woNotExisting = false;
      this.existingJobCardId = undefined;
      if ((selectedValue?.toString() || "").length == this.getWoDigitMax) {
        setTimeout(() => {
          this.jobCardDetailService.loadWorkorderJobCardLink(selectedValue).subscribe((next) => {
            this.onWorkOrderLoad(next);
          });
        }, 100);
      }
    });
    this.jobidCardGroupForm.get("site")?.valueChanges.subscribe((selectedValue) => {
      this.platformFieldEnabler(selectedValue);
    });

    combineLatest([
      this.jobidCardGroupForm
        .get("site")
        ?.valueChanges.pipe(startWith(this.jobidCardGroupForm.get("site")?.value as string)),
      this.jobidCardGroupForm
        .get("jobTitle")
        ?.valueChanges.pipe(startWith(this.jobidCardGroupForm.get("jobTitle")?.value as string)),
    ])
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        if (
          this.jobidCardGroupForm.get("site")?.value.length > 0 &&
          this.jobidCardGroupForm.get("jobTitle")?.value.length > 0 &&
          this.f.workOrder.invalid
        ) {
          this.jobidCardGroupForm.get("workOrder")?.patchValue("");
        }
      });
    this.jobCardDetailService.createdJcId$.subscribe((id) => {
      this.activeModal.close({jobCardId: id, workOrderNumber: this.newWorkOrderNumber});
    });

    combineLatest([this.sitePlatformsService.siteInfoDefaults$, this.revisionCodeService.revisionCodes$]).subscribe(
      (next) => {
        this.siteInfos = next[0] || [];
        this.siteInfos.forEach((element: SiteInfo) => {
          this.flocsitesList.push(element?.displayName);
        });
        this.revisionCodes = next[1] || [];
        this.jobidCardGroupForm.updateValueAndValidity();
      },
    );
  }

  private onWorkOrderLoad(result: ApolloQueryResult<any>) {
    if (result.errors || !result?.data?.findJobCardAndWorkOrderLink) {
      this.woNotExisting = true;
      return;
    }

    this.workOrderDetails = {
      description: result?.data?.findJobCardAndWorkOrderLink.workOrderDescription,
      discipline: result?.data?.findJobCardAndWorkOrderLink.discipline,
      disciplineCategory: result?.data?.findJobCardAndWorkOrderLink.disciplineCategory,
      flocPlatform: result?.data?.findJobCardAndWorkOrderLink.flocPlatform,
      metier: result?.data?.findJobCardAndWorkOrderLink.metier,
      sitePlatform: result?.data?.findJobCardAndWorkOrderLink.sitePlatform,
    };
    if (!result?.data?.findJobCardAndWorkOrderLink?.jobTitle) {
      this.initForm(
        this.sitePlatformsService.mapSiteNameToDisplayName(this.workOrderDetails.sitePlatform),
        this.workOrderDetails.flocPlatform,
        this.workOrderDetails.description,
      );
      this.changeStateSiteAndJobTitle(false);
      this.isBtValidateActivate = true;
      this.newWorkOrderNumber = result.data.findJobCardAndWorkOrderLink.workOrderNumber;
    } else {
      this.initForm();
      this.existingJobCardId = result.data.findJobCardAndWorkOrderLink.id;
    }
  }

  onSubmit() {
    if (this.massiveUpload.value) {
      const revisionCode = this.jobidCardGroupForm.get("revisionCode")?.value;
      this.jobCardDetailService.createJobCardsWithRevisionCode(revisionCode);
      this.activeModal.close();
    } else {
      const jobCardCreationInput: JobCardCreationInput = {
        jobTitle: this.jobidCardGroupForm.get("jobTitle")?.value,
        workOrderNumber: +this.jobidCardGroupForm.get("workOrder")?.value,
        sitePlatform: this.jobidCardGroupForm.get("site")?.value,
        flocPlatform: this.jobidCardGroupForm.get("flocPlatform")?.value || "",
        metier: this.workOrderDetails ? this.workOrderDetails.metier : "",
        disciplineCategory: this.workOrderDetails ? this.workOrderDetails.disciplineCategory : "",
        discipline: this.workOrderDetails ? this.workOrderDetails.discipline : "",
        siteVisitOnly: this.jobidCardGroupForm.get("siteVisitOnly")?.value,
        affiliate: this.profileService.getAffiliate().value,
      };
      this.jobCardDetailService.createJobCard(jobCardCreationInput);
    }
  }

  initForm(site: string = "", flocPlatform: string = "", jobTitle: string = "") {
    this.jobidCardGroupForm.patchValue({
      site: site,
      jobTitle: jobTitle,
      flocPlatform: this.flocsitesList.includes(site) ? flocPlatform : "",
    });
  }

  get f() {
    return this.jobidCardGroupForm.controls;
  }

  jobCardNoWorkOrder = () => {
    if (this.massiveUpload.value) {
      const revisionCode = this.jobidCardGroupForm.get("revisionCode");
      const creationConfirmation = this.jobidCardGroupForm.get("creationConfirmation")?.value;
      this.isBtValidateActivate = revisionCode ? revisionCode.valid && creationConfirmation === true : false;
      return null;
    }
    if (!this.jobidCardGroupForm) {
      return null;
    }
    const validFlocSite =
      this.jobidCardGroupForm.get("flocPlatform")?.value === "" ||
      this.flocPlatformsforSite.includes(this.jobidCardGroupForm.get("flocPlatform")?.value);
    const validPlatForm =
      this.checkNoWorkOrderCase() &&
      this.siteInfos?.map((s) => s.displayName).includes(this.jobidCardGroupForm.get("site")?.value) &&
      validFlocSite;

    this.isBtValidateActivate = validPlatForm;
    return validPlatForm
      ? null
      : {
          jobCardNoWorkOrder: {
            valid: false,
          },
        };
  };

  checkWorkOrderValidation() {
    let workOrderValid = false;
    if (this.f.workOrder.value.length > 0 && this.f.workOrder.value.length != this.getWoDigitMax) {
      // Reset site and job title input if workOrder length > 0
      this.initForm();
      this.changeStateSiteAndJobTitle(false);
    }
    if (!(this.f.workOrder.invalid && this.f.workOrder.dirty) && (this.existingJobCardId || this.woNotExisting)) {
      // If workOrder have been modified, is valid, but work Order doesn't exist or it is already link to a job card
      workOrderValid = true;
    }
    if (this.f.workOrder.invalid && this.f.workOrder.dirty) {
      // If work order have been modified and invalid
      workOrderValid = true;
    }
    if (this.f.workOrder.invalid && this.f.workOrder.dirty && !this.f.site.dirty && !this.f.jobTitle.dirty) {
      // If work order have been modified and invalid but site and jobTitle haven't been modified
      workOrderValid = true;
      this.initForm();
    }
    if (this.f.site.valid && this.f.jobTitle.valid && this.f.workOrder.invalid) {
      // If site and job title are valid but workOrder invalid
      workOrderValid = false;
    }
    if (this.f.workOrder.value == "") {
      // If work order is empty, enable site and job Title state
      this.changeStateSiteAndJobTitle(true);
      workOrderValid = false;
    }

    return workOrderValid;
  }

  checkNoWorkOrderCase = () => {
    const site = this.jobidCardGroupForm?.get("site")?.value;
    const jobtitle = this.jobidCardGroupForm?.get("jobTitle")?.value;

    return (
      this.jobidCardGroupForm?.get("workOrder")?.value.toString().length == 0 && site.length > 0 && jobtitle.length > 0
    );
  };

  changeStateSiteAndJobTitle(state: boolean) {
    if (state) {
      this.jobidCardGroupForm.get("site")?.enable();
      this.jobidCardGroupForm.get("jobTitle")?.enable();
      this.jobidCardGroupForm.get("siteVisitOnly")?.enable();
    } else {
      this.jobidCardGroupForm.get("site")?.disable();
      this.jobidCardGroupForm.get("jobTitle")?.disable();
      this.jobidCardGroupForm.get("siteVisitOnly")?.disable();
      this.jobidCardGroupForm.get("flocPlatform")?.disable();
    }
  }
  platformFieldEnabler(site: string) {
    this.flocPlatformsforSite = [];
    const valid = this.flocsitesList?.includes(site);
    if (valid) {
      this.siteInfos.forEach((element: SiteInfo) => {
        if (element.displayName === site) {
          this.flocPlatformsforSite = element?.platforms || [];
        }
      });
    }
    if (this.flocPlatformsforSite.length == 0) {
      this.jobidCardGroupForm.get("flocPlatform")?.disable();
    } else {
      this.jobidCardGroupForm.get("flocPlatform")?.enable();
    }
  }

  formatter = (result: string) => result.toUpperCase();

  changeSapWorkOrderState() {
    if (this.jobidCardGroupForm.get("siteVisitOnly")?.value) {
      this.jobidCardGroupForm.get("workOrder")?.enable();
    } else {
      this.jobidCardGroupForm.get("workOrder")?.disable();
    }
  }

  goToExistingJobCard() {
    if (this.existingJobCardId != undefined) {
      this.activeModal.close({jobCardId: this.existingJobCardId});
    }
  }
  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  changeMassiveUploadState(isMassiveUpload: boolean) {
    this.massiveUpload.next(isMassiveUpload);
  }
}
