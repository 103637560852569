import gql from "graphql-tag";

const GET_DISCIPLINE_CATEGORY_BY_AFFILIATE_QUERY = gql`
  query ($affiliate: String) {
    getDisciplineCategoriesByAffiliate(affiliate: $affiliate) {
      id
      name
      description
      affiliate
    }
  }
`;

export { GET_DISCIPLINE_CATEGORY_BY_AFFILIATE_QUERY };
