import gql from "graphql-tag";

const JOB_CARD_MASTER_CREATION_MUTATION = gql`
  mutation createJobCardMaster($affiliate: String, $input: JobCardsMasterInput) {
    createJobCardMaster(affiliate: $affiliate, input: $input) {
      id
      title
      affiliate
      mainSite
      sites
      startDate
      endDate
      owner
      isCampaign
      generalStatus
      actualStatus
      shortfall
      shortfallWaterInjection
      budgets
      pob
      specificAccommodation
      isShutDownDuringCampaign
      shutDownDate
      shutDownDuration
      revisionCode
      lastModified
      isScopeFrozen
    }
  }
`;

export { JOB_CARD_MASTER_CREATION_MUTATION };
