import { Injectable } from "@angular/core";
import { GET_DISCIPLINE_CATEGORY_BY_AFFILIATE_QUERY } from "@graphql/jobid-card/discipline/queries/disciplineCategory.gql";
import { DisciplineCategory } from "@shared/models/siteplatform/site-platform.model";
import { Apollo } from "apollo-angular";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileService } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class DisciplineCategoriesService {
  private _disciplineCategories$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  disciplineCategories$: Observable<string[]> = this._disciplineCategories$.asObservable();

  constructor(private profileService: ProfileService, private apollo: Apollo) {
    this.profileService.affiliate$.subscribe({
      next: (affiliate) => {
        this.apollo
          .query<any>({
            query: GET_DISCIPLINE_CATEGORY_BY_AFFILIATE_QUERY,
            variables: {
              affiliate: affiliate.value,
            },
          })
          .subscribe(({ data }) => {
            const categories = data.getDisciplineCategoriesByAffiliate as DisciplineCategory[];
            this._disciplineCategories$.next(
              categories.map((disciplineCategory, _id) => disciplineCategory.name).sort((a, b) => a.localeCompare(b)),
            );
          });
      },
    });
  }
}
