import { Observable, OperatorFunction } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

export function getDateFromString(date: string | null): Date | null {
  if (date) {
    const [day, month, year] = date.split("/");
    const dateConverted = new Date(+year, Number.parseFloat(month) - 1, +day);
    if (!isNaN(dateConverted.getTime())) {
      return dateConverted;
    }
  }
  return null;
}

export function buildSearchFunction(
  mappingFunction: (term: string) => string[],
): OperatorFunction<string, readonly string[]> {
  return (text$: Observable<string>) => text$.pipe(debounceTime(200), distinctUntilChanged(), map(mappingFunction));
}

export function getEnumKeyByValue(enumObject: any, value: any, defaultValue?: string): string | undefined {
  return Object.keys(enumObject).find((key) => enumObject[key] === value) || defaultValue || undefined;
}
