<div class="alert alert-dismissible d-flex mb-0" [ngClass]="{
        'alert-warning': toastPackage.toastType === 'error',
        'alert-danger': toastPackage.toastType === 'report_problem',
        'alert-success': toastPackage.toastType === 'check_circle',
        'alert-info': toastPackage.toastType === 'info'
      }">
  <span class="material-icons">{{ toastPackage.toastType }}</span>
  <button type="button" class="btn-close" *ngIf="options.closeButton" (click)="handleCloseClick($event)"></button>
  <div>
    <p *ngIf="title" class="alert-heading" [class]="options.titleClass" [innerHTML]="title"></p>
    <p *ngIf="message" class="mb-0" [innerHTML]="message"></p>
  </div>
</div>
