import { Injectable } from "@angular/core";
import { ProfileService } from "../profile/profile.service";
import { Apollo } from "apollo-angular";
import { GET_JOB_LEADERS } from "@graphql/jobid-card/detail/general-details/queries/job-leaders.gql";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class JobLeadersService {

  private _jobLeaders$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  jobLeaders$: Observable<string[]> = this._jobLeaders$.asObservable();

  constructor(private profileService: ProfileService, private apollo: Apollo) {
    this.profileService.affiliate$.subscribe({ next: affiliate => {
      this.apollo
      .query<any>({
        query: GET_JOB_LEADERS,
        variables: {
          affiliate: affiliate.value,
        },
      }).subscribe(({ data }) => {
        const jobLeaders = data.getJobLeaders;
        this._jobLeaders$.next(jobLeaders);
      });
    } });
  }


}
