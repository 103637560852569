export interface TemporaryEquipment {
  id: number;
  description: string;
  status: TemporaryEquipmentStatus;
  displayed: boolean;
  jobCardId: number;
  readiness: string | undefined;
}

export interface TemporaryEquipmentInput {
  id: number;
  description: string;
  status: TemporaryEquipmentStatus;
  displayed: boolean;
  jobCardId: number;
}

export enum TemporaryEquipmentStatus {
  NOT_STARTED = "Not Started",
  BASE_QA_QC_OK = "Base QA/QC checks OK",
  NOT_OK = "Base Not OK",
  SITE_RECEIVED = "Offshore received",
  SITE_QA_QC_OK = "Offshore reception checks",
}
