import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderPrerequisite } from '@shared/models/work-orders/workorder-details.model';
import { JobidCardDetailService } from '@shared/services/jobid-card-detail/jobid-card-detail.service';
import { ToastrService } from 'ngx-toastr';
import { distinct, filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'jbid-add-work-order-prerequisites',
  templateUrl: './add-work-order-prerequisites.component.html'
})
export class AddWorkOrderPrerequisitesComponent implements OnDestroy{

  constructor(private activeModal: NgbActiveModal, private jobCardDetailService: JobidCardDetailService, private toastr: ToastrService, private translateService: TranslateService) {}

  @Input() prerequisites: WorkOrderPrerequisite[] = [];
  @Input() jobCardId!: number;
  @Input() jobCardCreation = false;
  @Output() prerequisitesAdded = new EventEmitter<boolean>();

  private destroy = new Subject<void>();


  onNoClick() {
    this.prerequisitesAdded.emit(false);
    this.activeModal.dismiss('No prerequisites were added to the job card.');
  }

  onYesClick() {
    if (this.jobCardCreation) {
      this.toasterMessage(true);
      this.jobCardDetailService.prerequisites$.pipe(takeUntil(this.destroy), filter(prerequisites => prerequisites && prerequisites.jobId === this.jobCardId), distinct()).subscribe(
        () => {
          // Add new prerequisites once prerequisites page is loaded
          this.activeModal.close(true);
          this.jobCardDetailService.selectPrerequisites(this.prerequisites);
          this.jobCardDetailService.changeViewMode('edit');
        }
      );
      this.prerequisitesAdded.emit(true);
    } else {
      const itemAdded = this.jobCardDetailService.selectPrerequisites(this.prerequisites);
      this.toasterMessage(itemAdded);
      this.activeModal.close(itemAdded);
      this.prerequisitesAdded.emit(itemAdded);
    }
  }

  private toasterMessage(itemAdded: boolean) {
    if (itemAdded) {
      this.toastr.info(this.translateService.instant('generalDetails.workOrderPrerequisitesPopup.prerequisiteAddedMessage'), this.translateService.instant('generalDetails.workOrderPrerequisitesPopup.prerequisiteAddedTitle'));
    } else {
      this.toastr.info(this.translateService.instant('generalDetails.workOrderPrerequisitesPopup.prerequisiteNotAddedMessage'), this.translateService.instant('generalDetails.workOrderPrerequisitesPopup.prerequisiteNotAddedTitle'));
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }


}
