import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { JobidCardService } from "@shared/services/jobid-card/jobid-card.service";
import { GeneralStatus, JobGateReadiness } from "@shared/models/jobid-card/jobid-card.model";
import { ProfileService } from "@shared/services";
import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GridReadyEvent,
  RowClickedEvent,
  RowModelType,
  SelectionChangedEvent,
  SideBarDef,
  SortChangedEvent,
} from "@ag-grid-community/core";
import { ApplyColumnStateParams, IServerSideDatasource } from "ag-grid-enterprise";
import { filter, map, takeUntil } from "rxjs/operators";
import { JobCardMasterService } from "@shared/services/job-card-master/job-card-master.service";
import { environment } from "@env/environment";
import { SelectLineRendererComponent } from "@shared/components/cell-renderer/select-line-renderer.component";
import { JobidCardDetailService, ViewMode } from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import { ExportExcelService } from "@shared/services/export-excel/export-excel.service";
import { TranslateService } from "@ngx-translate/core";
import { AddNewJobCardModalComponent } from "@shared/components/modal/add-new-job-card-modal/add-new-job-card-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddWorkOrderPrerequisitesComponent } from "@shared/components/modal/add-work-order-prerequisites/add-work-order-prerequisites.component";
import { WorkOrderDetails } from "@shared/models/work-orders/workorder-details.model";
import { ReadinessBadgeComponent } from "../readiness-badge/readiness-badge.component";
import {DaysCockpitRenderer} from "@shared/components/cell-renderer/daysCockpitRenderer";

@Component({
  selector: "jbid-card-list",
  templateUrl: "./jobid-card-list.component.html",
  styleUrls: ["./jobid-card-list.component.scss"],
})
export class JobidCardListComponent implements OnDestroy, OnInit {
  @ViewChild("agGrid") agGrid!: AgGridAngular;
  protected readonly environment = environment;

  /** Unsubscribe from observable streams when the component is destroyed or when the infinite scroll datasource is destoyed. */
  private destroy = new Subject<void>();

  private dataSource!: IServerSideDatasource;
  userHasAffiliates = false;
  columnDefs;
  defaultColDef: ColDef;
  rowModelType: RowModelType;
  paginationPageSize;
  cacheBlockSize;
  rowData: [] = [];
  sideBar: SideBarDef;
  selectedLines = 0;
  queryParamsFilters!: any;
  frameworkComponents = {
    selectLineRendererComponent: SelectLineRendererComponent,
  };
  _linkModeActive = false;
  private readonly view: ViewMode = "view";
  private affiliate: string | undefined;
  private tableColumns: string[] = [];
  readonly JOB_CARDS_SHEET_NAME = "Job cards";
  readonly JOB_CARDS_EXPORT_FILE_NAME = "Job cards list.xlsx";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobidCardService: JobidCardService,
    public jobCardMasterService: JobCardMasterService,
    private profileService: ProfileService,
    private elementRef: ElementRef,
    public jobidCardDetailService: JobidCardDetailService,
    public exportExcelService: ExportExcelService,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {
    this.tableColumns = this.profileService.getColumnConfiguration();
    this.columnDefs = this.getColumnDef();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    };
    this.rowModelType = "serverSide";
    this.paginationPageSize = this.jobidCardService.defaultSizePerPage;
    this.cacheBlockSize = this.jobidCardService.defaultSizePerPage;
    this.sideBar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressPivotMode: true,
            suppressValues: true,
            suppressRowGroups: true,
          },
        },
      ],
      position: "right",
    };
  }

  @HostListener("unloaded")
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  openJobCardModal(): void {
    const modalResult = this.modalService.open(AddNewJobCardModalComponent, { centered: true });
    modalResult.result
      .then((result: { jobCardId: string; workOrderNumber: string | undefined }) => {
        if (result.workOrderNumber) {
          this.suggestWorkOrderPrerequisites(result.jobCardId, result.workOrderNumber);
        } else {
          // No work order number was provided, we navigate to the general tab
          this.redirectToGeneralDetails(result.jobCardId);
        }
      })
      .catch(() => {
        console.warn("Creation cancelled");
      });
  }

  openLinkToJobCardMasterModal() {
    this.jobCardMasterService.createLinkJobCardToJobCardMasterModal();
  }

  public isRowSelectable = (rowNode: any) => {
    return !!rowNode.data && rowNode.data.jobCardMasterId == null;
  };
  getRowNodeId(node: any) {
    return node.id;
  }

  ngOnInit() {
    this.profileService.noAffiliates$.pipe(takeUntil(this.destroy)).subscribe((noAffiliates) => {
      this.userHasAffiliates = !noAffiliates;
    });
    this.translate.onLangChange.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.columnDefs = this.getColumnDef();
    });

    this.profileService.affiliate$.pipe(filter(Boolean), takeUntil(this.destroy)).subscribe((affiliate) => {
      this.affiliate = affiliate.value;
      this.dataSource = this.jobidCardService.getDatasource();
      if (this.agGrid) {
        this.agGrid.api.setServerSideDatasource(this.dataSource);
      }
    });
    this.jobCardMasterService.linkModeActive$.pipe(takeUntil(this.destroy)).subscribe((active) => {
      this._linkModeActive = active;
      this.updateColunDefs();
      if (this.agGrid?.api) {
        this.agGrid.api.deselectAll();
        this.agGrid.api.refreshCells();
      }
    });
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      this.queryParamsFilters = params;
    });
    this.refreshDataAfterMassiveCreation();
    this.jobidCardDetailService.changeViewMode(this.view);
  }

  private refreshDataAfterMassiveCreation() {
    let previousLoading = false;
    this.jobidCardDetailService.loadingMassiveCreation$.subscribe((loading) => {
      if (loading) {
        previousLoading = true;
      } else {
        if (previousLoading) {
          const x: ApplyColumnStateParams = {
            state: [
              {
                colId: "creationDate",
                sort: "desc",
              },
            ],
          };
          this.agGrid.columnApi.applyColumnState(x);
        }
        previousLoading = loading;
      }
    });
  }

  private updateColunDefs() {
    this.columnDefs = this.getColumnDef();
    if (this.agGrid?.api) {
      this.agGrid.api.setColumnDefs(this.columnDefs);
    }
  }

  onGridReady(params: GridReadyEvent) {
    params.api.setServerSideDatasource(this.dataSource);

    params.columnApi.applyColumnState({ state: this.jobidCardService.columnState });
    params.columnApi.autoSizeAllColumns();

    let filterModel = this.jobidCardService.filterDataModel ?? {};
    if (this.queryParamsFilters) {
      Object.keys(this.queryParamsFilters)
        .filter((column) => this.getColumnDef().find((c) => c.field === column))
        .forEach((key) => {
          filterModel = {
            ...filterModel,
            [key]: { filterType: "text", type: "equals", filter: this.queryParamsFilters[key] },
          };
        });
      params.api.setFilterModel(filterModel);
    }
  }

  onModelUpdated() {
    if (this.agGrid.api && this.agGrid.api.getDisplayedRowCount() <= 0) {
      this.agGrid.api.showNoRowsOverlay();
    } else {
      this.agGrid.api.hideOverlay();
    }
  }

  onRowClicked($event: RowClickedEvent) {
    if (!this._linkModeActive) {
      const rowData: any = $event.node.data;
      this.router.navigate([`../detail/${this.affiliate}/${rowData.id}/general`], {
        relativeTo: this.route,
      });
      this.elementRef.nativeElement.remove();
    }
  }

  getColumnDef() {
    return [
      {
        filter: false,
        sortable: false,
        hide: !this._linkModeActive,
        cellRenderer: "selectLineRendererComponent",
        minWidth: 50,
      },
      {
        headerName: this.translate.instant("jobCardList.id"),
        field: "id",
        filter: "agNumberColumnFilter",
        sortable: true,
        minWidth: 100,
      },
      {
        headerName: this.translate.instant("jobCardList.jobTitle"),
        field: "jobTitle",
        filter: "agTextColumnFilter",
        minWidth: 300,
        hide: !this.tableColumns.includes("jobTitle"),
      },
      {
        headerName: this.translate.instant("jobCardList.jobCardMasterTitle"),
        field: "jobCardMasterTitle",
        filter: "agTextColumnFilter",
        minWidth: 300,
        hide: !this.tableColumns.includes("jobCardMasterTitle"),
      },
      {
        headerName: this.translate.instant("jobCardList.dependency"),
        field: "isLeader",
        filter: "agTextColumnFilter",
        valueGetter: (params: { data: { isLeader: boolean; parentId: boolean } }) => {
          return this.dependency(params.data.isLeader, params.data.parentId);
        },
        hide: !this.tableColumns.includes("isLeader"),
      },
      {
        headerName: this.translate.instant("jobCardList.workOrderNumber"),
        field: "workOrderNumber",
        filter: "agTextColumnFilter",
        valueGetter: (params: { data: { fromSap: boolean; workOrderNumber: any; generalStatus: string } }) => {
          if (params.data?.fromSap && params.data?.generalStatus !== GeneralStatus.CANCELLED) {
            return params.data?.workOrderNumber;
          }
          return "";
        },
        hide: !this.tableColumns.includes("workOrderNumber"),
      },
      {
        headerName: this.translate.instant("jobCardList.priority"),
        field: "priority",
        filter: "agTextColumnFilter",
        hide: !this.tableColumns.includes("priority"),
      },
      {
        headerName: this.translate.instant("jobCardList.sitePlatform"),
        field: "sitePlatform",
        filter: "agTextColumnFilter",
        minWidth: 200,
        hide: !this.tableColumns.includes("sitePlatform"),
      },
      {
        headerName: this.translate.instant("jobCardList.generalStatus"),
        field: "generalStatus",
        filter: "agSetColumnFilter",
        filterParams: {
          values: Object.values(GeneralStatus),
        },
        hide: !this.tableColumns.includes("generalStatus"),
      },
      {
        headerName: this.translate.instant("jobCardList.actualStatus"),
        field: "actualStatus",
        filter: "agSetColumnFilter",
        filterParams: {
          values: Object.values(JobGateReadiness),
        },
        cellRenderer: (params: any) =>
          `<span class="badge mx-1 ${ReadinessBadgeComponent.getActualStatusColor(params.value)}">${
            params.value ?? "Closed/Cancelled"
          }</span>`,
        hide: !this.tableColumns.includes("actualStatus"),
        minWidth: 200,
      },
      {
        headerName: this.translate.instant("jobCardList.potentialReadiness"),
        field: "potentialReadiness",
        filter: "agSetColumnFilter",
        minWidth: 250,
        filterParams: {
          values: Object.values(JobGateReadiness),
        },
        hide: !this.tableColumns.includes("potentialReadiness"),
      },
      {
        headerName: this.translate.instant("jobCardList.jobLeader"),
        field: "jobLeader",
        filter: "agTextColumnFilter",
        minWidth: 200,
        hide: !this.tableColumns.includes("jobLeader"),
      },
      {
        headerName: this.translate.instant("jobCardList.maximumPob"),
        field: "maximumPob",
        filter: "agNumberColumnFilter",
        hide: !this.tableColumns.includes("maximumPob"),
      },
      {
        headerName: this.translate.instant('jobCardList.days'),
        field: "days",
        filter: false,
        sortable: true,
        minWidth: 100,
        cellRenderer: DaysCockpitRenderer
      },
      {
        headerName: this.translate.instant('jobCardList.startDate'),
        field: "startDate",
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          filterOptions: ["inRange"],
        },
        hide: !this.tableColumns.includes("startDate"),
      },
      {
        headerName: this.translate.instant("jobCardList.endDate"),
        field: "endDate",
        filter: "agDateColumnFilter",
        filterParams: {
          inRangeInclusive: true,
          filterOptions: ["inRange"],
        },
        hide: !this.tableColumns.includes("endDate"),
      },
      {
        headerName: this.translate.instant("jobCardList.owner"),
        field: "owner",
        filter: "agTextColumnFilter",
        hide: !this.tableColumns.includes("owner"),
      },
      {
        headerName: this.translate.instant("jobCardList.discipline"),
        field: "discipline",
        filter: "agTextColumnFilter",
        hide: !this.tableColumns.includes("discipline"),
      },
      {
        field: "revisionCode",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant("jobCardList.revisionCode"),
        minWidth: 200,
        hide: !this.tableColumns.includes("revisionCode"),
      },
      {
        field: "masterNotification",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant("jobCardList.masterNotification"),
        minWidth: 200,
        hide: !this.tableColumns.includes("masterNotification"),
      },
      {
        headerName: this.translate.instant("jobCardList.workOrderSystemStatus"),
        field: "workOrderSystemStatus",
        filter: "agTextColumnFilter",
        minWidth: 210,
        hide: !this.tableColumns.includes("workOrderSystemStatus"),
      },
      {
        headerName: this.translate.instant("jobCardList.workOrderUserStatus"),
        field: "workOrderUserStatus",
        filter: "agTextColumnFilter",
        minWidth: 250,
        hide: !this.tableColumns.includes("workOrderUserStatus"),
      },
      {
        field: "functionalLocationDescription",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant("jobCardList.functionalLocation"),
        hide: !this.tableColumns.includes("functionalLocationDescription"),
      },
      {
        field: "attributes",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant("jobCardList.attributes"),
        minWidth: 250,
        hide: !this.tableColumns.includes("attributes"),
      },
      {
        field: "workOrderType",
        filter: "agTextColumnFilter",
        headerName: this.translate.instant("jobCardList.workOrderType"),
        minWidth: 200,
        hide: !this.tableColumns.includes("workOrderType"),
      },
      {
        headerName: this.translate.instant("jobCardList.creationDate"),
        field: "creationDate",
        filter: "agDateColumnFilter",
        minWidth: 200,
        filterParams: {
          inRangeInclusive: true,
          filterOptions: ["inRange"],
        },
        hide: !this.tableColumns.includes("creationDate"),
      },
    ];
  }

  onTabChange(tabName: string) {
    if (tabName === "Job-Card-Master") {
      this.jobCardMasterService.activeLinkMode(false);
      this.router.navigate([`../../job-card-master`], {
        relativeTo: this.route,
      });
    }
  }

  onSelectionChanged($event: SelectionChangedEvent) {
    this.jobCardMasterService.updateJobCardListToLink($event.api.getSelectedRows().map((row) => row.id));
    this.selectedLines = $event.api.getSelectedRows().length;
  }

  onFilterChanged($event: FilterChangedEvent) {
    this.jobidCardService.filterDataModel = $event.api.getFilterModel();
  }

  onSortChanged($event: SortChangedEvent) {
    this.jobidCardService.columnState = $event.columnApi.getColumnState();
  }

  onColumnVisible($event: ColumnVisibleEvent) {
    if ($event.column) {
      const columnName = $event.column.getColId();
      if ($event.visible) {
        this.tableColumns.push(columnName);
      } else {
        this.tableColumns = this.tableColumns.filter((column) => column !== columnName);
      }
      this.profileService.saveColumnConfiguration(this.tableColumns);
    }
  }

  exportToExcel() {
    // Get total rows count
    const totalRows = this.jobidCardService.totalItems;
    this.jobidCardService.getDatasource(totalRows).getRows({
      request: {
        startRow: 0,
        endRow: totalRows,
        filterModel: {},
        sortModel: [],
      },
      success: ({ rowData }: { rowData: any }) => {
        const formattedDate = this.prepareData(rowData);
        this.exportExcelService.exportToExcel(
          formattedDate,
          this.JOB_CARDS_SHEET_NAME,
          this.JOB_CARDS_EXPORT_FILE_NAME,
          "Job cards exported successfully",
        );
      },
    });
  }

  resetFilter() {
    this.agGrid.api.setFilterModel(null);
    this.agGrid.columnApi.applyColumnState({
      state: this.agGrid.columnApi.getColumnState().map((c) => ({ ...c, sort: null })),
    });
  }

  private dependency(isLeader: boolean, parentId: boolean) {
    if (isLeader) {
      return this.translate.instant("jobCardList.leader");
    } else if (parentId !== null && parentId !== undefined) {
      return this.translate.instant("jobCardList.dependent");
    } else {
      return this.translate.instant("jobCardList.none");
    }
  }

  prepareData(rowDataList: any[]) {
    return rowDataList.map((rowData: any) => {
      return {
        id: rowData.id,
        jobTitle: rowData.jobTitle,
        jobCardMasterTitle: rowData.jobCardMasterTitle,
        dependency: this.dependency(rowData.isLeader, rowData.parentId),
        jobCardMasterId: rowData.jobCardMasterId,
        masterClassification: rowData.masterClassification,
        workOrderNumber: rowData.workOrderNumber,
        priority: rowData.priority,
        sitePlatform: rowData.sitePlatform,
        generalStatus: rowData.generalStatus,
        actualStatus: rowData.actualStatus,
        potentialReadiness: rowData.potentialReadiness,
        startDate: rowData.startDate,
        endDate: rowData.endDate,
        owner: rowData.owner,
        discipline: rowData.discipline,
        revisionCode: rowData.revisionCode,
        masterNotification: rowData.masterNotification,
        workOrderUserStatus: rowData.workOrderUserStatus,
        workOrderSystemStatus: rowData.workOrderSystemStatus,
        functionalLocation: rowData.functionalLocation,
        attributes: rowData.attributes,
        dgsValue: rowData.dgsValue,
        lotiValue: rowData.lotiValue,
        campaignOption: rowData.campaignOption,
        workOrderType: rowData.workOrderType,
        maximumPob: rowData.maximumPob,
        fromSap: rowData.fromSap,
        jobLeader: rowData.jobLeader,
        creationDate: rowData.creationDate,
      };
    });
  }

  private suggestWorkOrderPrerequisites(jobCardId: string, workOrderNumber: string): void {
    this.jobidCardDetailService
      .getWorkOrderDetailsSilently(workOrderNumber)
      .pipe(
        map((result) => result.data.getWorkOrderDetailsAggregate),
        filter(Boolean),
      )
      .subscribe((workOrderDetails: WorkOrderDetails) => {
        if (workOrderDetails.prerequisites.length > 0) {
          const modalRef = this.modalService.open(AddWorkOrderPrerequisitesComponent, {
            centered: true,
          });
          modalRef.componentInstance.prerequisites = workOrderDetails.prerequisites;
          modalRef.componentInstance.jobCardId = jobCardId;
          modalRef.componentInstance.jobCardCreation = true;
          modalRef.componentInstance.prerequisitesAdded
            .pipe(takeUntil(this.destroy))
            .subscribe((itemAdded: boolean) => {
              if (itemAdded) {
                this.redirectToResources(jobCardId);
              } else {
                this.redirectToGeneralDetails(jobCardId);
              }
            });
        } else {
          this.redirectToGeneralDetails(jobCardId);
        }
      });
  }

  private redirectToGeneralDetails(jobCardId: string): void {
    this.router
      .navigateByUrl(`/jobid-card/detail/${this.affiliate}/${jobCardId}/general`)
      .catch((error) => console.error(error));
  }

  private redirectToResources(jobCardId: string): void {
    this.router
      .navigateByUrl(`/jobid-card/detail/${this.affiliate}/${jobCardId}/prerequisites-documents/resources`)
      .catch((error) => console.error(error));
  }
}
