<form *ngIf="formGroup" [formGroup]="formGroup" #formRef="ngForm"
      [ngClass]="{ 'needs-validation': formRef.submitted }" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <div class="d-flex flex-row modal-title">
            <span class="material-icons-outlined text-primary" aria-hidden="true">create</span>
            <h5 class="modal-title">Create a new Job card Master</h5>
        </div>
        <button type="button" class="btn btn-close" aria-label="Close"
                (click)="activeModal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <div class="container">
            <div class="row d-flex m-lg-1">
                <div class="col-9">
                    <div class="form-group">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox"
                                   formControlName="isCampaign" id="isCampaign"/>
                            <label style="font-size: 1rem" class="form-check-label fw-bold align-bottom"
                                   for="isCampaign">
                                Job Card Master Campaign
                            </label>
                            <mat-icon
                                    [inline]="true"
                                    matTooltip="Check this box if your job card master is a campaign."
                            > info_outline </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                    <div class="form-group">
                        <label class="form-label"> Title </label>
                        <input id="title" type="text" formControlName="title" class="form-control"/>
                    </div>
                </div>
            </div>
            <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                    <div class="form-group">
                        <label class="form-label"><span class="form-mandatory">*</span> Job Card Master Owner
                        </label>
                        <input
                                id="owner"
                                type="text"
                                formControlName="owner"
                                class="form-control"
                                [ngbTypeahead]="searchJobLeader"/>
                    </div>
                </div>
            </div>
            <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                    <div class="form-group">
                        <label class="form-label">
                            <span class="form-mandatory">*</span>
                            Main Site
                        </label>
                        <input id="mainSite" type="text" class="form-control" formControlName="mainSite"
                               [ngbTypeahead]="search"
                        />
                    </div>
                </div>
            </div>

            <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                    <div class="form-group">
                        <label class="form-label">Start Date</label>
                        <div class="jbid-datepicker">
                            <jbid-date-picker internalInputId="new-jcm-start-date" formControlName="startDate"></jbid-date-picker>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex py-2 m-lg-2">
                <div class="col-9">
                    <div class="form-group">
                        <label class="form-label">End Date</label>
                        <div class="jbid-datepicker">
                            <jbid-date-picker internalInputId="new-jcm-end-date" formControlName="endDate"></jbid-date-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="gap: 0">
        <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">
            <span class="material-icons" aria-hidden="true">done</span>
            Validate
        </button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">
            <span class="material-icons" aria-hidden="true">close</span>
            Cancel
        </button>
    </div>
</form>
