import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus,} from "@azure/msal-browser";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {BehaviorSubject, Observable} from "rxjs";
import {filter, map, startWith} from "rxjs/operators";
import {environment} from "@env/environment";
import {JOB_ID_CARD_ROUTES_NAMES} from "@routes/jobid-card/jobid-card.routes";
import {APP_ROUTES_NAMES} from "@routes/routes";
import {InsightsService} from "@shared/services";
import {Responsability, Role} from "@shared/services/auth/auth.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly USER_AFFILIATE_SUFFIX = "-Users";
  private readonly _currentUser$ = new BehaviorSubject<AccountInfo | null>(null);

  private readonly _admin$ = new BehaviorSubject<boolean>(false);
  admin$ = this._admin$.asObservable();

  private readonly _jobLeader$ = new BehaviorSubject<boolean>(false);
  jobLeader$ = this._jobLeader$.asObservable();

  private readonly _jobPlanner$ = new BehaviorSubject<boolean>(false);
  jobPlanner$ = this._jobPlanner$.asObservable();

  private readonly _sapAuthorized$ = new BehaviorSubject<boolean>(false);
  sapAuthorized$ = this._sapAuthorized$.asObservable();
  authenticationPending = new BehaviorSubject(true);

  userName$: Observable<string> = this._currentUser$.asObservable().pipe(
    map((userInfo: AccountInfo | null) => userInfo?.name ?? ""),
    startWith(""),
  );

  private readonly _userRoles$ = new BehaviorSubject<string[] | null>(null);
  userRoles$: Observable<string[] | null> = this._userRoles$.asObservable();

  constructor(
    private readonly router: Router,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly msalService: MsalService,
    private readonly insightsService: InsightsService,
  ) {
    // Init Service
    this._activeFirstAccountFromSessionStorage();
    this._loadActiveAccount();

    this.msalBroadcastService.inProgress$.subscribe({
      next: (status: InteractionStatus) => {
        if (status === InteractionStatus.None) {
          this.authenticationPending.next(false);
        } else {
          this.authenticationPending.next(true);
        }
      },
    });

    // Launch action on event from MSAL service
    this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
      switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          const payload = event.payload as AuthenticationResult;
          this._onLogin(payload.account);
          break;
        }
        case EventType.LOGOUT_SUCCESS: {
          this._onLogout();
          break;
        }
        case EventType.LOGIN_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE: {
          this._onLoginFailure(event.error);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  private _activeFirstAccountFromSessionStorage(): void {
    const allAccounts = this.msalService.instance.getAllAccounts();
    if (!this.msalService.instance.getActiveAccount() || (allAccounts && allAccounts.length > 0 && allAccounts[0])) {
      // Enable the first existing account
      const account = allAccounts[0];
      // Active user account
      this.msalService.instance.setActiveAccount(account);
    }
  }

  private _loadActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      this.insightsService.setUserId(activeAccount.username, activeAccount.localAccountId);
      if (activeAccount.idTokenClaims) {
        const claims = activeAccount.idTokenClaims;
        if (claims["roles"]) {
          const userRoles: string[] = JSON.parse(JSON.stringify(claims["roles"]));
          const isSapAuthorized = this.extractRole(userRoles, Responsability.SAP_AUTHORIZED_ROLE);
          this._sapAuthorized$.next(isSapAuthorized);
          const isAdmin = this.extractRole(userRoles, Responsability.LOCAL_ADMIN);
          this._admin$.next(isAdmin);
          const isJobLeader = userRoles.includes(Role.JOB_LEADER);
          this._jobLeader$.next(isJobLeader);
          const isJobPlanner = userRoles.includes(Role.JOB_PLANNER);
          this._jobPlanner$.next(isJobPlanner);
          const affiliates = userRoles
            .filter((role) => role.endsWith(this.USER_AFFILIATE_SUFFIX))
            .map((role) => role.slice(0, role.indexOf(this.USER_AFFILIATE_SUFFIX)).toUpperCase());
          const roles = userRoles.filter((role) => !role.endsWith(this.USER_AFFILIATE_SUFFIX));
          this.insightsService.setUserRoles(roles);
          this.insightsService.setAffiliates(affiliates);
          this._userRoles$.next(userRoles);
        }
        if (claims.department) {
          const department: string = JSON.parse(JSON.stringify(claims.department));
          this.insightsService.setDepartment(department);
        }
      }
      this._currentUser$.next(activeAccount);
    }
  }

  private extractRole(roles: string[], role: string): boolean {
    const index = roles.indexOf(role);
    if (index > -1) {
      roles.splice(index, 1);
      return true;
    } else {
      return false;
    }
  }

  private _onLogin(account: AccountInfo | null): void {
    if (account) {
      // Active user account
      this.msalService.instance.setActiveAccount(account);

      // Load account
      this._loadActiveAccount();
    }
  }

  private _onLogout(): void {
    this._currentUser$.next(null);
    this.router.navigate(["/login"]);
  }

  private async _onLoginFailure(_error: any): Promise<void> {
    this._currentUser$.next(null);
    this.router.navigate(["/unauthorized"]);
  }

  logout(): void {
    // logout without SLO
    this.msalService.logoutRedirect({
      // Logout from the application must log out user only from the application
      // And not from other tools where he/she is authenticated with Azure
      onRedirectNavigate: (_) => false,
    });
  }

  login(): void {
    const scopes: string[] = [];
    scopes.push(environment.aadBackendScope);

    this.msalService.loginRedirect({
      redirectUri: environment.redirectUrl,
      scopes,
    });
  }

  redirectAuthenticatedUserToHome() {
    // Redirect user if is already authenticated
    this.authenticationPending.pipe(filter((state: boolean) => state === false)).subscribe({
      next: () => {
        const currentUser = this._currentUser$.getValue();
        if (currentUser) {
          const userRoles = this._userRoles$.getValue();
          const includesJobPlanners = userRoles?.includes(Role.JOB_PLANNER);
          const includesJobLeaders = userRoles?.includes(Role.JOB_LEADER);

          let route: string;
          if (includesJobPlanners && !includesJobLeaders) {
            route = APP_ROUTES_NAMES.READINESS_DASHBOARD;
          } else if (!includesJobPlanners && includesJobLeaders) {
            route = APP_ROUTES_NAMES.COCKPIT;
          } else {
            route = APP_ROUTES_NAMES.JOB_ID_CARD + "/" + JOB_ID_CARD_ROUTES_NAMES.LIST;
          }

          this.router.navigate(["/" + route]);
        }
      },
    });
  }

  hasRole(role: Role): boolean {
    const claims = this._currentUser$.getValue()?.idTokenClaims;
    if (claims && claims["roles"]) {
      const userRoles: string[] = JSON.parse(JSON.stringify(claims["roles"]));
      return userRoles.includes(role);
    }
    return false;
  }

}
