import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { JobidCardDetailService } from "@shared/services/jobid-card-detail/jobid-card-detail.service";
import { AgGridAngular } from "@ag-grid-community/angular";
import { ColDef, GridReadyEvent, RowModelType } from "@ag-grid-community/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { IServerSideDatasource } from "ag-grid-enterprise";
import { ProfileService } from "@shared/services";
import { filter, map, takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { Prerequisites } from "@shared/models/prerequisites/prerequisites.model";
import { MobilizationFormService } from "./mobilization-form.service";
import { UntypedFormGroup } from "@angular/forms";
import { MobilizationPrerequisites } from "@shared/models/prerequisites/mobilization-prerequisites.model";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "jbid-mobilization",
  templateUrl: "./mobilization.component.html",
  styleUrls: ["./mobilization.component.css"],
})
export class MobilizationComponent implements OnInit, OnDestroy {
  static readonly NOT_STARTED = "NOT_STARTED";
  static readonly ON_GOING = "ON_GOING";
  static readonly VALIDATED = "VALIDATED";
  static readonly NOT_SET = "NOT_SET";

  descriptionInformation =
    "Please take a look at all the movements related to this job card that come from DaWinci and define the booking global status.";
  private DATE_FORMAT = "dd/MM/yyyy 'at' hh:mm";

  @ViewChild("agGrid") agGrid!: AgGridAngular;

  /** Unsubscribe from observable streams when the component is destroyed or when the infinite scroll datasource is destoyed. */
  private destroy = new Subject<void>();
  certificationsButtonExpanded = false;
  mobilizationsPrerequisites!: MobilizationPrerequisites;
  dataSource!: IServerSideDatasource;
  columnDefs;
  defaultColDef: ColDef;
  rowModelType: RowModelType;
  paginationPageSize: number = this.jobCardDetailService.defaultSizePerPage;
  cacheBlockSize: number = this.jobCardDetailService.defaultSizePerPage;
  rowData: [] = [];
  groupForm!: UntypedFormGroup;
  readonly bookingStatusOptions: string[] = [
    MobilizationComponent.NOT_STARTED,
    MobilizationComponent.ON_GOING,
    MobilizationComponent.VALIDATED,
  ];
  lastScanInfo$!: Observable<string>;
  mobilizationEnabled = this.profileService.getAffiliateConfig().mobilizationEnabled ?? false;
  defaultReadiness!: string | undefined;
  readonly = true;

  constructor(
    private jobCardDetailService: JobidCardDetailService,
    private profileService: ProfileService,
    private datePipe: DatePipe,
    public mobilizationFormService: MobilizationFormService,
    private translate: TranslateService,
  ) {
    this.columnDefs = this.getColumnDef();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      sortable: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
      menuTabs: ["filterMenuTab"],
    };
    this.rowModelType = "serverSide";
  }

  @HostListener("unloaded")
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  ngOnInit() {
    combineLatest([
      this.jobCardDetailService.isModeReadOnly$,
      this.jobCardDetailService.prerequisites$.pipe(
        filter(Boolean),
        map((prerequisites: Prerequisites) => prerequisites.mobilizationsDTO),
      ),
    ])
      .pipe(takeUntil(this.destroy))
      .subscribe(([isModeReadOnly, mobilizationsPrerequisites]) => {
        this.readonly = isModeReadOnly;
        this.mobilizationsPrerequisites = mobilizationsPrerequisites;
        this.groupForm = this.mobilizationFormService.createForm(mobilizationsPrerequisites, isModeReadOnly);
        this.defaultReadiness = mobilizationsPrerequisites.resourcesManagementReadiness;
      });
    this.profileService.affiliate$
      .pipe(
        map((affiliate) => affiliate.value),
        filter(Boolean),
        takeUntil(this.destroy),
      )
      .subscribe((affiliate) => {
        this.dataSource = this.jobCardDetailService.getMobilizationList(
          affiliate,
          this.paginationPageSize,
          this.onItemsLoaded,
          this,
        );
        this.lastScanInfo$ = this.jobCardDetailService.getMobilizationLastScanInfo(affiliate);
      });

    this.profileService.affiliateConfig$.pipe(takeUntil(this.destroy), filter(Boolean)).subscribe((affiliateConfig) => {
      this.mobilizationEnabled = affiliateConfig.mobilizationEnabled;
    });
    this.translate.onLangChange.pipe(takeUntil(this.destroy)).subscribe((event: LangChangeEvent) => {
      this.columnDefs = this.getColumnDef();
    });
  }

  onGridReady(params: GridReadyEvent) {
    params.api.setServerSideDatasource(this.dataSource);
    params.api.sizeColumnsToFit();
  }

  getColumnDef() {
    return [
      {
        headerName: this.translate.instant("mobilization.status"),
        field: "reservationStatus",
      },
      {
        headerName: this.translate.instant("mobilization.from"),
        field: "startLocation",
      },
      {
        headerName: this.translate.instant("mobilization.departure"),
        field: "transportationDate",
        minWidth: 170,
        valueFormatter: (params: { value: any }) => this.dateFormatter(params?.value),
      },
      {
        headerName: this.translate.instant("mobilization.to"),
        field: "endLocation",
      },
      {
        headerName: this.translate.instant("mobilization.transportType"),
        field: "transportationMode",
      },
      {
        headerName: this.translate.instant("mobilization.resAccoLocation"),
        field: "accommodationLocation",
      },
      {
        headerName: this.translate.instant("mobilization.companyName"),
        field: "companyName",
      },
      {
        headerName: this.translate.instant("mobilization.firstName"),
        field: "collaboratorFirstName",
      },
      {
        headerName: this.translate.instant("mobilization.lastName"),
        field: "collaboratorLastName",
      },
      {
        headerName: this.translate.instant("mobilization.nationality"),
        field: "collaboratorNationality",
      },
    ];
  }

  dateFormatter(params: any): string {
    return this.datePipe.transform(params, this.DATE_FORMAT) ?? "";
  }

  onPageSizeChanged() {
    this.agGrid.api.setServerSideDatasource(this.dataSource);
  }

  onItemsLoaded(totalItems: number): void {
    if (this.mobilizationsPrerequisites.bookingGlobalStatus === MobilizationComponent.NOT_SET) {
      if (totalItems > 0) {
        this.mobilizationFormService.setDefaultBookingStatusValue(MobilizationComponent.ON_GOING);
      } else {
        this.mobilizationFormService.setDefaultBookingStatusValue(MobilizationComponent.NOT_STARTED);
      }
    }
  }

  toggleCertificationsButton(): void {
    this.certificationsButtonExpanded = !this.certificationsButtonExpanded;
  }
}
