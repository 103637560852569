import gql from "graphql-tag";

const UPDATE_DISCUSSION_CONTENT_MUTATION = gql`
    mutation updatedDiscussionContent($id: Int, $affiliate: String, $input: DiscussionInput!) {
        updatedDiscussionContent(id: $id, affiliate: $affiliate, input: $input) {
            id
            jobCardId
            senderName
            messageObject
            dateCreated
            lastModified
            prerequisiteTags
            messageContent
        }
    }
`;

export {UPDATE_DISCUSSION_CONTENT_MUTATION};
