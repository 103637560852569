import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: "jbid-checkbox-renderer",
  template: `
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="selected"
        (change)="onCheckBoxChange($event)"
        [disabled]="!params.node.selectable"
        [checked]="params.node.isSelected()"
      />
    </div>
  `,
  styles: [
    `
      .form-check {
        padding-top: 10px;
      }
    `,
  ],
})
export class SelectLineRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  selected = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!params.node.selectable || params.node.isSelected()) {
      this.selected = true;
    }
  }

  refresh(_params: ICellRendererParams): boolean {
    return false;
  }

  onCheckBoxChange(_$event: any) {
    this.params.node.setSelected(_$event.target["checked"]);
    _$event.nativeElement.preventDefault();
  }
}
