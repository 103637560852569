import {JobidCardListComponent} from "./jobid-card-list/jobid-card-list.component";
import {JobidCardDetailComponent} from "./jobid-card-detail/jobid-card-detail.component";
import {Routes} from "@angular/router";

export const JOB_ID_CARD_ROUTES_NAMES = {
    LIST: "list",
    DETAIL: "detail/:affiliate/:id",
};

export const JOB_ID_CARD_DETAIL_ROUTES_NAMES = {
    GENERAL: "general",
    PREREQUISITES_DOCUMENTS: "prerequisites-documents",
    DISCUSSIONS: "discussion",
  HISTORY: "history",
};

export const JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES = {
    JOB_SCOPE: "job-scope",
    IMPACT: "impact",
    MATERIAL: "material",
    RESOURCES: "resources",
    MOBILIZATION: "mobilization",
};

export enum JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES {
    GENERAL = "general",
    JOB_SCOPE = "job-scope",
    IMPACT = "impact",
    MATERIAL = "material",
    RESOURCES = "resources",
    DISCUSSIONS = "discussion",
    HISTORY = "history",
    MOBILIZATIONS = "mobilization",
}

export const JOB_ID_CARD_ROUTES: Routes = [
    {path: "", redirectTo: JOB_ID_CARD_ROUTES_NAMES.LIST, pathMatch: "full"},
    {path: JOB_ID_CARD_ROUTES_NAMES.LIST, component: JobidCardListComponent},
    {
        path: JOB_ID_CARD_ROUTES_NAMES.DETAIL,
        component: JobidCardDetailComponent,
        children: [
            {
                path: JOB_ID_CARD_DETAIL_ROUTES_NAMES.GENERAL,
                loadChildren: () =>
                    import("./detail/general-details/general-details.module").then((m) => m.GeneralDetailsModule),
            },
            {
                path: JOB_ID_CARD_DETAIL_ROUTES_NAMES.PREREQUISITES_DOCUMENTS,
                loadChildren: () =>
                    import("./detail/prerequisites/prerequisites.module").then((m) => m.PrerequisitesDocumentsModule),
            },
            {
                path: JOB_ID_CARD_DETAIL_ROUTES_NAMES.DISCUSSIONS,
                loadChildren: () => import("./detail/discussions/discussions.module").then((m) => m.DiscussionsModule),
            },
          {
            path: JOB_ID_CARD_DETAIL_ROUTES_NAMES.HISTORY,
            loadChildren: () => import("./detail/details-history/details-history.module").then((m) => m.DetailsHistoryModule),
          }
        ],
    },
];
