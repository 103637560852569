import gql from "graphql-tag";

const JOB_CARD_DEPENDENCIES_MUTATION = gql`
  mutation updateJobCardDependencies($affiliate: String, $input: JobCardsDependenciesInput) {
    updateJobCardDependencies(affiliate: $affiliate, input: $input) {
      id
      dependencies {
        id
      }
    }
  }
`;

export { JOB_CARD_DEPENDENCIES_MUTATION };
