<div class="card me-2" *ngIf="jobCardDetail">
  <div class="card-body">
    <div class="d-flex align-items-center p-2">
      <span class="timeline-header">{{'generalDetails.jobCardstatus' | translate }}</span>
      <mat-icon
        [inline]="true"
        [class]="['px-1', 'text-danger']"
        cdkOverlayOrigin
        #triggerInfos="cdkOverlayOrigin"
        (focus)="isInfoOverlayOpen = true"
        (mouseover)="isInfoOverlayOpen = true"
        (blur)="isInfoOverlayOpen = false"
        (mouseout)="isInfoOverlayOpen = false"
      >
        info_outline
      </mat-icon>
      <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="triggerInfos"
      [cdkConnectedOverlayPositions]="titleOverlayPositionPairs"
      [cdkConnectedOverlayOpen]="isInfoOverlayOpen"
    >
      <div class="info-overlay">
        <h5>{{'generalDetails.howItWorks' | translate }}</h5>
        <p>
          {{'generalDetails.timeline' | translate }}
        </p>
        <mat-divider></mat-divider>
        <div class="flexBox">
          <mat-icon [ngClass]="'light-gray'">stop</mat-icon>
          <span class="cursorsText">{{'generalDetails.draftP6' | translate }}</span>
          <mat-divider [vertical]="true"></mat-divider>
          <mat-icon [ngClass]="'gray'">stop</mat-icon>
          <span class="cursorsText">{{'generalDetails.readyP6' | translate }}</span>
          <mat-divider [vertical]="true"></mat-divider>
          <mat-icon [ngClass]="'light-orange'">stop</mat-icon>
          <span class="cursorsText">{{'generalDetails.4MGateP6' | translate }}</span>
          <mat-divider [vertical]="true"></mat-divider>
          <mat-icon [ngClass]="'light-blue'">stop</mat-icon>
          <span class="cursorsText">{{'generalDetails.5WGateP6' | translate }}</span>
          <mat-divider [vertical]="true"></mat-divider>
          <mat-icon [ngClass]="'light-violet'">stop</mat-icon>
          <span class="cursorsText">{{'generalDetails.2WFGateP6' | translate }}</span>
        </div>
        <div class="flexBox">
          <span class="cursorsText"><strong>{{'generalDetails.lightColour' | translate }}</strong> {{'generalDetails.awaitingPrep' | translate }}</span>
          <mat-divider [vertical]="true"></mat-divider>
          <span class="cursorsText"><strong>{{'generalDetails.darkColour' | translate }}</strong> {{'generalDetails.readyForMeeting' | translate }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="flexBox">
          <span class="cursorsText"
            ><strong>{{'generalDetails.activation' | translate }} </strong> {{'generalDetails.activityWithPlanner' | translate }}</span
          >
        </div>
      </div>
      </ng-template>
      <div class="d-flex ms-auto">
        <div class="form-switch-labels form-check form-switch switch-two-weeks" *ngIf="isJobLeaderOrOffshoreFocalPoint">
          <span id="ready-for-two-weeks" class="mx-1" [ngClass]="{ 'gray': disabled2WFActivation}">{{'generalDetails.readyFor2WF' | translate }}</span>
          <input type="checkbox" id="switch-two-weeks" class="form-check-input switch-two-weeks"
                  [(ngModel)]="isReadyFor2WF" [disabled]="disabled2WFActivation" (click)="validateTwoWeeksFrozenGate()" (keydown)="validateTwoWeeksFrozenGate()">
        </div>

        <button class="opacity-100 btn btn-custom-disabled rounded disabled" *ngIf="(isModeReadOnly$ | async) === true && !pendingActivation">
          {{ selectedStatus }}
        </button>

        <button class="opacity-100 btn btn-custom-disabled rounded disabled" *ngIf="pendingActivation">
          Pending activation
        </button>

        <div ngbDropdown class="ps-0 form-group d-inline-block" *ngIf="(isModeReadOnly$ | async) === false && !pendingActivation">
          <button class="btn btn-custom rounded" id="GeneralDetailsStatus" ngbDropdownToggle>
            {{ selectedStatus }}
          </button>
          <div ngbDropdownMenu aria-labelledby="GeneralDetailsStatus">
            <div class="d-flex" *ngFor="let generalStatus of mGeneralStatusEnum | keyvalue: keyDescOrder">
              <button
                cdkOverlayOrigin
                #triggerStatusDisable="cdkOverlayOrigin"
                class="dropdown-item"
                (click)="onGeneralStatusChange(generalStatus.value)"
                [ngClass]="{
                disabled: checkActivationRole(generalStatus.value) || isActivationStatus(generalStatus.value) || isLinkedAndStatusIsCancelled(generalStatus.value),
                'status-disable': checkActivationRole(generalStatus.value) || isActivationStatus(generalStatus.value) || isLinkedAndStatusIsCancelled(generalStatus.value)
              }"
              >
                {{ generalStatus.value | translate  }}
              </button>
              <mat-icon
                [inline]="true"
                class="active-status-info"
                (focus)="toggleDisableActivation(triggerStatusDisable)"
                (mouseover)="toggleDisableActivation(triggerStatusDisable)"
                (blur)="isActivationDisableOverlayOpen = false"
                (mouseout)="isActivationDisableOverlayOpen = false"
                *ngIf="checkActivationRole(generalStatus.value)"
              >
                error_outline
              </mat-icon>

              <mat-icon
                [inline]="true"
                class="draft-status-info"
                (focus)="toggleDisableDraft(triggerStatusDisable)"
                (mouseover)="toggleDisableDraft(triggerStatusDisable)"
                (blur)="isDraftDisableOverlayOpen = false"
                (mouseout)="isDraftDisableOverlayOpen = false"
                *ngIf="isActivationStatus(generalStatus.value)"
              >
                error_outline
              </mat-icon>

              <mat-icon
                [inline]="true"
                class="cancel-status-info"
                (focus)="toggleDisableCancel(triggerStatusDisable)"
                (mouseover)="toggleDisableCancel(triggerStatusDisable)"
                (blur)="isCancelDisableOverlayOpen = false"
                (mouseout)="isCancelDisableOverlayOpen = false"
                *ngIf="true"
              >
                error_outline
              </mat-icon>
            </div>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="triggerDraftDisable"
              [cdkConnectedOverlayOpen]="isDraftDisableOverlayOpen"
            >
              <div class="info-draft-disable">
                <p>This activity is already in the IOP planning.</p>
              </div>
            </ng-template>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="triggerActivationDisable"
              [cdkConnectedOverlayOpen]="isActivationDisableOverlayOpen"
            >
              <div class="info-activation-disable">
                <p>{{'generalDetails.onlyPlanners' | translate }}</p>
              </div>
            </ng-template>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="triggerCancelDisable"
              [cdkConnectedOverlayOpen]="isCancelDisableOverlayOpen"
            >
              <div class="info-draft-disable">
                <p>Job card linked to a JCM. Cannot be cancel.</p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <mat-grid-list cols="22" rowHeight="15px">
      <mat-grid-tile [colspan]="12" [rowspan]="2"></mat-grid-tile>
      <mat-grid-tile [colspan]="10" [rowspan]="2"></mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="2"></mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="1" [ngClass]="'gray'"></mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1" [ngClass]="'gray'">{{'generalDetails.activation' | translate}}</mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1" [ngClass]="'gray'">{{'generalDetails.4MGate' | translate}}</mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1" [ngClass]="'gray'">{{'generalDetails.5WGate' | translate}}</mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1" [ngClass]="'gray'">{{'generalDetails.2WFGate' | translate}}</mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="2"></mat-grid-tile>

      <mat-grid-tile [colspan]="2" [rowspan]="3">
        <div *ngIf="isStatus('Draft')" class="firstCursorText draft">
          <mat-icon [inline]="true">fiber_manual_record</mat-icon>
          <span class="cursorsText"><strong>{{'generalDetails.draft' | translate }}</strong></span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-divider [vertical]="true" class="h-100"></mat-divider>
        <div *ngIf="isStatus('Ready to activate')" class="firstCursorText draft">
          <mat-icon [inline]="true">fiber_manual_record</mat-icon>
          <span class="cursorsText"><strong>{{'generalDetails.readyToActivate' | translate }}</strong></span>
        </div>
        <div
          [hidden]="!showFirstJobCursor('Awaiting Prep', '4M')"
          id="actualStatusAwaitingPreparation4M"
          [ngClass]="['firstCursorText', 'awaitingPreparationPosition', cancelledCursorCssClass()]"
          (mouseover)="toggle(triggerAwaitingPrep4MWarning, triggerAwaitingPrep4MAlert)"
          (mouseout)="isWarningOverlayOpen = false; isAlertOverlayOpen = false"
        >
          <mat-icon *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record</mat-icon>
          <mat-icon *ngIf="isStatus('Cancelled')" [inline]="true">clear</mat-icon>
          <span class="cursorsText"><strong>Awaiting Preparation 4M</strong></span>
          <mat-icon
            [hidden]="!isActualStatusLowerThanJobReadiness()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerAwaitingPrep4MWarning="cdkOverlayOrigin"
            >report_problem
          </mat-icon>
          <mat-icon
            [hidden]="isActualStatusLowerThanJobReadiness() || !isAlertActualStatus()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerAwaitingPrep4MAlert="cdkOverlayOrigin"
            >error_outline
          </mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-divider [vertical]="true" class="h-100"></mat-divider>
        <div
          [hidden]="!showFirstJobCursor('Awaiting Prep', '5W')"
          id="actualStatusAwaitingPreparation5W"
          [ngClass]="['firstCursorText', 'awaitingPreparationPosition', cancelledCursorCssClass()]"
          (mouseover)="toggle(triggerAwaitingPrep5WWarning, triggerAwaitingPrep5WAlert)"
          (mouseout)="isWarningOverlayOpen = false; isAlertOverlayOpen = false"
        >
          <mat-icon *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record</mat-icon>
          <mat-icon *ngIf="isStatus('Cancelled')" [inline]="true">clear</mat-icon>
          <span class="cursorsText"><strong>Awaiting Preparation 5W</strong></span>
          <mat-icon
            [hidden]="!isActualStatusLowerThanJobReadiness()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerAwaitingPrep5WWarning="cdkOverlayOrigin"
            >report_problem
          </mat-icon>
          <mat-icon
            [hidden]="isActualStatusLowerThanJobReadiness() || !isAlertActualStatus()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerAwaitingPrep5WAlert="cdkOverlayOrigin"
            >error_outline
          </mat-icon>
        </div>
        <div
          [hidden]="!showFirstJobCursor('Ready', '4M')"
          id="actualStatusReady4M"
          [ngClass]="['firstCursorText', 'readyPosition', cancelledCursorCssClass()]"
          (mouseover)="toggle(triggerReady4MWarning, triggerReady4MAlert)"
          (mouseout)="isWarningOverlayOpen = false; isAlertOverlayOpen = false"
        >
          <mat-icon *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record</mat-icon>
          <mat-icon *ngIf="isStatus('Cancelled')" [inline]="true">clear</mat-icon>
          <span class="cursorsText"><strong>Ready 4M</strong></span>
          <mat-icon
            [hidden]="!isActualStatusLowerThanJobReadiness()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerReady4MWarning="cdkOverlayOrigin"
            >report_problem
          </mat-icon>
          <mat-icon
            [hidden]="isActualStatusLowerThanJobReadiness() || !isAlertActualStatus()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerReady4MAlert="cdkOverlayOrigin"
            >error_outline
          </mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-divider [vertical]="true" class="h-100"></mat-divider>
        <div
          [hidden]="!showFirstJobCursor('Ready', '5W')"
          id="actualStatusReady5W"
          [ngClass]="['firstCursorText', 'readyPosition', cancelledCursorCssClass()]"
          (mouseover)="toggle(triggerReady5WWarning, triggerReady5WAlert)"
          (mouseout)="isWarningOverlayOpen = false; isAlertOverlayOpen = false"
        >
          <mat-icon *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record</mat-icon>
          <mat-icon *ngIf="isStatus('Cancelled')" [inline]="true">clear</mat-icon>
          <span class="cursorsText"><strong>Ready 5W</strong></span>
          <mat-icon
            [hidden]="!isActualStatusLowerThanJobReadiness()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerReady5WWarning="cdkOverlayOrigin"
            >report_problem
          </mat-icon>
          <mat-icon
            [hidden]="isActualStatusLowerThanJobReadiness() || !isAlertActualStatus()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerReady5WAlert="cdkOverlayOrigin"
            >error_outline
          </mat-icon>
        </div>
        <div
          [hidden]="!showFirstJobCursor('Awaiting Prep', '2WF')"
          id="actualStatusAwaitingPreparation2WF"
          [ngClass]="['firstCursorText', 'awaitingPreparationPosition', cancelledCursorCssClass()]"
          (mouseover)="toggle(triggerAwaitingPrep2WFWarning, triggerAwaitingPrep2WFAlert)"
          (mouseout)="isWarningOverlayOpen = false; isAlertOverlayOpen = false"
        >
          <mat-icon *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record</mat-icon>
          <mat-icon *ngIf="isStatus('Cancelled')" [inline]="true">clear</mat-icon>
          <span class="cursorsText"><strong>Awaiting Preparation 2WF</strong></span>
          <mat-icon
            [hidden]="!isActualStatusLowerThanJobReadiness()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerAwaitingPrep2WFWarning="cdkOverlayOrigin"
            >report_problem
          </mat-icon>
          <mat-icon
            [hidden]="isActualStatusLowerThanJobReadiness() || !isAlertActualStatus()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerAwaitingPrep2WFAlert="cdkOverlayOrigin"
            >error_outline
          </mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-divider [vertical]="true" class="h-100"></mat-divider>
        <div *ngIf="isStatus('Closed')" class="firstCursorText text-success close">
          <mat-icon [inline]="true">fiber_manual_record</mat-icon>
          <span class="cursorsText"><strong>Close</strong></span>
        </div>
        <div
          *ngIf="showFirstJobCursor('Ready', '2WF')"
          id="actualStatusReady2WF"
          [ngClass]="['firstCursorText', 'readyPosition', cancelledCursorCssClass()]"
          (mouseover)="toggle(triggerReady2WFWarning, triggerReady2WFAlert)"
          (mouseout)="isWarningOverlayOpen = false; isAlertOverlayOpen = false"
        >
          <mat-icon *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record</mat-icon>
          <mat-icon *ngIf="isStatus('Cancelled')" [inline]="true">clear</mat-icon>
          <span class="cursorsText"><strong>Ready 2WF</strong></span>
          <mat-icon
            [hidden]="!isActualStatusLowerThanJobReadiness()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerReady2WFWarning="cdkOverlayOrigin"
            >report_problem
          </mat-icon>
          <mat-icon
            [hidden]="isActualStatusLowerThanJobReadiness() || !isAlertActualStatus()"
            [inline]="true"
            class="text-danger"
            cdkOverlayOrigin
            #triggerReady2WFAlert="cdkOverlayOrigin"
            >error_outline
          </mat-icon>
        </div>
        <div
          *ngIf="isStatus('Active')"
          [hidden]="!showFirstJobCursor('Preparation', 'Site Execution')"
          id="siteExecutionPreparation"
          [ngClass]="['firstCursorText', 'siteExecutionPosition', cancelledCursorCssClass()]"
        >
          <mat-icon class="siteExecutionIcon" *ngIf="isStatus('Active')" [inline]="true">fiber_manual_record </mat-icon>
          <span class="siteExecutionText"><strong>{{'generalDetails.sitePrep' | translate}}</strong></span>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="2" [rowspan]="3"
        ><span class="text-end me-3"> {{'generalDetails.createdOn' | translate}} : {{ this.creationDate }}</span></mat-grid-tile
      >
      <mat-grid-tile [colspan]="2" [rowspan]="3">
        <span class="text-start ms-3"> {{'generalDetails.plannedOn' | translate}} : {{ this.startDate }}</span>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1">
        <mat-progress-bar mode="determinate" value="50" [ngClass]="[progressBarCssClass('gray-progress'), 'timeLine']">
        </mat-progress-bar>
        <div *ngIf="isStatus('Draft')" style="position: absolute; left: 22%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="isStatus('Ready to activate')" style="position: absolute; left: 72%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1">
        <mat-progress-bar
          mode="determinate"
          value="50"
          [ngClass]="[progressBarCssClass('orange-progress'), 'timeLine']"
        ></mat-progress-bar>
        <div *ngIf="showFirstJobCursor('Awaiting Prep', '4M')" style="position: absolute; left: 22%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showSecondJobCursor('Awaiting Prep', '4M')" style="position: absolute; left: 25%">
          <mat-icon [inline]="true" [ngClass]="'secondCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showFirstJobCursor('Ready', '4M')" style="position: absolute; left: 70%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showSecondJobCursor('Ready', '4M')" style="position: absolute; left: 73%">
          <mat-icon [inline]="true" [ngClass]="'secondCursorIcon'">navigation</mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1">
        <mat-progress-bar mode="determinate" value="50" [ngClass]="[progressBarCssClass('blue-progress'), 'timeLine']">
        </mat-progress-bar>
        <div *ngIf="showFirstJobCursor('Awaiting Prep', '5W')" style="position: absolute; left: 22%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showSecondJobCursor('Awaiting Prep', '5W')" style="position: absolute; left: 25%">
          <mat-icon [inline]="true" [ngClass]="'secondCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showFirstJobCursor('Ready', '5W')" style="position: absolute; left: 70%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showSecondJobCursor('Ready', '5W')" style="position: absolute; left: 73%">
          <mat-icon [inline]="true" [ngClass]="'secondCursorIcon'">navigation</mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="1">
        <mat-progress-bar
          mode="determinate"
          value="50"
          [ngClass]="[progressBarCssClass('violet-progress'), 'timeLine']"
        ></mat-progress-bar>
        <div *ngIf="showFirstJobCursor('Awaiting Prep', '2WF')" style="position: absolute; left: 22%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showSecondJobCursor('Awaiting Prep', '2WF')" style="position: absolute; left: 25%">
          <mat-icon [inline]="true" [ngClass]="'secondCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showFirstJobCursor('Ready', '2WF')" style="position: absolute; left: 70%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="showSecondJobCursor('Ready', '2WF')" style="position: absolute; left: 73%">
          <mat-icon [inline]="true" [ngClass]="'secondCursorIcon'">navigation</mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="1">
        <mat-progress-bar
          mode="determinate"
          value="100"
          [ngClass]="[progressBarCssClass('green-progress'), 'timeLine']"
        ></mat-progress-bar>
        <div
          *ngIf="showFirstJobCursor('Preparation', 'Site Execution') && isStatus('Active')"
          style="position: absolute; left: 45%"
        >
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
        <div *ngIf="isStatus('Closed')" style="position: absolute; left: 80%">
          <mat-icon [inline]="true" [ngClass]="'firstCursorIcon'">navigation</mat-icon>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="22" [rowspan]="2">
        <div
          *ngIf="showSecondJobCursor('Awaiting Prep', '4M')"
          id="jobReadinessAwaitingPreparation4M"
          class="secondCursorText pos1"
        >
          <mat-icon [inline]="true">star</mat-icon>
          <span class="cursorsText"><strong>Awaiting Preparation 4M</strong></span>
        </div>
        <div *ngIf="showSecondJobCursor('Ready', '4M')" id="jobReadinessReady4M" class="secondCursorText pos2">
          <mat-icon [inline]="true">star</mat-icon>
          <span class="cursorsText"><strong>Ready 4M</strong></span>
        </div>
        <div
          *ngIf="showSecondJobCursor('Awaiting Prep', '5W')"
          id="jobReadinessAwaitingPreparation5W"
          class="secondCursorText pos3"
        >
          <mat-icon [inline]="true">star</mat-icon>
          <span class="cursorsText"><strong>Awaiting Preparation 5W</strong></span>
        </div>
        <div *ngIf="showSecondJobCursor('Ready', '5W')" id="jobReadinessReady5W" class="secondCursorText pos4">
          <mat-icon [inline]="true">star</mat-icon>
          <span class="cursorsText"><strong>Ready 5W</strong></span>
        </div>
        <div
          *ngIf="showSecondJobCursor('Awaiting Prep', '2WF')"
          id="jobReadinessAwaitingPreparation2WF"
          class="secondCursorText pos5"
        >
          <mat-icon [inline]="true">star</mat-icon>
          <span class="cursorsText"><strong>Awaiting Preparation 2WF</strong></span>
        </div>
        <div *ngIf="showSecondJobCursor('Ready', '2WF')" id="jobReadinessReady2WF" class="secondCursorText pos6">
          <mat-icon [inline]="true">star</mat-icon>
          <span class="cursorsText"><strong>Ready 2WF</strong></span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="isStatus('Closed') || isStatus('Cancelled')" [colspan]="2" [rowspan]="2"></mat-grid-tile>
      <mat-grid-tile *ngIf="isStatus('Closed')" [ngClass]="['warning-box', 'messageBox', 'text-success']" [colspan]="16" [rowspan]="2">
        <mat-icon [inline]="true">info</mat-icon>
        <span class="ps-3"> This activity has been executed on {{ startDate }} </span>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="isStatus('Cancelled')" [ngClass]="['warning-box', 'messageBox', 'text-danger']" [colspan]="16" [rowspan]="2">
        <mat-icon [inline]="true">info</mat-icon>
        <span class="ps-3"> This activity has been cancelled before execution </span>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="isStatus('Closed') || isStatus('Cancelled')" [colspan]="2" [rowspan]="2"></mat-grid-tile>
    </mat-grid-list>
    <div
      *ngIf="isDraft || isStatus('Active')"
      class="d-flex gray align-items-center p-2"
    >
      <div
      class="d-flex gray align-items-center"
      (focus)="isLegendsOverlayOpen = true"
      (mouseover)="isLegendsOverlayOpen = true"
      (blur)="isLegendsOverlayOpen = false"
      (mouseout)="isLegendsOverlayOpen = false">
        <mat-icon [inline]="true">fiber_manual_record</mat-icon>
        <span class="ps-1 pe-2">{{'generalDetails.actualStatus' | translate}}</span>
        <mat-icon [inline]="true">star</mat-icon>
        <span class="ps-1 pe-2">{{'generalDetails.CurrentJobReadiness' | translate}}</span>
        <mat-icon [inline]="true" cdkOverlayOrigin #triggerLegends="cdkOverlayOrigin"> info_outline </mat-icon>
      </div>
      <div class="text-danger d-flex align-items-center warning-box p-2 rounded ms-auto" *ngIf="isJobLeaderOrOffshoreFocalPoint && isAwaiting2WFValidation && !isReadyFor2WF">
        <mat-icon [inline]="true" class="pe-1">error</mat-icon>
        <span>You have to endorse the 2 weeks frozen gate</span>
      </div>
      <div class="text-high-alert d-flex align-items-center warning-box p-2 rounded ms-auto" *ngIf="pendingActivation">
        <mat-icon [inline]="true" class="pe-1">error</mat-icon>
        <span>Failure in P6 for job card activation. Your job card will be activated when the connection with P6 is restored.</span>
      </div>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="triggerLegends"
        [cdkConnectedOverlayOpen]="isLegendsOverlayOpen"
      >
        <div class="info-overlay">
          <h5 class="d-flex align-items-center">
            <mat-icon [inline]="true" class="pe-1">error_outline</mat-icon>
            <span>Info</span>
          </h5>
          <p>
            {{'generalDetails.actualStatusTooltip' | translate}}
            <br />{{'generalDetails.potentialStatusTooltip' | translate}}
          </p>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerAlertOrigin"
    [cdkConnectedOverlayOpen]="isAlertOverlayOpen"
  >
    <div class="info-overlay">
      <h5 class="text-danger d-flex align-items-center">
        <mat-icon [inline]="true" class="pe-1">error_outline</mat-icon>
        <span>Info</span>
      </h5>
      <p>
        You start preparing for this activity well in advance. This activity will be reviewed in a planning meeting when
        the execution date is within the range of the gate.
      </p>
      <p>
        According to its characteristics, this activity must be reviewed during the 5W planning meeting. The number of
        days before execution of the activity (150 days) exceeds the range of the 5W Gate (between 35 and 120 days).
      </p>
    </div>
  </ng-template>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="triggerWarningOrigin"
    [cdkConnectedOverlayOpen]="isWarningOverlayOpen"
  >
    <div class="info-overlay">
      <h5 [class]="'text-danger'" style="display: flex">
        <mat-icon [inline]="true" style="padding-right: 8px">report_problem</mat-icon>
        Warning
      </h5>
      <p>
        Discrepancy between the planning status of the Job Card and the readiness of the associated pre-requisites : You
        have to either updates your prerequisites, or change the starting date of the operation, or request for a
        derogation.
      </p>
    </div>
  </ng-template>
</div>
<div class="pb-3"></div>
