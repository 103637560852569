export enum WorkOrderPrerequisite {
  Scaffolding = 'Scaffolding',
  Lifting = "Lifting",
  Isolation = 'Isolation',
  Calorifuge = "Calorifuge",
  Inhibition = "Inhibition",
  CoreCrewAssistance = "Core crew assistance",
  ContractorAssistance = "Contractor assistance",
  OnshoreWorks = "Onshore works",
  RFMNumber = "RFM number",
  DivingROV = "Diving/ROV"
}

export interface WorkOrderDetails {
  id: number;
  priority: string;
  description: string;
  metier: string;
  disciplineCategory: string;
  discipline: string;
  masterNotification: string;
  functionalLocation: string;
  functionalLocationDescription: string;
  activityType: string;
  jobEstimatedCost: number;
  fromSap: boolean;
  sitePlatform: string;
  wbsElement: string;
  wbsDescription: string;
  materials: WorkOrderMaterial[];
  revisionCode: string;
  startDate: string;
  endDate: string;
  flocPlatform: string;
  prerequisites: WorkOrderPrerequisite[];
}

export interface WorkOrderMaterial {
  affiliate?: string
  eta?: Date
  id?: number
  itemNumber?: string
  jobCardId?: number
  description?: string
  materialNumber?: number
  quantityRequested?: number
  quantityStock?: number
  quantitySite?: number
  procurementStatus?: string
  qaQcCertificate?: string
  sapOrigin?: boolean
  unloadingPoint?: string
  workOrderNumber?: number
}
