export interface ResourceInvitation {
  id: number;
  displayed: boolean;
  status: string;
  internationalFlights : string;
  invitationVisaStatus: string;
  description: string;
  jobCardId: number;
  readiness: string;
}

export interface ResourceInvitationInput {
  id: number;
  displayed: boolean;
  status: string;
  internationalFlights : string;
  invitationVisaStatus: string;
  description: string;
  jobCardId: number;
}

export enum InvitationStatusEnum {
  NOT_STARTED = "Not Started",
  NOT_REQUIRED = "Not required",
  PENDING = "Pending",
  RELEASED = "Released"
}
export enum InternationalFlightsEnum {
  NOT_REQUIRED = "Not required",
  NOT_STARTED = "Not Started",
  DONE = "Booking Done"

}
