import { Injectable } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { NEVER, Observable, Subscription, defer } from "rxjs";
import { finalize, share } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class PendingHttpRequestsInterceptor implements HttpInterceptor {
  public readonly spinner$ = defer(() => {
    this.spinner.show();
    return NEVER.pipe(
      finalize(() => {
        this.spinner.hide();
      }),
    );
  }).pipe(share());

  constructor(private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.body?.query.includes("createAllByRevisionCode(")) {
      return next.handle(request);
    }
    const spinnerSubscription: Subscription = this.spinner$.subscribe();
    return next.handle(request).pipe(finalize(() => spinnerSubscription.unsubscribe()));
  }
}

export const pendingRequestsInterceptorProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: PendingHttpRequestsInterceptor,
    multi: true,
  },
];
