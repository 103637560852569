import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { JobCardMasterCreationInput } from "@shared/models/job-card-master/job-card-master.model";
import { JobLeadersService, ProfileService, SitePlatformsService } from "@shared/services";
import { JobCardMasterDetailService } from "@shared/services/job-card-master/details/job-card-master-details.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { buildSearchFunction } from "@shared/utils";
import { DatePipe } from "@angular/common";
import { SiteInfo } from "@shared/models/siteplatform/site-platform.model";

@Component({
  selector: "jbid-add-new-job-card-master-modal",
  templateUrl: "./add-new-job-card-master-modal.component.html",
  styleUrls: ["./add-new-job-card-master-modal.component.scss"],
})
export class AddNewJobCardMasterModalComponent implements OnInit, OnDestroy {
  formGroup!: UntypedFormGroup;
  private destroy = new Subject<void>();
  jobLeaders!: string[];
  listSitePlatform: SiteInfo[] = [];
  private affiliate: string | undefined;
  search = buildSearchFunction((term: string) =>
    term === ""
      ? []
      : this.listSitePlatform
          .filter((v) => v.displayName.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10)
          .map((v) => v.displayName),
  );

  constructor(
    private profileService: ProfileService,
    private jobCardMasterDetailService: JobCardMasterDetailService,
    private jobLeadersService: JobLeadersService,
    public activeModal: NgbActiveModal,
    private activeModalService: NgbActiveModal,
    private router: Router,
    private datePipe: DatePipe,
    private sitePlatformsService: SitePlatformsService,
  ) {}

  searchJobLeader = buildSearchFunction((term: string) =>
    term ? this.jobLeaders.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10) : [],
  );

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({
      isCampaign: new UntypedFormControl(false),
      title: new UntypedFormControl("", [Validators.required]),
      owner: new UntypedFormControl("", [Validators.required]),
      mainSite: new UntypedFormControl("", [Validators.required]),
      startDate: new UntypedFormControl("", [Validators.required]),
      endDate: new UntypedFormControl("", [Validators.required]),
    });

    this.jobLeadersService.jobLeaders$.pipe(takeUntil(this.destroy)).subscribe((value) => {
      this.jobLeaders = value;
      if (this.jobLeaders != null) {
        this.jobLeaders.filter((s) => !!s).sort((a, b) => a.localeCompare(b));
      }
    });

    this.sitePlatformsService.siteInfoDefaults$.pipe(takeUntil(this.destroy)).subscribe((sitePlatformList) => {
      this.listSitePlatform = sitePlatformList;
    });

    this.jobCardMasterDetailService.jobCardMasterCreated$.pipe(takeUntil(this.destroy)).subscribe((data) => {
      this.router.navigateByUrl(`/job-card-master/detail/${this.affiliate}/${data.id}/general`, { replaceUrl: true });
      this.activeModalService.close();
    });
  }

  onSubmit() {
    this.affiliate = this.profileService.getAffiliate().value;
    const jobCardMasterCreationInput: JobCardMasterCreationInput = {
      isCampaign: this.formGroup.get("isCampaign")?.value,
      title: this.formGroup.get("title")?.value,
      owner: this.formGroup.get("owner")?.value,
      mainSite: this.formGroup.get("mainSite")?.value,
      startDate:
        this.datePipe.transform(this.formGroup?.getRawValue()?.startDate, "dd/MM/yyyy") ??
        this.formGroup?.getRawValue()?.startDate,
      endDate:
        this.datePipe.transform(this.formGroup?.getRawValue()?.endDate, "dd/MM/yyyy") ??
        this.formGroup?.getRawValue()?.endDate,
      affiliate: this.affiliate,
      isShutDownDuringCampaign: false,
    };
    this.jobCardMasterDetailService.createJobCardMaster(jobCardMasterCreationInput);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
