import {
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewContainerRef,
} from "@angular/core";
import { AbstractControl, ControlContainer, NgControl } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FORM_ERRORS } from "@core/core.module";
import { AppFormSubmitDirective } from "../app-form-submit/app-form-submit.directive";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AppFormControlErrorDisplayComponent } from "@shared/components/forms/app-form-control-error-display/app-form-control-error-display.component";

/**
 * Directive used to tell the controlError to use the default ViewContainerRef
 */
@Directive({
  selector: "[jbidErrorAnchor]",
})
export class JbidErrorAnchorDirective {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[controlErrorAnchor]",
  exportAs: "controlErrorAnchor",
})
export class ControlErrorAnchorDirective {
  constructor(public vcr: ViewContainerRef) {}
}

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    "[formControlName]:not([controlErrorsIgnore]), [formControl]:not([controlErrorsIgnore]), [formGroup]:not([controlErrorsIgnore]), [formGroupName]:not([controlErrorsIgnore]), [formArrayName]:not([controlErrorsIgnore]), [ngModel]:not([controlErrorsIgnore])",
  exportAs: "jbidControlError",
})
export class AppFormControlErrorDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  private ref!: ComponentRef<AppFormControlErrorDisplayComponent>;

  // @Input() controlErrorsTpl: TemplateRef<any> | undefined;

  private anchor!: ViewContainerRef;
  @Input() controlErrorAnchor!: ControlErrorAnchorDirective;

  private control!: AbstractControl;

  private formSubmitted = false;

  @HostListener("mouseenter") onMouseEnter() {
    if (this.formSubmitted && this.tooltip) {
      this.tooltip.open();
    }
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.formSubmitted && this.tooltip) {
      this.tooltip.close();
    }
  }

  get element() {
    return this.host.nativeElement;
  }

  constructor(
    private host: ElementRef,
    private vcr: ViewContainerRef,
    @Inject(FORM_ERRORS) private errors: any,
    @Optional() @Host() private formSubmitDirective: AppFormSubmitDirective,
    @Optional() private tooltip: NgbTooltip,
    @Optional() @Self() private readonly ngControl: NgControl,
    @Optional() @Self() private controlContainer: ControlContainer,
    @Optional() private controlErrorAnchorParent: ControlErrorAnchorDirective,
    @Optional() private jbidErrorAnchorDirective: JbidErrorAnchorDirective,
  ) {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
    this.clearRefs();
  }

  ngOnInit() {
    this.anchor = this.resolveAnchor();

    if (this.tooltip) {
      this.tooltip.tooltipClass = "form-error-tooltip"; // class to update in jbid.scss
      this.tooltip.autoClose = false;
    }
    this.formSubmitDirective?.submitted$
      .pipe(takeUntil(this.destroy$))
      .subscribe((submittedSate) => this.onValidate(submittedSate));
  }

  private onValidate(submittedSate: any) {
    if (this.formSubmitDirective?.isGeneralStatusDraft) {
      this.hideError();
      return;
    }

    this.formSubmitted = submittedSate.submitted;

    this.control = (this.controlContainer || this.ngControl).control ?? ({} as any);
    const controlErrors = this.control.errors;

    if (controlErrors && controlErrors.length !== 0 && this.formSubmitted) {
      if (this.element.classList.contains("is-invalid") === false) {
        this.element.classList.add("is-invalid");
      }
      const [firstKey] = Object.keys(controlErrors);
      const getError = this.errors[firstKey];
      if (!getError) {
        return;
      }
      const text = typeof getError === "function" ? getError(controlErrors[firstKey]) : getError;
      this.showError(text);
    } else {
      this.hideError();
    }
  }

  private showError(text: string) {
    if (this.tooltip) {
      this.tooltip.ngbTooltip = text;
    } else {
      if (!this.ref) {
        this.ref = this.vcr.createComponent(AppFormControlErrorDisplayComponent);
      }
      const instance = this.ref.instance;
      instance.text = text;
    }
  }

  private hideError() {
    if (this.tooltip) {
      this.tooltip.ngbTooltip = "";
    } else if (this.ref) {
      this.ref.instance.text = "";
    }
    if (this.element.classList) {
      this.element.classList.remove("is-invalid");
    }
  }

  private resolveAnchor() {
    if (this.controlErrorAnchor) {
      return this.controlErrorAnchor.vcr;
    }
    if (this.controlErrorAnchorParent && !this.jbidErrorAnchorDirective) {
      return this.controlErrorAnchorParent.vcr;
    }
    return this.vcr;
  }

  private clearRefs(): void {
    if (this.ref) {
      this.ref.destroy();
    }
    this.ref = {} as any;
  }
}
