import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {

  constructor(
    public toastrService: ToastrService,
  ) {
  }

  exportToExcel(data: any[], sheetName: string, fileName: string, successMessage: string): void {
    // Create a new workbook
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Convert the row data to worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // Write the workbook to a file
    XLSX.writeFile(wb, fileName);

    this.toastrService.success(successMessage, "Success");
  }
}
