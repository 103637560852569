import { Component, Input } from "@angular/core";
import { ReadinessModel } from "@shared/models/prerequisites/Readiness.model";

@Component({
  selector: 'jbid-readiness',
  templateUrl: './readiness.component.html',
  styleUrls: ['./readiness.component.css']
})
export class ReadinessComponent  {

  _isSectionChecked: boolean | undefined;

  @Input()
  displayReadinessText = true;

  displayReadiness = true;

  readiness: ReadinessModel | undefined;
  _readinessText!:string|undefined;

  @Input()
  set readinessText(readinessText:string|undefined){
    this._readinessText=readinessText;
    this.readiness = this.toReadiness(readinessText);
    this.calculateDisplayReadiness();
  }
  get readinessText(){
    return this._readinessText;
  }
  @Input()
  set isSectionChecked(isSectionChecked:boolean | undefined){
    this._isSectionChecked=isSectionChecked;
    this.calculateDisplayReadiness();
  }
  get isSectionChecked(){
    return this._isSectionChecked;
  }


  private calculateDisplayReadiness(){
    this.displayReadiness = this._isSectionChecked == undefined
      || this._isSectionChecked || (!this._isSectionChecked && this._readinessText != ReadinessModel.READY.text);

  }

  private toReadiness(readinessText: string | undefined): ReadinessModel | undefined {
    if (!readinessText) {
      return undefined;
    }
    switch (readinessText) {
      case ReadinessModel.AWAITING_PREPARATION.text:
        return ReadinessModel.AWAITING_PREPARATION;
      case ReadinessModel.DELAYED.text:
        return ReadinessModel.DELAYED;
      case ReadinessModel.READY.text:
        return ReadinessModel.READY;
      default:
        return undefined;
    }
  }
}
