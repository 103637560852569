import gql from "graphql-tag";

const MARK_DISCUSSION_AS_DELETED = gql`
    mutation($id: Int, $affiliate: String!) {
        markDiscussionAsDeleted(id: $id, affiliate: $affiliate) {
            id
            dateCreated
            jobCardId
            favorite
            read
            archive
            messageContent
            messageObject
            prerequisiteTags
            senderName
            deleted
            deleteTime
        }
    }

`;

export {MARK_DISCUSSION_AS_DELETED};
