// https://github.com/scttcper/ngx-toastr/blob/master/src/app/bootstrap.toast.ts
// https://github.com/scttcper/ngx-toastr/blob/master/src/styles.scss
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

@Component({
  selector: "jbid-toast",
  templateUrl: "app-toast.component.html",
  styleUrls: ["app-toast.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class AppToastComponent extends Toast {
  // constructor is only necessary when not using AoT
  constructor(public toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  handleCloseClick(event: Event) {
    event.stopPropagation();
    this.remove();
    return false;
  }
}
