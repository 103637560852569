export type LiftingPlanStatus = "Not issued" | "Issued" | "Validated";
export const aLiftingPlanStatus: string[] = ["Not issued", "Issued", "Validated"];


export interface ResourceLiftingDTO {
  id?: number;
  jobCardId?: number;
  displayed?: boolean;
  criticalLifting?: boolean;
  liftingPlanStatus?: LiftingPlanStatus;
  liftingDescription?: string;
  readiness: string | undefined;
  slsRequired: boolean;
  craneRequired: boolean;
  craneDescription: string;
  liftingProcedureRequired: boolean;
}

export interface ResourceLiftingDTOInput {
  id?: number;
  jobCardId?: number;
  displayed?: boolean;
  criticalLifting?: boolean;
  liftingPlanStatus?: LiftingPlanStatus;
  liftingDescription?: string;
  slsRequired: boolean;
  craneRequired: boolean;
  craneDescription: string;
  liftingProcedureRequired: boolean;
}
