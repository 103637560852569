import {Injectable} from "@angular/core";
import {FormArray, UntypedFormBuilder, Validators} from "@angular/forms";
import {ResourceContractor, ResourceContractorInput} from "@shared/models/prerequisites";
import {AbstractArrayFormService} from "../../abstract-array-form.service";

@Injectable({
  providedIn: "root",
})
export class ContractorAssistanceFormService extends AbstractArrayFormService {

  jobCardId!: number;

  defaultDTO: ResourceContractor = {
    contractorActivityServiceOrder: false,
    readiness: undefined,
  };

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(resourceContractor?: ResourceContractor) {
    return this.fb.group({
      id: [resourceContractor?.id],
      displayed: [resourceContractor?.displayed, []],
      contractorActivityAmount: [resourceContractor?.contractorActivityAmount, [Validators.required]],
      contractorActivityOrder: [resourceContractor?.contractorActivityOrder, [Validators.required]],
      contractorActivityServiceOrder: [resourceContractor?.contractorActivityServiceOrder, [Validators.required]],
      contractorActivityName: [resourceContractor?.contractorActivityName, [Validators.required]],
      contractorActivityNumber: [resourceContractor?.contractorActivityNumber, [Validators.required]],
      contractorActivityVendorJustification: [resourceContractor?.contractorActivityVendorJustification, [Validators.required]],
    });
  }

  createForm(isModeReadOnly: boolean, resourceContractorList?: ResourceContractor[]) {
    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = resourceContractorList;

      const isChecked = (resourceContractorList?.length !== 0 && resourceContractorList?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isModeReadOnly }, []],
        items: this.setInitialData(resourceContractorList),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(resourceContractorList: ResourceContractor[]): ResourceContractorInput[] {
    if (!resourceContractorList) {
      return [];
    }
    if (!this.form) return resourceContractorList.map((resourceContractorList) => this.copy(resourceContractorList));

    const formItems: FormArray = this.form.controls.items as FormArray;
    let items: ResourceContractor[] = [];

    if (formItems && formItems.controls.length !== 0) {
      items = formItems.controls.map((item: any) => {
        item = this.copyFromFormItem(item.controls);
        item.jobCardId = this.jobCardId;
        item.displayed = this.form.controls.checked.value;
        return item;
      });
    }
    return items;
  }

  private copyFromFormItem(formItem : any){
    return {
      id : formItem.id?.value,
      displayed : formItem.displayed.value,
      contractorActivityAmount: formItem.contractorActivityAmount.value,
      contractorActivityOrder: formItem.contractorActivityOrder.value,
      contractorActivityServiceOrder: formItem.contractorActivityServiceOrder.value,
      contractorActivityVendorJustification: formItem.contractorActivityVendorJustification.value,
      contractorActivityName: formItem.contractorActivityName.value,
      contractorActivityNumber: formItem.contractorActivityNumber.value,
    }
  }

  private copy(resources: ResourceContractorInput) {
    return {
      id: resources?.id,
      displayed: resources?.displayed,
      contractorActivityAmount: resources?.contractorActivityAmount,
      contractorActivityOrder: resources?.contractorActivityOrder,
      contractorActivityServiceOrder: resources?.contractorActivityServiceOrder,
      contractorActivityVendorJustification: resources?.contractorActivityVendorJustification,
      contractorActivityName: resources?.contractorActivityName,
      contractorActivityNumber: resources?.contractorActivityNumber,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }

}
