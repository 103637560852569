import {Injectable} from "@angular/core";
import {DetailFormService} from "@routes/jobid-card/detail/prerequisites/detail-form.service";
import {JobCardMasterAggregate, JobCardMasterCreationInput} from "@shared/models/job-card-master/job-card-master.model";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {getDateFromString} from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class JCMGeneralDetailsFormService extends DetailFormService {


  constructor(private fb: UntypedFormBuilder, private datePipe: DatePipe) {
    super();
  }

  createForm(generalDetails: JobCardMasterAggregate | undefined) {
    this.form = this.fb.group(
      {
        owner: [generalDetails?.owner, [Validators.required],],
        mainSite: [generalDetails?.mainSite, [],],
        shortfall: [generalDetails?.shortfall, [Validators.required],],
        shortfallWaterInjection: [generalDetails?.shortfallWaterInjection, [Validators.required],],
        budgets: [generalDetails?.budgets, [Validators.required],],
        specificAccommodation: [generalDetails?.specificAccommodation, [Validators.required],],
        pob: [generalDetails?.pob, [Validators.required],],
        startDate: [generalDetails?.startDate ? getDateFromString(generalDetails.startDate.toString()) : null, [Validators.required],],
        endDate: [generalDetails?.endDate ? getDateFromString(generalDetails.endDate.toString()) : null, [Validators.required],],
        isShutDownDuringCampaign: [generalDetails?.isShutDownDuringCampaign, [Validators.required],],
        shutDownDate: [generalDetails?.shutDownDate ? getDateFromString(generalDetails.shutDownDate.toString()) : undefined, [],],
        shutDownDuration: [generalDetails?.shutDownDuration, [],],
        sites: [generalDetails?.sites?.filter(s=> s !== '[]'), [],],
        revisionCode: [generalDetails?.revisionCode, [],],
      });
    return this.form;
  }

  getControl(field: string) {
    return this.form?.get(field);
  }

  isFormValid(): boolean {
    return this.form === undefined ? true : this.form.valid;
  }

  getFormData(generalDetails: JobCardMasterAggregate | undefined): JobCardMasterCreationInput {
    if (!this.form) return this.copy(generalDetails);
    const formRawValue = this.form?.getRawValue();
    return {
      id: Number(generalDetails?.id),
      title: generalDetails?.title,
      affiliate: generalDetails?.affiliate,
      mainSite: generalDetails?.mainSite,
      sites: formRawValue?.sites,
      startDate: this.datePipe.transform(formRawValue?.startDate, "dd/MM/yyyy") ?? formRawValue?.startDate,
      endDate: this.datePipe.transform(formRawValue?.endDate, "dd/MM/yyyy") ?? formRawValue?.endDate,
      owner: formRawValue?.owner,
      isCampaign: generalDetails?.isCampaign,
      shortfall: Number(formRawValue?.shortfall),
      shortfallWaterInjection: Number(formRawValue?.shortfallWaterInjection),
      budgets: Number(formRawValue?.budgets),
      pob: Number(formRawValue?.pob),
      specificAccommodation: formRawValue?.specificAccommodation,
      isShutDownDuringCampaign: formRawValue?.isShutDownDuringCampaign,
      shutDownDate: this.datePipe.transform(formRawValue?.shutDownDate, "dd/MM/yyyy") ?? formRawValue?.shutDownDate,
      shutDownDuration: Number(formRawValue?.shutDownDuration),
      revisionCode: formRawValue?.revisionCode,
    };
  }

  copy(generalDetails: JobCardMasterAggregate | undefined): JobCardMasterCreationInput {
    return {
      id: generalDetails?.id,
      title: generalDetails?.title,
      affiliate: generalDetails?.affiliate,
      mainSite: generalDetails?.mainSite,
      sites: generalDetails?.sites,
      startDate: generalDetails?.startDate,
      endDate: generalDetails?.endDate,
      owner: generalDetails?.owner,
      isCampaign: generalDetails?.isCampaign,
      shortfall: generalDetails?.shortfall,
      shortfallWaterInjection: generalDetails?.shortfallWaterInjection,
      budgets: generalDetails?.budgets,
      pob: generalDetails?.pob,
      specificAccommodation: generalDetails?.specificAccommodation,
      isShutDownDuringCampaign: generalDetails?.isShutDownDuringCampaign,
      shutDownDate: generalDetails?.shutDownDate,
      shutDownDuration: generalDetails?.shutDownDuration,
      revisionCode: generalDetails?.revisionCode,
    }
  }

  getAllFormsErrorsList() {
    if (!this.form) {
      return [];
    }
    const invalid = [];
    const controls = this.form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

}
