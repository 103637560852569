import {NgModule} from "@angular/core";
import {APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule} from "apollo-angular";
import {ApolloClientOptions, ApolloLink, DefaultOptions, InMemoryCache} from "@apollo/client/core";
import {HttpLink} from "apollo-angular/http";
import {setContext} from "@apollo/client/link/context";
import {environment} from "@env/environment";

const uri = environment.apiBaseUrl;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  function getTokenFromSessionStorage() {
    for (const [key, value] of Object.entries(sessionStorage)) {
      if (value.includes("AccessToken")) {
        if (sessionStorage.getItem(key)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return JSON.parse(sessionStorage.getItem(key)).secret;
        }
      }
    }
  }

  const basic = setContext((_operation, _context) => ({
    headers: {
      Accept: "charset=utf-8",
    },
  }));

  const auth = setContext(() => {
    const token = getTokenFromSessionStorage();
    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  const cache = new InMemoryCache({
    addTypename: false
  })

  return {
    link,
    cache,
    defaultOptions,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true, // enable loading state
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
