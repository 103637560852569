import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { DetailFormService } from "@routes/jobid-card/detail/prerequisites/detail-form.service";
import { CustomValidators } from "@shared/components/forms/custom.validators";
import { aActivityType, JobCardGeneralDetailsAggregate } from "@shared/models/jobid-card/GeneralDetailsAggregate.model";
import { DatePipe } from "@angular/common";
import { GeneralStatus, JobidCardShiftType } from "@shared/models/jobid-card/jobid-card.model";
import { getDateFromString } from "@shared/utils";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class GeneralDetailsFormService extends DetailFormService {
  jobAttributesConfig: any;
  private disciplines: string[] = [];
  private revisionCodes?: string[];
  private activityOwners?: string[];

  public setDisciplines(disciplines: string[]) {
    this.disciplines = disciplines;
    const control = this.form?.get("discipline");
    control?.markAsDirty();
    control?.updateValueAndValidity();
  }

  public setRevisionCodes(revisionCodes: string[]) {
    this.revisionCodes = revisionCodes;
    const control = this.form?.get("revisionCode");
    control?.markAsDirty();
    control?.updateValueAndValidity();
  }

  public setactivityOwner(affiliateUsersName: string[]) {
    this.activityOwners = affiliateUsersName;
    const control = this.form?.get("activityOwner");
    control?.markAsDirty();
    control?.updateValueAndValidity();
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly datePipe: DatePipe,
    private readonly translate: TranslateService,
  ) {
    super();
    this.jobAttributesConfig = {
      siteVisitOnly: { name: this.translate.instant("generalDetails.siteVisitOnly"), value: false },
      sece: { name: this.translate.instant("generalDetails.sece"), value: false },
      dgs: { name: this.translate.instant("generalDetails.dgs"), value: false },
      loti: { name: this.translate.instant("generalDetails.loti/LTI"), value: false },
    };
  }

  private jobAttributesToForm(generalDetails?: JobCardGeneralDetailsAggregate) {
    const jobAttributesData = generalDetails?.attributes?.split("/") || [];

    const jobAttributes: any = {
      siteVisitOnly: { name: "Site visit only", value: false },
      sece: { name: "SECE", value: false },
      dgs: { name: "DGS", value: false },
      loti: { name: "LOTI/LTI", value: false },
    };

    for (const element of jobAttributesData) {
      if (generalDetails?.siteVisitOnly) {
        jobAttributes["siteVisitOnly"].value = true;
      }

      if (element.toLowerCase().includes("sece")) {
        jobAttributes["sece"].value = true;
      }

      if (element.toLowerCase().includes("dgs")) {
        jobAttributes["dgs"].value = true;
      }

      if (element.toLowerCase().includes("loti")) {
        jobAttributes["loti"].value = true;
      }
    }

    return jobAttributes;
  }

  private readonly disciplineValidatorFunction: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return this.disciplines?.includes(control?.value) ? null : { validDiscipline: false };
  };

  private readonly validateRevisionCode = (control: AbstractControl): ValidationErrors | null => {
    const revisionCode: string = control?.value;
    return !revisionCode || this.revisionCodes?.includes(revisionCode)
      ? null
      : {
          invalidRevisionCode: true,
        };
  };

  activityOwnerValidatorFunction: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return this.activityOwners?.includes(control?.value) ? null : { validActivityOwner: false };
  };

  validateDGS = () => {
    const dgs = this.form?.get("dgs")?.value;
    const dgsValue = this.form?.get("dgsValue")?.value;
    return !dgs || (dgs && dgsValue != null && dgsValue != "" && dgsValue != undefined) ? null : { validateDGS: false };
  };
  validateLOTI = () => {
    const loti = this.form?.get("loti")?.value;
    const lotiValue = this.form?.get("lotiValue")?.value;
    return !loti || (loti && lotiValue != null && lotiValue != "" && lotiValue != undefined)
      ? null
      : { validateLOTI: false };
  };
  validateWbs = () => {
    const wbsElement = this.form?.get("wbsElement")?.value;
    const wbsDescription = this.form?.get("wbsDescription")?.value;
    if ((wbsElement || "").trim().length > 0 && (wbsDescription || "").trim().length <= 0) {
      return { wbsElementNotFound: true };
    }
    return null;
  };

  updateForm(generalDetails?: JobCardGeneralDetailsAggregate) {
    if (this.form == null) {
      this.initForm();
    }
    this.data = generalDetails;
    const jobAttributes: any = this.jobAttributesToForm(generalDetails);
    const numberOfNight = this.calculateNights(generalDetails);
    if (!this.form.touched) {
      this.form.patchValue({
        description: generalDetails?.description,
        functionalLocation: generalDetails?.functionalLocation,
        functionalLocationDescription: generalDetails?.functionalLocationDescription,
        revisionCode: generalDetails?.revisionCode,
        discipline: generalDetails?.discipline,
        activityType: generalDetails?.activityType ? this.findActivityTypeFromValue(generalDetails.activityType) : null,
        jobLeader: generalDetails?.jobLeader,
        notificationNumber: generalDetails?.masterNotification,
        workOrder: generalDetails?.fromSap ? generalDetails?.workOrderNumber : "",
        jobEstimatedCost: generalDetails?.jobEstimatedCost,
        siteVisitOnly: generalDetails?.siteVisitOnly,
        sece: jobAttributes["sece"].value,
        seceFromSap: generalDetails?.seceFromSap,
        dgd: jobAttributes["dgs"].value,
        loti: jobAttributes["loti"].value,
        lotiValue: generalDetails?.lotiValue,
        dgsValue: generalDetails?.dgsValue,
        startDate: generalDetails?.startDate ? getDateFromString(generalDetails?.startDate?.toString()) : null,
        endDate: generalDetails?.endDate ? getDateFromString(generalDetails?.endDate?.toString()) : null,
        priority: generalDetails?.priority,
        shiftType: generalDetails?.shiftType ? generalDetails?.shiftType : JobidCardShiftType.DAY_SHIFT,
        maximumPob: generalDetails?.maximumPob,
        activityOwner: generalDetails?.activityOwner,
        numberOfNights: numberOfNight,
        activityId: generalDetails?.activityId,
        projectId: generalDetails?.projectId,
        wbsObject: undefined,
        wbsElement: generalDetails?.wbsElement,
        wbsDescription: generalDetails?.wbsDescription,
        flocPlatform: generalDetails?.flocPlatform,
      });
    }
    return this.form;
  }

  private findActivityTypeFromValue(value: string): string | null {
    return aActivityType.find((element) => element.value.startsWith(value))?.value ?? null;
  }

  private initForm() {
    this.form = this.fb.group(
      {
        description: ["", [Validators.required]],
        functionalLocation: ["", [Validators.required]],
        functionalLocationDescription: ["", [Validators.required]],
        revisionCode: ["", [this.validateRevisionCode]],
        discipline: ["", [Validators.required, this.disciplineValidatorFunction]],
        activityType: ["", [Validators.required]],
        jobLeader: ["", [Validators.required]],
        notificationNumber: ["", []],
        workOrder: undefined,
        jobEstimatedCost: [0, [Validators.min(0)]],
        // Job attributes
        siteVisitOnly: [false, []],
        sece: false,
        seceFromSap: false,
        dgs: false,
        loti: false,
        lotiValue: "",
        dgsValue: "",
        // Job planning
        startDate: [undefined, [Validators.required]],
        endDate: [undefined, [Validators.required]],
        priority: ["", [Validators.required]],
        shiftType: ["", [Validators.required]],
        maximumPob: [0, [Validators.required]],
        activityOwner: ["", [Validators.required, this.activityOwnerValidatorFunction]],
        numberOfNights: [0, []],
        activityId: "",
        projectId: "",
        wbsObject: undefined,
        wbsElement: "",
        wbsDescription: "",
        flocPlatform: "",
      },
      {
        validators: [
          CustomValidators.startDateAfterEndDate("startDate", "endDate"),
          this.validateDGS,
          this.validateLOTI,
          this.validateWbs,
        ],
      },
    );
  }

  public calculateNights(generalDetails: JobCardGeneralDetailsAggregate | undefined) {
    let numberOfNight;
    if (generalDetails?.startDate && generalDetails?.endDate) {
      const endDate = getDateFromString(generalDetails?.endDate?.toString());
      const startDate = getDateFromString(generalDetails?.startDate?.toString());
      if (endDate !== null && startDate !== null) {
        numberOfNight = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
      }
    }
    return numberOfNight;
  }

  getFormData(
    generalDetails: JobCardGeneralDetailsAggregate,
    generalStatus: GeneralStatus,
    twoWeeksFrozenValidated: boolean,
  ): Partial<JobCardGeneralDetailsAggregate> {
    if (!this.data) return this.copy(generalDetails);
    const formRawValue = this.form?.getRawValue();
    const data: Partial<JobCardGeneralDetailsAggregate> = {
      id: generalDetails.id,
      jobTitle: generalDetails?.jobTitle,
      priority: formRawValue?.priority,
      workOrderNumber:
        formRawValue?.workOrder === "" ? generalDetails.workOrderNumber : Number(formRawValue?.workOrder),
      description: formRawValue?.description,
      generalStatus: generalStatus,
      startDate: this.datePipe.transform(formRawValue?.startDate, "dd/MM/yyyy") ?? formRawValue?.startDate,
      endDate: this.datePipe.transform(formRawValue?.endDate, "dd/MM/yyyy") ?? formRawValue?.endDate,
      discipline: formRawValue?.discipline,
      revisionCode: formRawValue?.revisionCode,
      masterNotification: String(formRawValue?.notificationNumber),
      functionalLocation: formRawValue?.functionalLocation,
      functionalLocationDescription: formRawValue?.functionalLocationDescription,
      activityType: formRawValue?.activityType || "",
      shiftType: formRawValue?.shiftType,
      sitePlatform: generalDetails.sitePlatform,
      jobLeader: formRawValue?.jobLeader,
      jobEstimatedCost: formRawValue?.jobEstimatedCost,
      maximumPob: Number(formRawValue?.maximumPob),
      fromSap: formRawValue?.workOrder !== "",
      siteVisitOnly: generalDetails?.siteVisitOnly,
      activityId: formRawValue?.activityId,
      projectId: formRawValue?.projectId,
      wbsObjectId: formRawValue?.wbsObject?.objectId,
      dgsValue: formRawValue?.dgsValue,
      lotiValue: formRawValue?.lotiValue,
      wbsElement: formRawValue?.wbsElement,
      wbsDescription: formRawValue?.wbsDescription,
      flocPlatform: generalDetails?.flocPlatform,
      activityOwner: formRawValue?.activityOwner,
      twoWeeksFrozenValidated: twoWeeksFrozenValidated,
    };

    const jobAttributes: string[] = [];
    const jobAttributesConfigKeys = Object.keys(this.jobAttributesConfig);
    for (const element of jobAttributesConfigKeys) {
      const jobAttributeKey = element;
      const value = formRawValue?.[jobAttributeKey];
      if (value) {
        jobAttributes.push(jobAttributeKey);
      }
    }
    data.attributes = jobAttributes.join("/");
    return data;
  }

  copy(generalDetails: JobCardGeneralDetailsAggregate) {
    return {
      id: generalDetails.id,
      jobTitle: generalDetails?.jobTitle,
      priority: generalDetails?.priority,
      workOrderNumber: generalDetails.workOrderNumber,
      description: generalDetails?.description,
      generalStatus: generalDetails.generalStatus,
      startDate: generalDetails.startDate,
      endDate: generalDetails.endDate,
      discipline: generalDetails.discipline,
      revisionCode: generalDetails.revisionCode,
      masterNotification: generalDetails.masterNotification,
      functionalLocation: generalDetails.functionalLocation,
      functionalLocationDescription: generalDetails.functionalLocationDescription,
      activityType: generalDetails.activityType,
      shiftType: generalDetails.shiftType,
      sitePlatform: generalDetails.sitePlatform,
      jobLeader: generalDetails.jobLeader,
      jobEstimatedCost: generalDetails.jobEstimatedCost,
      maximumPob: generalDetails.maximumPob,
      fromSap: generalDetails.fromSap,
      siteVisitOnly: generalDetails?.siteVisitOnly,
      activityId: generalDetails.activityId,
      projectId: generalDetails.projectId,
      wbsObjectId: generalDetails.wbsObjectId,
      attributes: generalDetails.attributes,
      dgsValue: generalDetails.dgsValue,
      lotiValue: generalDetails.lotiValue,
      wbsElement: generalDetails.wbsElement,
      wbsDescription: generalDetails.wbsDescription,
      flocPlatform: generalDetails.flocPlatform,
      activityOwner: generalDetails.activityOwner,
      twoWeeksFrozenValidated: generalDetails.twoWeeksFrozenValidated,
    };
  }

  resetForm() {
    this.data = undefined;
    this.initForm();
  }
}
