import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "./custom-date-parser-formatter";

@Component({
  selector: "jbid-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DatePickerComponent,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, Validator {
  private static dateParserFormatter = new CustomDateParserFormatter();

  @ViewChild("ngbInputDatepicker", { static: true, read: NgbInputDatepicker }) ngbInputDatepicker!: NgbInputDatepicker;
  @Input() disableRule = false;
  @Input() containerBody = false;
  @Input() isModeReadOnly$: Observable<boolean> = of(false);
  @Input() public internalInputId: string | undefined;
  isModeReadOnly = false;
  model!: string;
  disabled = false;
  private _ngControl!: NgControl;
  private _onChange = (_: any) => undefined;
  private _onTouched = () => undefined;

  constructor(private inj: Injector) {}

  ngOnInit(): void {
    this._ngControl = this.inj.get(NgControl);

    this.internalInputId = this.internalInputId ?? this._ngControl.name + "-input";

    this.isModeReadOnly$.subscribe((data) => (this.isModeReadOnly = data));
    this.ngbInputDatepicker.container = this.containerBody ? "body" : "-";
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.model = obj;
  }

  toggleDatePicker(ngbInput: any) {
    if (!this.disableRule) {
      ngbInput.toggle();
    }
  }

  get ngControl(): NgControl {
    return this._ngControl;
  }

  onDateChange(date: any) {
    this._onChange(date);
    this._onTouched();
  }

  validate(control: AbstractControl<string, string>): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const date = new Date(value);
    if (!isNaN(date.getTime()) && DatePickerComponent.dateParserFormatter.parse(date.toString())) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }
}
