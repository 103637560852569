import gql from "graphql-tag";

const JOB_CARD_CREATION_MUTATION = gql`
  mutation jobCardCreation($affiliate: String, $input: JobCardCreationInput!) {
    createJobCard(affiliate: $affiliate, input: $input) {
      jobCardBasicInfoDTO {
        id
      }
    }
  }
`;

export { JOB_CARD_CREATION_MUTATION };
