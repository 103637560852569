import { Injectable } from '@angular/core';
import { DetailFormService } from '../detail-form.service';
import { UntypedFormBuilder } from '@angular/forms';
import { MobilizationPrerequisites, MobilizationPrerequisitesInput } from '@shared/models/prerequisites/mobilization-prerequisites.model';
import { MobilizationComponent } from './mobilization.component';

@Injectable({
  providedIn: 'root'
})
export class MobilizationFormService extends DetailFormService {

  defaultValueSet = false;
  defaultValue = MobilizationComponent.NOT_STARTED;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  createForm(
    mobilizationsPrerequisites: MobilizationPrerequisites,
    isModeReadOnly?: boolean
  ) {

    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = mobilizationsPrerequisites;
      this.form = this.fb.group({
        id: [mobilizationsPrerequisites.id],
        bookingGlobalStatus: [mobilizationsPrerequisites.bookingGlobalStatus === 'NOT_SET' ? this.defaultValue : mobilizationsPrerequisites.bookingGlobalStatus, []],
        offshoreOnshoreCertifications: [mobilizationsPrerequisites.offshoreOnshoreCertifications || false],
        medicalSpecificTraining: [mobilizationsPrerequisites.medicalSpecificTraining || false],
        hseSiteOnboardingTraining: [mobilizationsPrerequisites.hseSiteOnboardingTraining || false],
      });
    }
    return this.form;
  }

  setDefaultBookingStatusValue(defaultBookingStatusValue: string): void {
    this.defaultValue = defaultBookingStatusValue;
    if (!this.defaultValueSet) {
      this.getControl("bookingGlobalStatus")?.setValue(defaultBookingStatusValue);
      this.defaultValueSet = true;
    }
  }

  getFormData(mobilizationsPrerequisites: MobilizationPrerequisites): MobilizationPrerequisitesInput | null {
    if (!this.form)
      return this.copy(mobilizationsPrerequisites);

    const formRawValue = this.form.getRawValue();
    return this.copy(formRawValue);
  }

  private copy(mobilizationsPrerequisites: MobilizationPrerequisites): MobilizationPrerequisitesInput {
    return {
      id: mobilizationsPrerequisites.id,
      bookingGlobalStatus: mobilizationsPrerequisites.bookingGlobalStatus,
      offshoreOnshoreCertifications: mobilizationsPrerequisites.offshoreOnshoreCertifications,
      medicalSpecificTraining: mobilizationsPrerequisites.medicalSpecificTraining,
      hseSiteOnboardingTraining: mobilizationsPrerequisites.hseSiteOnboardingTraining,
    };
  }

}
