import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {AuthService} from "../auth/auth.service";
import {Affiliate, AffiliateConfig} from "@shared/models/affiliate.model";
import {Apollo} from "apollo-angular";
import {GET_AFFILIATES} from "@graphql/affiliates/affiliates.gql";
import {GET_AFFILIATE_CONFIG} from "@graphql/affiliates/affiliate-config.gql";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  readonly DEFAULT_JOB_CARD_LIST_COLUMNS = [
    'jobTitle',
    'jobCardMasterTitle',
    'workOrderNumber',
    'sitePlatform',
    'actualStatus',
    'startDate',
    'functionalLocationDescription',
    'creationDate'
  ];
  userName$: Observable<string> = this.authService.userName$;
  readonly defaultConfig: AffiliateConfig = {
    name: '',
    workOrderDigits: Number.MAX_VALUE,
    quantumEnabled: false,
    priorities: [],
    quantumPlatformMapping: [],
    mobilizationEnabled: false,
    translationEnabled: false,
  };
  readonly defaultAffiliate: Affiliate = {name: '', value: ''};
  private _affiliate: Affiliate = this.defaultAffiliate;
  private _affiliateConfig: AffiliateConfig = this.defaultConfig;
  private _affiliate$: BehaviorSubject<Affiliate> = new BehaviorSubject<Affiliate>(this.defaultAffiliate);
  private _affiliateConfig$: BehaviorSubject<AffiliateConfig> = new BehaviorSubject<AffiliateConfig>(this.defaultConfig);
  private _noAffiliates: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  affiliate$ = this._affiliate$.asObservable();
  affiliateConfig$ = this._affiliateConfig$.asObservable();
  affiliates$: BehaviorSubject<Affiliate[]> = new BehaviorSubject<Affiliate[]>([]);
  noAffiliates$: Observable<boolean> = this._noAffiliates.asObservable();


  constructor(private authService: AuthService, private apollo: Apollo) {
    this.apollo
      .query<any>({
        query: GET_AFFILIATES
      }).subscribe(
      ({data}) => {
        const affiliates: Affiliate[] = [...data.getAffiliates];
        affiliates.sort((a: Affiliate, b: Affiliate) => (a.name > b.name ? 1 : -1));
        this._noAffiliates.next(affiliates.length === 0);
        this.changeAffiliate(affiliates[0]);
        this.affiliates$.next(affiliates);
      });
  }

  changeAffiliate(affiliate: Affiliate) {
    if (affiliate.value !== this._affiliate.value) {
      this._affiliate = affiliate;
      this._affiliate$.next(this._affiliate);
      this.apollo.query<any>({
        query: GET_AFFILIATE_CONFIG,
        variables: {affiliate: affiliate.value}
      }).subscribe(({data}) => {
        const config = data.getAffiliateConfig;
        this._affiliateConfig = config;
        this._affiliateConfig$.next(config);
      });
    }
  }

  changeAffiliateFromURL(affiliate: string) {
    const affiliates = this.affiliates$.getValue();
    const affiliateAllowed = affiliates.find(a => a.value === affiliate);
    if (affiliateAllowed) {
      this.changeAffiliate(affiliateAllowed);
    }
  }

  getAffiliate(): Affiliate {
    return this._affiliate;
  }

  getAffiliateConfig(): AffiliateConfig {
    return this._affiliateConfig;
  }

  logout() {
    this.authService.logout();
  }

  getColumnConfiguration(): string[] {
    const columns = localStorage.getItem("jobCardListColumns");
    return columns ? JSON.parse(columns) : this.DEFAULT_JOB_CARD_LIST_COLUMNS;
  }

  saveColumnConfiguration(columns: string[]) {
    localStorage.setItem("jobCardListColumns", JSON.stringify(columns));
  }
}
