import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subject} from "rxjs";

import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from "rxjs/operators";
import {
  JobCardLinkOperationContext,
  JobCardMasterService,
} from "@shared/services/job-card-master/job-card-master.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {ProfileService} from "@shared/services";

@Component({
  selector: "jbid-link-job-card-to-jcm",
  templateUrl: "./link-job-card-to-job-card-master.component.html",
  styleUrls: ["./link-job-card-to-job-card-master.component.scss"],
})
export class LinkJobCardToJobCardMasterComponent implements OnInit {
  formGroup: FormGroup<{ jobCardMaster: FormControl<string | null> }> = new FormGroup({
    jobCardMaster: new FormControl<string | null>(null, [Validators.required]),
  });
  private destroy = new Subject<void>();
  jobCardMasters: { title: string; id: string }[] = [];
  linkContext!: JobCardLinkOperationContext;
  search = (text$: Observable<string>) =>
    text$.pipe(debounceTime(200), distinctUntilChanged(), map((term: string) =>
      term === ""
        ? this.jobCardMasters.map((v) => v.title)
        : this.jobCardMasters
          .filter((v) => v.title.toLowerCase().indexOf(term.toLowerCase()) > -1)
          .slice(0, 10)
          .map((v) => v.title),
    ));

  constructor(
    private jobCardMasterService: JobCardMasterService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.jobCardMasterService
      .getJobCardMasterLinkedAllowed()
      .pipe(filter(Boolean), takeUntil(this.destroy))
      .subscribe((result) => {
        this.jobCardMasters = result.map((jcm) => ({ id: String(jcm.id), title: jcm.title }));
        this.preselectJobCardMaster();
      });
    this.jobCardMasterService.linkContext$.pipe(takeUntil(this.destroy)).subscribe((linkContext) => {
      this.linkContext = linkContext;
      this.preselectJobCardMaster();
    });
    this.formGroup.controls.jobCardMaster.valueChanges
      .pipe(
        takeUntil(this.destroy),
        filter((value) => !!value),
      )
      .subscribe((value) => {
        this.jobCardMasterService.updateLinkDestination(this.jobCardMasters.find((jcm) => jcm.title === value) || null);
      });
  }

  onSubmit() {
    this.jobCardMasterService.linkJobCardsToJobCardMaster().subscribe((result) => {
      if (result) {
        this.router.navigate([
          "/job-card-master/detail/" +
            this.profileService.getAffiliate().value +
            "/" +
            this.linkContext.linkDestinationJobCardMaster?.id +
            "/general",
        ]);
        this.jobCardMasterService.activeLinkMode(false);
        this.activeModal.close(result);
      }
    });
  }

  private preselectJobCardMaster() {
    if (this.formGroup?.controls?.jobCardMaster?.value) {
      return;
    }
    const preselecteId = this.linkContext?.linkDestinationJobCardMaster?.id;
    if (preselecteId && (this.jobCardMasters || []).find((jcm) => jcm.id === preselecteId)) {
      this.formGroup.controls.jobCardMaster.setValue(preselecteId || "");
    }
  }
}
