import gql from "graphql-tag";

const CHECK_WORK_ORDER_JOB_CARD_CREATION_QUERY = gql`
  query ($workOrderNumber: Float, $affiliate: String) {
    findJobCardAndWorkOrderLink(affiliate: $affiliate, workOrderNumber: $workOrderNumber) {
      id
      jobTitle
      workOrderNumber
      workOrderDescription
      sitePlatform
      metier
      disciplineCategory
      discipline
      flocPlatform
    }
  }
`;

export { CHECK_WORK_ORDER_JOB_CARD_CREATION_QUERY };
