import { ICellRendererComp, ICellRendererParams } from "@ag-grid-community/core";

export class DaysCockpitRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement("span");
    this.eGui.setAttribute("id", "cockpit");
    this.eGui.style.cssText = "display:flex;";
    this.eGui.innerHTML = "";
    if (params.value > 0) {
      this.eGui.innerHTML = `<span class="material-icons" style="color: red; font-size: 20px;padding-top: 9px; padding-right: 5px">warning</span><label style="color:red;">+${params.value} </label>`;
    }
    if (params.value <= 0) {
      this.eGui.innerHTML = `<label style="color:green;">${params.value} </label>`;
    }
    if(params.value == null){
      this.eGui.innerHTML = "";
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams): boolean {
    return false;
  }
}
