import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jbid-pending-activation-modal',
  templateUrl: './pending-activation-modal.component.html',
  styleUrls: ['./pending-activation-modal.component.css']
})
export class PendingActivationModalComponent {

  constructor(private activeModal: NgbActiveModal) { }

  onConfirm(): void {
    this.activeModal.dismiss();
  }

}
