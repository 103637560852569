import {Component, Input} from '@angular/core';
import {Classification, ClassificationLabels} from "@shared/services/job-card-master/job-card-master.service";

@Component({
  selector: 'jbid-classification-card',
  templateUrl: './classification-card.component.html',
  styleUrls: ['./classification-card.component.css']
})
export class ClassificationCardComponent {
  @Input() classification: Classification | undefined;

  getLabelKey(label: string): string {
    switch (label) {
      case ClassificationLabels.DRIVER:
        return 'jobCardClassifications.driver';
      case ClassificationLabels.BUILDING_BLOCK:
        return 'jobCardClassifications.buildingBlock';
      case ClassificationLabels.OPPORTUNITY:
        return 'jobCardClassifications.opportunity';
      case ClassificationLabels.NOT_DEFINED:
        return 'jobCardClassifications.notDefined';
      case "Job Cards linked":
        return 'jobCardClassifications.linkedJobCards';
      default:
        return 'jobCardClassifications.unknown';
    }

  }
}
