import {Injectable} from "@angular/core";
import {Adapter} from "@shared/models";

export interface SearchFilters {
    senderName?: string | null;
    messageObject?: string | null;
    dateFrom?: string | null;
    dateTo?: string | null;
    prerequisiteTags?: string[] | null;
}

export interface SearchCriteria {
    terms?: string;
    filters?: SearchFilters;
}

export interface Discussion {
    id?: number;
    jobCardId?: string;
    jobCardTitle?: string;
    senderName?: string;
    messageObject?: string;
    dateCreated?: Date;
    prerequisiteTags?: string[];
    messageContent?: string;
    read?: boolean;
    favorite?: boolean;
    archive?: boolean;
    delete?: boolean;
    deleteTime?: Date;
    lastModified?: Date;
}

@Injectable({
    providedIn: "root",
})
export class DiscussionToDtoAdapter implements Adapter<any> {
    adapt(item: Discussion) {
        return {
            messageObject: item.messageObject,
            prerequisiteTags: item.prerequisiteTags?.join("/"),
            messageContent: item.messageContent,
        };
    }
}

@Injectable({
    providedIn: "root",
})
export class DiscussionFromDtoAdapterForDisplay implements Adapter<Discussion> {
    adapt(item: any) {
        const valuesToNames = (tags: string[]) =>
            tags.map((tag: string) => aPrerequisiteTags.find((element) => element.value === tag)?.name || undefined);

        return {
            id: item.id,
            jobCardId: item.jobCardId,
            jobCardTitle: item.jobCardTitle,
            senderName: item.senderName,
            messageObject: item.messageObject,
            dateCreated: item.dateCreated,
            lastModified: item.lastModified,
            prerequisiteTags: valuesToNames(item.prerequisiteTags?.split(/,|\//) || []),
            messageContent: item.messageContent,
            deleted: item.deleted,
            deleteTime: item.deleteTime,
        } as Discussion;
    }
}

export const aPrerequisiteTags = [
    // General details
    {name: "Job card description", value: "jobCardDescription", category: "General details"},
    {name: "Functional location", value: "functionalLocation", category: "General details"},
    {name: "Revision code", value: "revisionCode", category: "General details"},
    {name: "Discipline", value: "discipline", category: "General details"},
    {name: "Activity type", value: "activityType", category: "General details"},
    {name: "Job Leader", value: "jobLeader", category: "General details"},
    {name: "Notification number", value: "notificationNumber", category: "General details"},
    {name: "Work order number", value: "workOrderNumber", category: "General details"},
    {name: "Job estimated cost", value: "jobEstimatedCost", category: "General details"},
    {name: "Site visit only", value: "siteVisitOnly", category: "General details"},
    {name: "SECE", value: "sece", category: "General details"},
    {name: "DGS", value: "dgs", category: "General details"},
    {name: "LOTI", value: "loti", category: "General details"},
    {name: "Start Date", value: "startDate", category: "General details"},
    {name: "End Date", value: "endDate", category: "General details"},
    {name: "Priority", value: "priority", category: "General details"},
    {name: "Shift type", value: "shiftType", category: "General details"},
    {name: "POB", value: "pob", category: "General details"},

    // TODO: Merge with PREREQUISITES_SECTION declared in prerequisites-api.service ???

    // Prerequisites
    // Job scope
    {name: "Method statement", value: "methStatement", category: "Job scope"},
    {name: "SOW tech. pres. meeting required", value: "sowTechnical", category: "Job scope"},
    {name: "KOM & JRA", value: "komJra", category: "Job scope"},
    {name: "RFM", value: "rfmNumber", category: "Job scope"},
    {name: "Well intervention", value: "wellIntervention", category: "Job scope"},

    // Impact
    {name: "SIMOPS", value: "simops", category: "Impact"},
    {name: "Shortfall impact", value: "shortfall", category: "Impact"},
    {name: "Permit to work", value: "permitToWork", category: "Impact"},

    // Material
    {name: "Material number", value: "materialNumber", category: "Material"},
    {name: "Material description", value: "materialDescription", category: "Material"},

    // Resources
    {name: "Scaffolding", value: "scaffolding", category: "Resources"},
    {name: "Lifting", value: "lifting", category: "Resources"},
    {name: "Temporary equipment", value: "temporaryEquipment", category: "Resources"},
    {name: "Isolation", value: "isolation", category: "Resources"},
    {name: "Inhibition", value: "inhibition", category: "Resources"},
    {name: "Core crew assistance", value: "coreCrewAssistance", category: "Resources"},
    {name: "Contractor assistance", value: "contractorAssistance", category: "Resources"},
    {name: "Specific logistic", value: "specificLogistic", category: "Resources"},
    {name: "Invitation", value: "invitation", category: "Resources"},

    // Mobilization
    {name: "Resources management", value: "ResourcesManagement", category: "Mobilization"},
    {name: "Offshore & Onshore Certifications", value: "OffshoreOnshoreCertifications", category: "Mobilization"},

];
