import { Injectable } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { finalize, tap } from "rxjs/operators";
import { InsightsService } from "@shared/services";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private appInsights: InsightsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const started = Date.now();
    let ok: string;

    // extend server response observable with logging
    return next.handle(req).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        (event) => (ok = event instanceof HttpResponse ? "succeeded" : ""),
        // Operation failed; error is an HttpErrorResponse
        () => (ok = "failed"),
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
        this.appInsights.logEvent(`LoggingInterceptor ${msg}`);
      }),
    );
  }
}

export const loggingInterceptorProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoggingInterceptor,
    multi: true,
  },
];
