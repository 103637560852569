import { Directive, ElementRef, Input } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Directive({
  selector: "form[formSubmit]",
})
export class AppFormSubmitDirective {
  private _formSubmitted$ = new BehaviorSubject({ submitted: false });
  submitted$ = this._formSubmitted$.asObservable().pipe(shareReplay(1));

  @Input() isGeneralStatusDraft = false;

  @Input() set formSubmit(state: any){
    if (state.submitted) {
      this.formGroup.ngSubmit.emit();
      (this.formGroup as any).submitted = true;

      this.formGroup.form?.markAllAsTouched();
      this.formGroup.form?.updateValueAndValidity({ onlySelf: true });
    }
    this._formSubmitted$.next(Object.assign({}, state));
  }

  get element(){
    return this.host.nativeElement;
  }

  constructor(private formGroup: FormGroupDirective, private host: ElementRef<HTMLFormElement>){}
}
