export interface Isolation {
  id: number | undefined;
  description: string;
  status: IsolationStatus;
  isolationType: IsolationType;
  displayed: boolean;
  jobCardId: number;
  readiness: string | undefined;
}

export interface IsolationInput {
  id: number | undefined;
  description: string;
  status: IsolationStatus;
  isolationType: IsolationType;
  displayed: boolean;
  jobCardId: number;
}

export enum IsolationStatus {
  NOT_STARTED = "Not Started",
  PENDING = "Pending",
  VALIDATED = "Validated",
}

export enum IsolationType {
  PROCESS = "Process",
  ELECTRICAL = "Electrical",
  MECHANICAL = "Mechanical",
}
