import gql from "graphql-tag";

const GET_JOB_CARD_ORGANIZATION = gql`
  query ($affiliate: String, $id: Int) {
    fetchAllJobCardsOrganization(affiliate: $affiliate, masterId: $id) {
      id
      jobTitle
      masterClassification
      generalStatus
      dependencies {
        id
        jobTitle
        masterClassification
      }
    }
  }
`;

export { GET_JOB_CARD_ORGANIZATION };
