 <div class="card text-bg-light">
  <div class="card-body d-flex">
    <span class="material-icons-outlined md-36 text-primary pe-3">lightbulb</span>
    <div>
      <h3 class="card-title pb-2">{{ 'generalDetails.workOrderPrerequisitesPopup.title' | translate }}</h3>
      <p class="card-text">{{ 'generalDetails.workOrderPrerequisitesPopup.message' | translate }}</p>
      <ul>
        <li *ngFor="let prerequisite of prerequisites">{{ prerequisite }}</li>
      </ul>
      <p class="card-text">
        <strong>{{ 'generalDetails.workOrderPrerequisitesPopup.question' | translate }}</strong>
        <em>{{ 'generalDetails.workOrderPrerequisitesPopup.explanation' | translate }}</em>
      </p>
    </div>
  </div>
  <div class="card-footer d-flex flex-row-reverse text-bg-light">
    <button class="btn btn-primary" type="button" (click)="onYesClick()">{{ 'generalDetails.workOrderPrerequisitesPopup.yes' | translate }}</button>
    <button class="btn btn-secondary me-3" type="button" (click)="onNoClick()">{{ 'generalDetails.workOrderPrerequisitesPopup.no' | translate }}</button>
  </div>
 </div>
