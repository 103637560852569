import gql from "graphql-tag";

const GET_AFFILIATE_CONFIG = gql`
  query getAffiliateConfig($affiliate: String) {
    getAffiliateConfig(affiliate: $affiliate) {
      name
      workOrderDigits
      quantumEnabled
      priorities
      quantumPlatformMapping {
        key
        value
      }
      mobilizationEnabled
      translationEnabled
    }
  }
`;

export {GET_AFFILIATE_CONFIG};
