import gql from "graphql-tag";

const GET_JOB_CARD_HISTORY_PAGE = gql`
  query (
    $affiliate: String,
    $jobCardId: Int,
    $filter: JobCardTrackingFilter
  ) {
    getJobCardHistoryPage (
      affiliate: $affiliate,
      jobCardId: $jobCardId,
      filter: $filter
    ) {
      data {
        id
        jobCardId
        affiliate
        section
        field
        author
        modificationDate
      }
      totalPages
      currentPage
      totalItems
    }
  }

`;

export {GET_JOB_CARD_HISTORY_PAGE};
