import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileService } from '@shared/services';
import { GET_REVISION_CODE_BY_AFFILIATE_QUERY } from '@graphql/jobid-card/detail/general-details/queries/revision-code.gql';

@Injectable({
  providedIn: 'root'
})
export class RevisionCodeService {

  private _revisionCodes$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  revisionCodes$: Observable<string[]> = this._revisionCodes$.asObservable();

  constructor(private profileService: ProfileService, private apollo: Apollo) {
    this.profileService.affiliate$.subscribe({ next: affiliate => {
      this.apollo
      .query<any>({
        query: GET_REVISION_CODE_BY_AFFILIATE_QUERY,
        variables: {
          affiliate: affiliate.value,
        },
      }).subscribe(({ data }) => {
        const revisionCodes = data.getRevisionCodesByAffiliate;
        this._revisionCodes$.next(revisionCodes);
      });
    } });
  }
}
