<div class="input-group mr-2">
  <input
    [ngClass]="{
          'is-invalid' : ngControl.invalid,
          'form-control': !isModeReadOnly,
          'calendar-icon': !isModeReadOnly,
          'form-control-plaintext': isModeReadOnly
        }"
    [readOnly]="isModeReadOnly"
    [disabled]="disableRule"
    class="form-control"
    name="dateInput"
    #ngm="ngModel"
    [(ngModel)]="model"
    (ngModelChange)="onDateChange($event)"
    (click)="toggleDatePicker(ngbInputDatepicker)"
    ngbDatepicker
    #ngbInputDatepicker="ngbDatepicker"
    id="{{internalInputId}}"
    placeholder="{{placeholder}}"
  />
</div>
