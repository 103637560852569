<mat-card class="impact-card">
  <div style="display: flex">
    <mat-icon [inline]="true" class="title-icon">graphic_eq</mat-icon>
    <h5 class="card-title">Job Cards KPIs</h5>
  </div>
  <div class="horizontal-bar mb-3"></div>

  <div class="row flex-fill ms-0 ps-0">
    <div class="col-3 mt-1 ms-0 ps-0 d-flex flex-column">
      <jbid-classification-card [classification]="totalCounts">
      </jbid-classification-card>
    </div>
    <div class="col-1 mt-1 ms-0 ps-0 d-flex flex-column"></div>
    <div
      *ngFor="let cl of classifications"
      class="col-2 mt-1 ms-0 ps-0 d-flex flex-column">
      <jbid-classification-card [classification]="cl">
      </jbid-classification-card>
    </div>
  </div>
  <div class="d-flex flex-row-reverse bd-highlight legend">
      <span>Draft</span>
      <mat-icon [inline]="true" class="legend-icon draft">stop</mat-icon>
      <span class="legend-title">Ready to Activate</span>
      <mat-icon [inline]="true" class="legend-icon ready-to-activate">stop</mat-icon>
      <span class="legend-title">Delayed</span>
      <mat-icon [inline]="true" class="legend-icon delayed">stop</mat-icon>
      <span class="legend-title">Awaiting Preparation</span>
      <mat-icon [inline]="true" class="legend-icon awaiting-preparation">stop</mat-icon>
      <span class="legend-title">Ready</span>
      <mat-icon [inline]="true" class="legend-icon ready">stop</mat-icon>
  </div>
</mat-card>
