import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";

export class DetailFormService {
  private _submittedState = { submitted: false };

  isSubmitted = this.submittedState.submitted;
  form!: UntypedFormGroup;
  data?: any;
  destroy = new Subject();

  progressBarValue = 0;

  isModeReadOnly = false;

  get submittedState() {
    return this._submittedState;
  }

  set submittedState(newState) {
    this._submittedState = newState;
    this.isSubmitted = this.submittedState.submitted;
  }

  getControl(field: string) {
    return this.form?.get(field);
  }

  private getControlName(control: AbstractControl | null | undefined): string | null {
    return Object.entries(control?.parent?.controls ?? []).find(([_, value]) => value === control)?.[0] ?? null;
  }

  copyDocument(document: DocumentDTO): DocumentDTO {
    return {
      id: document.id,
      type: document.type,
      link: document.link,
      name: document.name,
      section: document.section,
      sectionIndex: document.sectionIndex
    }
  }
}
