import { NgModule } from "@angular/core";
import { CommonModule, DatePipe, TitleCasePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { OrderModule } from "ngx-order-pipe";
import { AppFormRequiredInformationComponent } from "./components/forms/app-form-required-information/app-form-required-information.component";
import { AppDisabledControlDirective } from "./directives/app-disabled-control/app-disabled-control.directive";
import { AppYesNoModalComponent } from "./components/modal/app-yes-no-modal/app-yes-no-modal.component";
import { AppFormSubmitDirective } from "./directives/app-form-submit/app-form-submit.directive";
import {
  AppFormControlErrorDirective,
  ControlErrorAnchorDirective,
  JbidErrorAnchorDirective,
} from "@shared/directives/app-form-control-error/app-form-control-error.directive";
import { AppFormControlErrorDisplayComponent } from "@shared/components/forms/app-form-control-error-display/app-form-control-error-display.component";
import { TimelineComponent } from "@routes/jobid-card/detail/timeline/timeline.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { OverlayModule } from "@angular/cdk/overlay";
import { ReadinessComponent } from "@routes/jobid-card/detail/prerequisites/readiness/readiness.component";
import { DatePickerModule } from "@shared/components/date-picker/date-picker.module";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { JcmImpactComponent } from "@routes/job-card-master/detail/jcm-impact/jcm-impact.component";
import { AgChartsAngularModule } from "ag-charts-angular";
import { JcmClassificationComponent } from "@routes/job-card-master/detail/jcm-classification/jcm-classification.component";
import { LinkJobCardToJobCardMasterByRevcodeComponent } from "./components/modal/link-job-card-to-job-card-master-by-revcode/link-job-card-to-job-card-master-by-revcode.component";
import { MatTreeModule } from "@angular/material/tree";
import { ClassificationCardComponent } from "./components/classification-card/classification-card.component";
import { TranslateModule } from "@ngx-translate/core";
import { AddWorkOrderPrerequisitesComponent } from "./components/modal/add-work-order-prerequisites/add-work-order-prerequisites.component";
import { MatButtonModule } from "@angular/material/button";

const MODULES: any[] = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  NgSelectModule,
  OrderModule,
  AgGridModule,
  MatCardModule,
  MatGridListModule,
  MatDividerModule,
  MatIconModule,
  MatProgressBarModule,
  MatTreeModule,
  MatButtonModule,
  OverlayModule,
  DatePickerModule,
  MatButtonToggleModule,
  AgChartsAngularModule,
  TranslateModule,
];

const COMPONENTS: any[] = [
  AppFormRequiredInformationComponent,
  AppYesNoModalComponent,
  AppFormControlErrorDisplayComponent,
  AddWorkOrderPrerequisitesComponent,
  TimelineComponent,
  ReadinessComponent,
  JcmImpactComponent,
  JcmClassificationComponent,
  ClassificationCardComponent,
];

const DIRECTIVES: any[] = [
  AppDisabledControlDirective,
  AppFormSubmitDirective,
  AppFormControlErrorDirective,
  ControlErrorAnchorDirective,
  JbidErrorAnchorDirective,
];

const PIPES = [DatePipe, TitleCasePipe];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES, LinkJobCardToJobCardMasterByRevcodeComponent],
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
  providers: [...PIPES],
})
export class SharedModule {}
