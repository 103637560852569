import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "@shared/services";
import { Subject } from "rxjs";

@Component({
  selector: "jbid-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.redirectAuthenticatedUserToHome();
  }

  login() {
    this.authService.login();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
