import gql from "graphql-tag";

const GET_JOB_CARD_MEETING_BOARD = gql`
  query ($affiliate: String, $site: String, $actualStatusList: [String]){
    searchForMeetingBoard (affiliate: $affiliate, site: $site, actualStatusList: $actualStatusList) {
      id
      jobTitle
      workOrderNumber
      priority
      sitePlatform
      generalStatus
      actualStatus
      startDate
      endDate
      owner
      discipline
      revisionCode
      masterNotification
      workOrderUserStatus
      workOrderSystemStatus
      functionalLocation
      attributes
      workOrderType
      maximumPob
      fromSap
      potentialReadiness
      creationDate
      jobCardMasterTitle
      jobCardMasterId
      jobLeader
      days
      isBreakout
    }
}
`;

export { GET_JOB_CARD_MEETING_BOARD };
