<div class="d-flex flex-column min-vh-100 w-100">
  <!-- HEADER -->
  <nav class="navbar navbar-expand-sm navbar-light flex-column">
    <jbid-header></jbid-header>
  </nav>

  <!-- MAIN CONTENT -->
  <main class="main-content-scrollable p-3">
    <router-outlet></router-outlet>
  </main>
</div>

