
  <div class="modal-header">
    <div class="d-flex flex-row modal-title">
      <span class="material-icons-outlined text-primary" aria-hidden="true">error_outline</span>
      <h5 class="modal-title">Massive Link</h5>
    </div>
    <button type="button" class="btn btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>

  <ng-container [ngSwitch]="[status]">
    <ng-template *ngSwitchCase="'input'" [ngTemplateOutlet]="input" ></ng-template>
    <ng-template *ngSwitchCase="'loading'" [ngTemplateOutlet]="loading" ></ng-template>
    <ng-template *ngSwitchCase="'result'" [ngTemplateOutlet]="result" ></ng-template>
  </ng-container>
  <ng-template #input>
    <form *ngIf="formGroup" [formGroup]="formGroup" #formRef="ngForm" (ngSubmit)="onSubmit()"
          [ngClass]="{ 'needs-validation': formRef.submitted }">
    <div class="modal-body">
    <div class="container" >
      <label class="form-label">Revision Code</label>
      <span class="form-placeholder"> (please enter the revision code) </span>
      <input id="revisionCodeInput" type="text" formControlName="revisionCode"
             class="form-control"
             [ngbTypeahead]="searchRevisionCode"
             [ngClass]="{ 'is-invalid': formGroup.invalid}"/>
    </div>
  </div>
    <div class="modal-footer" style="gap: 0">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">
      <span class="material-icons" aria-hidden="true">close</span>
     Close
    </button>
    <button type="submit" id="link-by-revision-code-submit" class="btn btn-primary" [disabled]="formGroup.invalid">
      <span class="material-icons" aria-hidden="true">done</span>
      Validate
    </button>

  </div>
    </form>

  </ng-template>
  <ng-template #loading>
    <div class="modal-body">
        <div class="card mb-3">
          <div class="card-body">
            <span class="spinner-border text-primary" role="status"></span>
            ... Job Cards Linked
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="spinner-border text-primary" role="status"></span>
            ... Job Cards Link Fails
          </div>
        </div>
    </div>
    <div class="modal-footer" style="gap: 0">

      <button type="button" class="btn btn-secondary" [disabled]="true" >
        <span class="material-icons" aria-hidden="true">close</span>
        Close
      </button>
      <button type="button" class="btn btn-secondary" id="link-by-revision-code-view-report-loading" [disabled]="true" >
        View the Report
      </button>
    </div>
  </ng-template>

  <ng-template #result>
    <div class="modal-body">
      <p id="link-by-revcode-success-message" *ngIf="!massiveLinkReportError" [innerHTML]="'Completed Process'" class="card-text text-success form-info"></p>
      <jbid-form-error-display id="link-by-revcode-error-message" *ngIf="massiveLinkReportError" [text]="'Error Running process: '+massiveLinkReportError"></jbid-form-error-display>
      <div class="card mb-3" >
        <div class="card-body" id="link-by-revcode-total-success">
          <span class="material-icons-outlined text-primary text-success" aria-hidden="true">check_circle</span>
          {{(massiveLinkReport?.successCount??0) + '/' +
        ((massiveLinkReport?.successCount??0)+(massiveLinkReport?.errorCount??0)) + ' Job Cards Linked'}}
        </div>
      </div>
      <div class="card">
        <div class="card-body" id="link-by-revcode-total-error">
          <span class="material-icons-outlined text-primary" aria-hidden="true">error_outline</span>
          {{(massiveLinkReport?.errorCount??0) + ' Job Cards Link Fails'}}
        </div>
      </div>
    </div>
    <div class="modal-footer" style="gap: 0">

      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">
        <span class="material-icons" aria-hidden="true">close</span>
        Close
      </button>
      <button type="button" class="btn btn-secondary" id="link-by-revision-code-view-report"
              (click)="redirectToReport()"
              [disabled]="massiveLinkReportError"  >
        View the Report
      </button>
    </div>
  </ng-template>
