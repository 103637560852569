import gql from "graphql-tag";

const FREEZE_JCM = gql`
  mutation ($affiliate: String, $id: Int) {
    freezeJobCardMasterScope(affiliate: $affiliate, id: $id) {
      id
      title
      generalStatus
      actualStatus
      potentialReadiness
      owner
      mainSite
      affiliate
      isScopeFrozen
    }
  }
`;

export { FREEZE_JCM };
