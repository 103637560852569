export enum MaterialGlobalStatus {
  N_A = "N/A",
  NOT_RECEIVED = "Not received",
  PARTIALLY_RECEIVED = "Partially received",
  RECEIVED = "Received",
}
export const mMaterialGlobalStatus: ReadonlyMap<string, string> = new Map(Object.entries(MaterialGlobalStatus));

export enum MaterialProcurementStatus {
  WAITING_DELIVERY = "Waiting delivery",
  IN_STOCK = "In stock",
  IN_TRANSIT = "In transit",
  PO_CREATED = "PO created",
  PO_RELEASED = "PO Released",
  PR_CREATED = "PR created",
  RECEIVED_AT_BASE = "Received at base",
  RECEIVE_AT_SITE = "Received at site",
  RFQ_ON_GOING = "RFQ On-going",
  WO_CREATED = "WO created",
  WO_NOT_RELEASED = "WO not released",
  WO_RELEASED = "WO released",
  WO_CLOSED = "WO Closed",
  CANCELED = "Canceled",
  DELIVERED_TO_WO = "Delivered to WO",
  IN_LITIGATION = "In litigation",
  PR_RELEASED = "PR released",
}
export const mMaterialProcurementStatus: ReadonlyMap<string, string> = new Map(
  Object.entries(MaterialProcurementStatus),
);

export enum MaterialQA_QC_Certificate_Received {
  NOT_REQUIRED = "Not required",
  NOT_PROVIDED = "Not provided",
  PROVIDED = "Provided",
  VALIDATED = "Validated",
}
export const mMaterialQA_QC_Certificate_Received: ReadonlyMap<string, string> = new Map(
  Object.entries(MaterialQA_QC_Certificate_Received),
);

export interface Material {
  affiliate?: string
  eta?: Date
  id?: number
  itemNumber?: string
  jobCardId?: number
  materialDescription?: string
  materialNumber?: number
  materialQuantityRequested?: number
  materialQuantitySite?: number
  procurementStatus?: string
  qaQcCertificate?: string
  sapOrigin?: boolean
  unloadingPoint?: string
  workOrderNumber?: number
  readiness: string;
}

export interface FatDTO {
  id?: number
  displayed?: boolean
  description: string
  plannedDate: string
  status: string
  jobCardId?: number
  readiness: string;
}

export interface MaterialListDTO {
  materialStatus?: MaterialGlobalStatus;
  materials: Material[];
  fatDTO: FatDTO;
}

export interface MaterialListDTOInput {
  materialStatus?: MaterialGlobalStatus;
  materials?: MaterialInput[];
  fatDTO: FatInputDTO;
}

export interface MaterialInput {
  affiliate?: string
  eta?: Date
  id?: number
  itemNumber?: string
  jobCardId?: number
  materialDescription?: string
  materialNumber?: number
  materialQuantityRequested?: number
  materialQuantitySite?: number
  procurementStatus?: string
  qaQcCertificate?: string
  sapOrigin?: boolean
  unloadingPoint?: string
  workOrderNumber?: number
}

export interface FatInputDTO {
  id?: number
  displayed?: boolean;
  description?: string
  plannedDate: string
  status: string
  jobCardId?: number
}
