import gql from "graphql-tag";

const GET_METIER_BY_AFFILIATE_QUERY = gql`
  query ($affiliate: String) {
    getMetiersByAffiliate(affiliate: $affiliate) {
      id
      name
      description
      affiliate
    }
  }
`;

export { GET_METIER_BY_AFFILIATE_QUERY };
