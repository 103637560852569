import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerComponent} from './date-picker.component';
import {CommonModule} from "@angular/common";
import {CustomDateParserFormatter} from "@shared/components/date-picker/custom-date-parser-formatter";

@NgModule({
  imports: [CommonModule, FormsModule, NgbDatepickerModule],
  exports: [DatePickerComponent],
  declarations: [DatePickerComponent],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class DatePickerModule {}
