<div class="modal-header">
    <div class="d-flex flex-row modal-title">
    <span class="material-icons-outlined text-primary" aria-hidden="true">
      {{ iconName }}
    </span>
        <h5 class="modal-title mx-1">{{ title }}</h5>
    </div>
    <button type="button" class="btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span class="material-icons" aria-hidden="true">close</span>
    </button>
</div>
<div class="modal-body">
    <p class="content-style">{{ content }}</p>
    <br>
    <p class="content-style">{{ subContent }}</p>
    <p *ngIf="showModalPreferences">
        <input id="modalPreferences" type="checkbox" [(ngModel)]="toNotShowModalAgain"/>
        <label for="modalPreferences">{{'discussion.doNotShow' | translate}}</label>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" id="cancelButton" (click)="activeModal.close(false)">
        {{ noLabel }}
    </button>
    <button type="button" class="btn btn-primary" id="confirmButton" (click)="confirmAction()">
        {{ yesLabel }}
    </button>
</div>
