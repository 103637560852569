import gql from "graphql-tag";

const GET_COMPUTE_JOBCARD_COUNT_BY_STATUS_QUERY = gql`
  query ($affiliate: String!, $masterId: Int!) {
    getComputedJobCardMasterKpiAggregate(affiliate: $affiliate, masterId: $masterId) {
      overdueDaysCount
      awaitingCount
      draftCount
      readyToActivateCount
      readyCount
      closedCount
      allCount
    }
  }
`;

export {GET_COMPUTE_JOBCARD_COUNT_BY_STATUS_QUERY};
