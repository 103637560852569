import { Component } from "@angular/core";

@Component({
  selector: "jbid-redirect-page",
  templateUrl: "./redirect-page.component.html",
  styleUrls: ["./redirect-page.component.css"],
})
export class RedirectPageComponent {
  authenticationPending = true;
}
