import gql from "graphql-tag";

const GET_JCM_KPIS = gql`
  query ($affiliate: String, $id: Int) {
    getJobCardMasterKPIs(affiliate: $affiliate, masterId: $id) {
      jobCardMasterImpactKPISitePlateform {
        label
        count
      }
      jobCardMasterImpactKPIDisciplineCategory {
        label
        count
      }
      jobCardMasterKPIMasterClassification {
        label
        count
        countReady
        countAwaitingPreparation
        countDelayed
        countReadyToActivate
        countDraft
      }
    }
  }
`;

export { GET_JCM_KPIS };
