import { ErrorHandler, InjectionToken, NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { AgGridModule } from "@ag-grid-community/angular";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from "@azure/msal-angular";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";

import { environment } from "@env/environment";
import { httpInterceptorProviders } from "./interceptors";
import {
  InsightsService,
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from "@shared/services";
import { MainLayoutComponent } from "./components/main-layout/main-layout.component";
import { HeaderComponent } from "./components/header/header.component";
import { AppToastComponent } from "./components/toast/app-toast.component";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { CustomDateParserFormatter } from "@shared/components/forms/datepicker-adapter";
import { LoginComponent } from "./components/login/login.component";
import { RedirectPageComponent } from "./components/login/redirect-page/redirect-page.component";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslationService } from "@shared/services/translation/translation.service";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";

ModuleRegistry.registerModules([ServerSideRowModelModule, ColumnsToolPanelModule]);

export const FORM_ERRORS = new InjectionToken("FORM_ERRORS");

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const insightsProviders = [
  InsightsService,
  { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
];

const msalProviders = [
  MsalGuard,
  MsalService,
  { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
  { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [] },
  { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory, deps: [] },
  { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory, deps: [] },
];

const MODULES = [NgbModule, AgGridModule, NgxSpinnerModule, ToastrModule, MsalModule];

const COMPONENTS = [MainLayoutComponent, HeaderComponent, AppToastComponent, UnauthorizedComponent, LoginComponent];

@NgModule({
  providers: [
    httpInterceptorProviders,
    msalProviders,
    insightsProviders,
    NgbActiveModal,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    {
      provide: FORM_ERRORS,
      useFactory() {
        return {
          // ErrorsProvider
          required: "This field is required",
          // minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`,
          ngbDate: "This field is not a valid date",
          startDateAfterEndDate: "Start date must be before end date",
          dateFromOrDateToNull: 'The fields "dateFrom" and "dateTo" must be either empty or filled',
          validDiscipline: "Incorrect discipline",
          validRevisionCode: "Incorrect revision code",
          wbsElementNotFound: "Wbs not found",
          oneShortFallFieldIsRequired: "At least one shortfall field is required",
        };
      },
      deps: [],
    },
  ],
  declarations: [...COMPONENTS, LoginComponent, RedirectPageComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    AgGridModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      toastComponent: AppToastComponent,
      toastClass: "toast",
      autoDismiss: true,
      closeButton: true,
      tapToDismiss: true,
      maxOpened: 1,
      preventDuplicates: true,
      enableHtml: true,
      iconClasses: {
        error: "report_problem",
        info: "info",
        success: "check_circle",
        warning: "error",
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [...COMPONENTS, ...MODULES],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private appInsights: InsightsService,
    private translation: TranslationService,
  ) {
    const isDebugMode = !environment.production;

    if (isDebugMode) {
      this.appInsights.logEvent("[CoreModule] Dropping into debug mode");
    }

    throwIfAlreadyLoaded(parentModule, "CoreModule");
    this.appInsights.logEvent("[CoreModule] Core Module initialized");
  }
}

// AoT requires an exported function for factories
export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}
