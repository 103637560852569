import {Injectable} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";
import {
  ResourceCustomPrerequisite,
  ResourceCustomPrerequisiteInput
} from "@shared/models/prerequisites/resource-custom-prerequiste.model";
import {AbstractArrayFormService} from "../../abstract-array-form.service";
import filter from "lodash-es/filter";

@Injectable({
  providedIn: "root",
})
export class CustomPrerequisiteFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  get formArrayName() {
    return "items";
  }

  createFormGroup(customPrerequisite?: ResourceCustomPrerequisite, documents?: Map<string, DocumentDTO[]>) {
    return this.fb.group({
      id: [customPrerequisite?.id],
      title: [customPrerequisite?.title],
      description: [customPrerequisite?.description, [Validators.required]],
      status: [customPrerequisite?.status],
      displayed: [customPrerequisite?.displayed, []],
      jobCardId: [this.jobCardId],
      customPrerequisiteDocumentLink: [documents?.get("customPrerequisiteDocumentLink"), []],
    });
  }

  createForm(isModeReadOnly: boolean, customPrerequisites?: ResourceCustomPrerequisite[], documents?: Map<string, DocumentDTO[]>) {
    if(this.form != undefined && !isModeReadOnly){
      return this.form;
    }

    if(isModeReadOnly || this.form == undefined){
      this.data = customPrerequisites;

      const isChecked = (customPrerequisites?.length !== 0 && customPrerequisites?.every((item) => item.displayed)) || false;

      this.form = this.fb.group({
        checked: [{ value: isChecked, disabled: isModeReadOnly }, []],
        items: this.setInitialData(customPrerequisites,documents),
      });
    }
    this.clearFormOnUnchecked()
    return this.form;
  }

  getFormData(customPrerequisites: ResourceCustomPrerequisite[]): ResourceCustomPrerequisiteInput[] {
    if (!customPrerequisites) {
      return [];
    }
    if (!this.form) return customPrerequisites.map((customPrerequisites) => this.copy(customPrerequisites));

    let itemsInput: ResourceCustomPrerequisiteInput[] = [];
    if (this.form?.value && this.form?.value[this.formArrayName] && this.form?.value[this.formArrayName].length !== 0) {
      itemsInput = this.form?.value[this.formArrayName].filter((item: any) => {
        return item.description || item.status;
      }).map((item: ResourceCustomPrerequisite) => {
        const itemInput = this.copy(item);
        itemInput.displayed = this.form.controls.checked.value;
        return itemInput;
      });
    }

    return itemsInput;
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
    if (!documentDTOList && !this.form) {
      return [];
    }

    if(!this.form){
      const filteredDocs :DocumentDTO[] = filter(documentDTOList, {'section': 'CUSTOM_PREREQUISITE'});
      return filteredDocs.map(doc => this.copyDocument(doc));
    }

    const customPrerequisiteDocumentLink: DocumentDTO[] = [];
    this.form?.controls["items"]?.value?.forEach(
      (item: { customPrerequisiteDocumentLink: DocumentDTO[]}) => {
        item.customPrerequisiteDocumentLink?.forEach((doc: DocumentDTO) => customPrerequisiteDocumentLink.push(this.copyDocument(doc)));
      },
    );

    return [customPrerequisiteDocumentLink].reduce((acc, val) => acc.concat(val), []);
  }

  private copy(customPrerequisite: ResourceCustomPrerequisite): ResourceCustomPrerequisiteInput {
    return {
      id: customPrerequisite.id,
      title: customPrerequisite.title,
      description: customPrerequisite.description,
      status: customPrerequisite.status,
      displayed: customPrerequisite.displayed,
      jobCardId: customPrerequisite.jobCardId,
    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }
}
