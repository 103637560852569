<div class="container-fluid px-3 form-info" *ngIf="!mobilizationEnabled">

  <div class="card-body d-flex align-items-center info">
    <span class="material-icons">error</span>
    <span class="ms-2" id="bookingStatus"> {{'mobilization.bookingProcess' | translate}}</span>
  </div>
</div>


<div class="container-fluid px-3">
  <form [formGroup]="mobilizationFormService.form" *ngIf="mobilizationFormService.form" [ngClass]="{
    'needs-validation': !mobilizationFormService.isSubmitted
  }" [formSubmit]="mobilizationFormService.submittedState">
    <div class="card h-100" *ngIf="mobilizationEnabled">
      <div class="card-body">
        <div class="d-flex justify-content-start align-items-center">
          <h5> {{'mobilization.resourcesManagement' | translate}}</h5>
          <div *ngIf="lastScanInfo$ | async" class="ms-4 mb-2 d-flex align-items-center">
              <span class="material-icons me-1">update</span>
              <small id="lastScanInfo"> {{'mobilization.lastUpdateFromDaWinci' | translate}} {{ lastScanInfo$ | async | date: 'dd/MM/yyyy \'at\' HH:mm'}}</small>
          </div>
          <jbid-readiness class="ms-auto pe-4" [isSectionChecked]="true" [readinessText]="mobilizationsPrerequisites?.resourcesManagementReadiness || defaultReadiness"></jbid-readiness>
        </div>
        <div class="card-body">
          <jbid-form-required-information
          [content]="descriptionInformation"
          [visible]="true"
        >
          </jbid-form-required-information>
          <div class="py-3">
            <div class="col-4">
              <div class="form-group w-100">
                <label
                  class="form-label"
                  for="bookingGlobalStatus"
                  [ngClass]="{ required: !readonly }"
                >
                  {{'mobilization.bookingGlobalStatus' | translate}}
                </label>
                <div *ngIf="mobilizationFormService.form?.get('bookingGlobalStatus') || false">
                  <div *ngIf="readonly" class="form-control-plaintext px-2" id="bookingGlobalStatusReadOnly">
                    {{ mobilizationFormService.form?.get("bookingGlobalStatus")?.value.replace('_', ' ') | titlecase }}
                  </div>
                </div>
                <select id="bookingGlobalStatus" *ngIf="!readonly" class="form-select"
                        formControlName="bookingGlobalStatus">
                  <option *ngFor="let status of bookingStatusOptions"
                          [value]="status">
                    {{ status.replace('_', ' ') | titlecase }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <ag-grid-angular
              #agGrid
              class="ag-theme-alpine mobilization-list"
              id="job-cards-list"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [rowModelType]="rowModelType"
              [serverSideInfiniteScroll]="true"
              [pagination]="true"
              [paginationPageSize]="paginationPageSize"
              [cacheBlockSize]="cacheBlockSize"
              [animateRows]="true"
              [rowData]="rowData"
              (gridReady)="onGridReady($event)"
              [domLayout]="'autoHeight'"
            ></ag-grid-angular>
            <div class="d-flex align-items-center mobilization-number-per-page">
              <label for="number-per-page" class="form-label label-number-per-page" id="test"> {{'mobilization.itemsPerPage' | translate}}</label>
              <input
                class="form-control"
                type="number"
                min="0"
                name="paginationPageSize"
                id="number-per-page"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="paginationPageSize"
                (change)="onPageSizeChanged()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="row">
          <div class="d-flex align-items-center form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" formControlName="offshoreOnshoreCertifications"
                    [checked]="mobilizationFormService.form?.get('offshoreOnshoreCertifications')?.value"
                    [disabledControl]="readonly" id="offshoreOnshoreCertifications"/>
              <label class="form-check-label fw-bold" for="offshoreOnshoreCertifications"
                    [ngClass]="{ 'text-primary': !readonly }">
                {{'mobilization.offshoreOnshoreCertifications' | translate}}
              </label>
            </div>
            <button class="btn btn-tertiary ms-auto" type="button" (click)="toggleCertificationsButton()" id="certificationsCollapseButton">
              <span *ngIf="!certificationsButtonExpanded" class="material-icons">expand_more</span>
              <span *ngIf="certificationsButtonExpanded" class="material-icons">expand_less</span>
            </button>
          </div>
        </div>
        <div #collapseSection="ngbCollapse" [(ngbCollapse)]="certificationsButtonExpanded">
          <div *ngIf="mobilizationFormService.form?.get('offshoreOnshoreCertifications')?.value">
          <div class="form-group mt-3">
            <div class="form-check">
              <label class="form-label">
                   {{'mobilization.MedicalSpecificTrainings' | translate}}
              </label>
              <div class="d-flex mt-2">
                <div class="form-check pe-5">
                  <input class="form-check-input" formControlName="medicalSpecificTraining" [value]="true" type="radio" name="medicalSpecificTraining" id="medicalSpecificTrainingYes" [disabledControl]="readonly">
                  <label class="form-check-label" for="medicalSpecificTrainingYes">
                     {{'mobilization.medicalYes' | translate}}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" formControlName="medicalSpecificTraining" [value]="false" type="radio" name="medicalSpecificTraining" id="medicalSpecificTrainingNo" [disabledControl]="readonly">
                  <label class="form-check-label" for="medicalSpecificTrainingNo">
                   {{'mobilization.medicalNo' | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <div class="form-check">
              <label class="form-label">
                 {{'mobilization.hSESiteOnboardingTrainingPerformed' | translate}}
              </label>
              <div class="d-flex mt-2">
                <div class="form-check pe-5">
                  <input class="form-check-input" formControlName="hseSiteOnboardingTraining" [value]="true" type="radio" name="hseSiteOnboardingTraining" id="hseSiteOnboardingTrainingYes" [disabledControl]="readonly">
                  <label class="form-check-label" for="hseSiteOnboardingTrainingYes">
                      {{'mobilization.hSEYes' | translate}}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" formControlName="hseSiteOnboardingTraining" [value]="false" type="radio" name="hseSiteOnboardingTraining" id="hseSiteOnboardingTrainingNo" [disabledControl]="readonly">
                  <label class="form-check-label" for="hseSiteOnboardingTrainingNo">
                    {{'mobilization.hSENo' | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

      </div>
    </div>
  </form>
</div>
