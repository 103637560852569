import gql from "graphql-tag";

const GET_JCM_HIGHLIGHTS = gql`
  query ($affiliate: String, $id: Int, $flocDetectionLevel: Int = 1) {
    getOverlappingConflicts(affiliate: $affiliate, masterId: $id, flocDetectionLevel: $flocDetectionLevel) {
      scaffoldingConflicts {
        id
        jobTitle
        functionalLocation
      }
      liftingConflicts{
        id
        jobTitle
        functionalLocation
      }
      isolationConflicts{
        id
        jobTitle
        functionalLocation
      }
      inhibitionConflicts{
        id
        jobTitle
        functionalLocation
      }
      temporaryEquipmentConflicts{
        id
        jobTitle
        functionalLocation
      }
    }
  }
`;

export { GET_JCM_HIGHLIGHTS };
