import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {AgChartTheme} from "@ag-grid-community/core";
import {AgChartsAngular} from "ag-charts-angular";

@Component({
  selector: 'jbid-jcm-impact',
  templateUrl: './jcm-impact.component.html',
  styleUrls: ['./jcm-impact.component.css']
})
export class JcmImpactComponent implements OnInit, OnChanges {

  @ViewChild(AgChartsAngular)
  public agCharts!: AgChartsAngular;

  @Input() disciplines: { label: string, count: number }[] = [];
  @Input() sites: { label: string, count: number }[] = [];

  options: any;
  selectedTab = 'disciplines';
  disciplinesLabel = 'Disciplines';
  sitesLabel = 'Sites';
  impactedValue = this.disciplines.length;
  impactedLabel = 'Disciplines';

  readonly paletteColors = ['#DD3400', '#009EB3', '#6FA136', '#5C2983', '#0076C5', '#21B372', '#FDDE02', '#F76700', '#D30018'];
  myTheme: AgChartTheme = {
    baseTheme: 'ag-default',
    palette: {
      fills: this.paletteColors,
      strokes: ['white'],
    },
  };

  constructor() {
    this.options = {
      theme: this.myTheme,
      data: this.getData(),
      series: [
        {
          type: 'pie',
          calloutLabelKey: 'label',
          angleKey: 'count',
          innerRadiusRatio: 0.7,
        },
      ],
    };
  }

  getData() {
    if (this.selectedTab === 'disciplines') {
      this.impactedLabel = this.disciplinesLabel;
      this.impactedValue = this.disciplines.length;
      return this.disciplines;
    } else {
      this.impactedLabel = this.sitesLabel;
      this.impactedValue = this.sites.length;
      return this.sites;
    }
  }

  ngOnInit(): void {
    this.refreshChart();
  }

  onTabChange(tab: string) {
    this.selectedTab = tab;
    this.refreshChart();
  }

  private refreshChart() {
    const options = {...this.options};
    options.data = this.getData();
    this.options = options;
  }

  ngOnChanges(): void {
    this.refreshChart();
  }

  numberOfJobCards() {
    let result = 0;
    for (const c of this.getData()) {
      result += c.count;
    }
    return result;
  }

  percent(count: number) {
    return Math.round((count / this.numberOfJobCards()) * 100);
  }
}
