<div class="log">
  <div class="background-image-overlay"></div>
  <img src="assets/img.jpg" alt="" class="background-image" />
  <div class="modal">
    <div class="center-modal p-3 mx-2">
      <img src="/assets/design-system/TotalEnergies_logo_2x.png" style="width: 30%" alt="" />

      <div class="login-sub-container mt-3">
        <div id="job" class="text-primary">Job ID Card</div>
        <div>
          <button type="button" class="btn btn-sm btn-primary my-3 mb-5" aria-hidden="true" (click)="login()">
            Log in
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
