export const environment = {
  production: true,
  apiBaseUrl: "https://aggregator-dev.jbid.iasp.tgscloud.net/aggregator",
  quantumBaseUrl: "https://navigator.phusionim.com/",
  instrumentationKey: "dfa56c50-04ac-4c5d-badd-241422ad9aa4",
  clientId: "16c5867a-d92b-46a9-a66c-a513f598d3a5",
  tenantId: "329e91b0-e21f-48fb-a071-456717ecc28e",
  aadBackendScope: "api://0e1b55b3-90d9-412f-bfa0-d45f730a8f35/aggregator_scope",
  redirectUrl: "/jobid-card/list",
  angolaUsers: "Tepa-Users",
  denmarkUsers: "Tepdk-Users",
  nigeriaDWUsers: "Tepng-dw-Users",
  nigeriaJVUsers: "Tepng-jv-Users",
  hotjarEnvironmentId: "",
  linkJobcardToJcmActive: true,
  agGridLicense: process.env["AG_GRID_LICENSE"] || 'Using_this_{AG_Grid}_Enterprise_key_{AG-060803}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TotalEnergies_Digital_Factory}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_July_2025}____[v3]_[01]_MTc1MzkxNjQwMDAwMA==639922454f8ffeeab2b10d6331c37e8c'
};
