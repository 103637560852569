import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { BehaviorSubject, filter, map, Observable } from "rxjs";
import { ProfileService } from "@shared/services";
import { SiteInfo } from "@shared/models/siteplatform/site-platform.model";
import { GET_SITE_INFO_BY_AFFILIATE_QUERY } from "@graphql/jobid-card/discipline/queries/site-info.gql";

@Injectable({
  providedIn: "root",
})
export class SitePlatformsService {
  private _siteInfo$: BehaviorSubject<SiteInfo[]> = new BehaviorSubject<SiteInfo[]>([] as SiteInfo[]);
  siteInfo$: Observable<SiteInfo[]> = this._siteInfo$.asObservable();
  siteInfoDefaults$: Observable<SiteInfo[]> = this.siteInfo$.pipe(
    map((siteInfo) => siteInfo.filter((site) => site.isDefault)),
  );

  constructor(private profileService: ProfileService, private apollo: Apollo) {
    this.profileService.affiliate$.pipe(map(affiliate => affiliate.value), filter(Boolean)).subscribe({
      next: (affiliate) => {
        this.apollo
          .query<any>({
            query: GET_SITE_INFO_BY_AFFILIATE_QUERY,
            variables: {
              affiliate: affiliate,
            },
          })
          .subscribe(({ data }) => {
            const sitesWithFloc = data.getSiteInfoByAffiliate;
            this._siteInfo$.next(sitesWithFloc);
          });
      },
    });
  }

  public mapSiteNameToDisplayName(siteName: string) {
    return this._siteInfo$.value
      .filter((site) => site.siteName === siteName)
      .map((site) => site.displayName)
      .pop();
  }
}
