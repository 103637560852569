import {Component, OnInit} from "@angular/core";
import {filter, Observable} from "rxjs";
import {Affiliate, AffiliateConfig} from "@shared/models/affiliate.model";
import {ProfileService} from "@shared/services/profile/profile.service";
import {RouterEventsService} from "@shared/services/routing/router-events.service";
import {AuthService} from "@shared/services";
import {Router} from "@angular/router";
import {Language} from "@shared/@types/i18n";
import {TranslationService} from "@shared/services/translation/translation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "jbid-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userName$: Observable<string> = this.profileService.userName$;
  affiliates: Affiliate[] = [];
  isJobCardListRoute!: boolean;
  translationEnabled = false;
  selectedAffiliate!: Affiliate;
  jobCardTabsHeader: any[] = [
    { title: this.translate.instant('common.myCockpit'), route: "cockpit", id: "cockpit" },
    { title: this.translate.instant('common.activities'), route: "jobid-card/list", id: "home" },
    { title: this.translate.instant('common.readiness'), route: "readiness-dashboard", id: "readiness" },
    { title: this.translate.instant('common.meetingBoard'), route: "meeting-boards", id: "meeting-boards" },
    { title: this.translate.instant('common.metrics'), route: "metrics", id: "metrics" },
  ];
  displayJobCardTabsHeader = this.jobCardTabsHeader;
  selectedTabIndex = 0;
  displayAdminLink = false;
  displayTabForJobLeader = false;
  public currentLanguage = Language.EN;
  public Language = Language;

  constructor(private profileService: ProfileService, private urlService: RouterEventsService, private translate: TranslateService,
    private authService: AuthService, private router: Router, private translationService: TranslationService) {
      this.changeLanguage(this.currentLanguage);
  }

  ngOnInit(): void {
    this.authService.admin$.subscribe((isAdmin) => {
      this.displayAdminLink = isAdmin;
    });
    this.authService.jobLeader$.subscribe((isJobLeader) => {
      this.displayTabForJobLeader = isJobLeader;
      this.updateTabs();
      this.selectedTabIndex = this.displayJobCardTabsHeader.findIndex((tab) => this.urlService.getCurrentUrl().includes(tab.route));
    });
    this.urlService.hasRoutedFromJobCardList$.subscribe(() => {
      this.isJobCardListRoute = this.urlService.isJobCardListRoute();
    });
    this.profileService.affiliates$.subscribe((affiliates) => {
      this.affiliates = affiliates;
    });
    this.profileService.affiliate$.subscribe((affiliate) => {
      this.selectedAffiliate = affiliate;
    });
    this.profileService.affiliateConfig$?.pipe(filter<AffiliateConfig | null>(Boolean)).subscribe((affiliateConfig) => {
      this.translationEnabled = affiliateConfig.translationEnabled;
    });
  }

  selectTab(index: number) {
    this.selectedTabIndex = index;
  }

  logout() {
    this.profileService.logout();
  }

  goToAdminPage() {
    this.router.navigate(["/admin"]);
  }

  changeAffiliate(affiliate: Affiliate) {
    this.profileService.changeAffiliate(affiliate);
  }

  changeLanguage(language: Language): void {
    this.currentLanguage = language;
    this.translationService.changeLanguage(language);
  }

  updateTabs() {
    this.displayJobCardTabsHeader = this.jobCardTabsHeader.filter(tab => {
      return !(tab.id === "cockpit" && !this.displayTabForJobLeader)
        && !(tab.id === "meeting-boards" && this.displayTabForJobLeader);
    });
  }
}
