import { KeyValue } from "@angular/common";

export * from "./adapter.model";
export * from "./response.model";

export const keyDescOrderComparator = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
  if(+a.key > +b.key) {
    return -1;
  } else {
    return +b.key > +a.key ? 1 : 0;
  }
};

// Preserve original property order
export const keyOriginalOrderComparator = (a: KeyValue<string,string>, b: KeyValue<string,string>): number => {
  return 0;
}
