import {Injectable} from "@angular/core";
import {FormArray, UntypedFormBuilder} from "@angular/forms";
import {DocumentDTO} from "@shared/models/prerequisites/document-dto.model";
import filter from "lodash-es/filter";

import {AbstractArrayFormService} from "../../abstract-array-form.service";
import { ResourceCalorifugeRemovalDTO, ResourceCalorifugeRemovalDTOInput } from "@shared/models/prerequisites/resource-calorifuge-removal.model";

@Injectable({
  providedIn: "root",
})
export class CalorifugeRemovalFormService extends AbstractArrayFormService {
  currentId!: string;
  jobCardId!: number;

  defaultDTO: ResourceCalorifugeRemovalDTO ={
    calorifugeRemovalNeeded: true,
    equipmentTagAndDescription: "",
  }

  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

  createFormGroup(dto?: ResourceCalorifugeRemovalDTO, documents?: Map<string, DocumentDTO[]>) {
    return this.fb.group({
      calorifugeRemovalNeeded: [dto?.calorifugeRemovalNeeded || true],
      equipmentTagAndDescription: [dto?.equipmentTagAndDescription || ""],
      calorifugeDocumentLink: [documents?.get("calorifugeDocumentLink"), []],
      jobCardId: [dto?.jobCardId],
      id: [dto?.id],
    });
  }

createForm(isDisabled: boolean, calorifugeData?: ResourceCalorifugeRemovalDTO[], documents?: Map<string, DocumentDTO[]>) {
  if(this.form != undefined && !isDisabled){
    return this.form;
  }

  if(isDisabled || this.form == undefined){
    this.data = calorifugeData;

    const isChecked =
      (calorifugeData?.length !== 0 && calorifugeData?.every((item) => item.displayed)) || false;

    this.form = this.fb.group({
      checked: [{ value: isChecked, disabled: isDisabled }, []],
      items: this.setInitialData(calorifugeData, documents),
    });
  }
  this.clearFormOnUnchecked()
  return this.form;
}
getFormData(resourceCalorifuge: ResourceCalorifugeRemovalDTO[]): ResourceCalorifugeRemovalDTOInput[] {
  if (!resourceCalorifuge) {
    return [];
  }
  if (!this.form) return resourceCalorifuge.map((work) => this.copy(work));

  const formItems: FormArray = this.form.controls.items as FormArray;
  let items: ResourceCalorifugeRemovalDTO[] = [];

  if (formItems && formItems.controls.length !== 0) {
    items = formItems.controls.map((item: any) => {
      item = this.copyFromFormItem(item.controls);
      item.jobCardId = this.jobCardId;
      item.displayed = this.form.controls.checked.value;
      return item;
    });
  }

  return items;
}

get formArrayName() {
  return "items";
}

private copyFromFormItem(formItem : any){
  return {
    id: formItem.id?.value,
    calorifugeRemovalNeeded: formItem.calorifugeRemovalNeeded.value,
    equipmentTagAndDescription: formItem.equipmentTagAndDescription.value,
  };
}

  private copy(calorifuge: ResourceCalorifugeRemovalDTO): ResourceCalorifugeRemovalDTOInput {
    return {
      id: calorifuge.id,
      jobCardId: calorifuge.jobCardId,
      displayed: calorifuge.displayed,
      calorifugeRemovalNeeded: calorifuge.calorifugeRemovalNeeded,
      equipmentTagAndDescription: calorifuge.equipmentTagAndDescription,

    };
  }

  setJobCardId(jobCardId: number) {
    this.jobCardId = jobCardId;
  }

  getFormDocuments(documentDTOList: DocumentDTO[]) {
      if (!documentDTOList && !this.form) {
        return [];
      }

      if(!this.form){
        const filteredDocs :DocumentDTO[] = filter(documentDTOList, {'section': 'CALORIFUGE_REMOVAL'});
        return filteredDocs.map(doc => this.copyDocument(doc));
      }
      const calorifugeDocumentLink: DocumentDTO[] = [];
      this.form?.controls["items"]?.value?.forEach(
        (item: {
          calorifugeDocumentLink: DocumentDTO[];
          }) => {
          item.calorifugeDocumentLink?.forEach((doc: DocumentDTO) => calorifugeDocumentLink.push(this.copyDocument(doc)));
        },
      );

      return [
        calorifugeDocumentLink
      ].reduce((acc, val) => acc.concat(val), []);
  }
}
