import gql from "graphql-tag";

const MUTATE_LINK_JOB_CARD_TO_JOB_CARD_MASTER_BY_REVCODE = gql`
  mutation linkByRevisionCode($affiliate: String, $masterId: Int, $revisionCode: String) {
    linkByRevisionCode(affiliate: $affiliate, masterId: $masterId, revisionCode: $revisionCode) {
      id
      successCount
      errorCount
      jobCardMasterId
      createDate
    }
  }
`;

export { MUTATE_LINK_JOB_CARD_TO_JOB_CARD_MASTER_BY_REVCODE };
