@if(generalDetails){
  <div class="card me-2">
    <div class="card-body">
      <div class="container-fluid d-flex align-items-center">

        <div>
            <button id="returnButton" class="btn btn-link" (click)="returnToJobCardList()">
                    <span class="material-icons" aria-hidden="true">navigate_before</span>
              <span class="text-muted ms-1"> {{'generalDetails.jobCardList' | translate}} </span>
            </button>
          </div>

        <div class="vr mx-4"></div>

        <div class="w-100">
            <div class="d-flex align-items-center">
              <h5 class="job-card-title my-0">ID - {{generalDetails.id}}</h5>

              @if(readonly || !titleEditable){
                <div>
                  <h5 id="jobCardTitle" class="job-card-title mx-2 my-0">{{ generalDetails.jobTitle | uppercase }}</h5>
                </div>
              } @else if (!readonly && titleEditable) {
                <div>
                  <input type="text" class="form-control mx-2 my-0 w-25" [(ngModel)]="generalDetails.jobTitle" title="Job title">
                </div>
              }
              <jbid-readiness-badge id="actualStatusBadge" [readiness]="generalDetails.actualStatus"></jbid-readiness-badge>

              <div class="d-flex ms-auto">
                @if(!isDiscussionSection && readonly){
                  <ng-container >
                  <div ngbDropdown class="dropdown px-3 text-center">
                    <button ngbDropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-expanded="false" id="actionsButton">{{'dropdownLabels.actions' | translate}} </button>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="actionsButton">
                      <button ngbDropdownItem class="dropdown-item" id="editButton" (click)="actionHandler('edit')" [disabled]="editionDisabled || isHistoryTab">
                        <div class="d-flex align-items-center">
                          <span class="material-icons-outlined md-24" aria-hidden="true">edit</span>
                          <span class="ps-1">{{'generalDetails.edit' | translate}}</span>
                        </div>
                      </button>
                      <button ngbDropdownItem class="dropdown-item" id="duplicateButton" (click)="actionHandler('duplicate')">
                        <div class="d-flex align-items-center">
                          <span class="material-icons-outlined md-24" aria-hidden="true">copy_all</span>
                          <span class="ps-1">{{'dropdownLabels.duplicate' | translate}}</span>
                        </div>
                      </button>
                      <button ngbDropdownItem class="dropdown-item" id="deleteButton" (click)="actionHandler('delete')" [disabled]="!isDraftOrReadyToActivate || !isAdmin" >
                        <div class="d-flex align-items-center">
                          <span class="material-icons-outlined md-24" aria-hidden="true">delete</span>
                          <span class="ps-1">{{'dropdownLabels.delete' | translate}}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </ng-container>
                } @else if (!isDiscussionSection && !readonly){
                  <ng-container >
                    <button type="button" class="btn btn-primary mx-2"
                            (click)="actionHandler('validate')">
                      <span class="material-icons" aria-hidden="true">done</span>
                      {{'createPopup.validate' | translate}}
                    </button>
                    <button type="button" class="btn btn-secondary mx-2"
                            (click)="actionHandler('cancel')">
                      <span class="material-icons" aria-hidden="true">close</span>
                      {{'createPopup.cancel' | translate}}
                    </button>
                  </ng-container>
                }
              </div>

            </div>

            <div class="d-flex mt-3">

              @if(generalDetails.jobCardMasterId){
                <div>
                  <span>Job Card Master : </span>
                  <span class="header-value" id="masterHeader">
                    <a href="{{ jcmLink }}" target="_blank" rel="noopener">{{ generalDetails.jobCardMasterTitle }}</a>
                  </span>
                </div>

                <div class="vr mx-2"></div>
              }

              @if(isLeader){
                <div>
                  <span>Dependency : </span>
                  <span class="header-value">Leader</span>
                </div>
              }

              @if(isIndependent){
                <div>
                  <span>Dependency : </span>
                  <span class="header-value">None</span>
                </div>
              }

              @if(isDependentOnParent){
                <div>
                  <span>Depends On : </span>
                  <span class="header-value">
                    <a href="{{ parentLink }}" target="_blank" rel="noopener">{{ truncate(generalDetails.parentTitle) }}</a>
                  </span>
                </div>
              }

              <div class="vr mx-2"></div>

              <div>
                <span>{{'generalDetails.jobCardstatus' | translate}} : </span>
                <span class="header-value" id="statusHeader">{{ generalDetails.actualStatus }}</span>
              </div>

              <div class="vr mx-2"></div>

              <div>
                <span>{{'generalDetails.lastStatusUpdate' | translate}} : </span>
                <span class="header-value"
                      id="readinessUpdateHeader">{{ lastReadinessUpdate ? (lastReadinessUpdate | date: 'dd/MM/yyyy \'at\' H:mm') : 'N/A' }}</span>
              </div>

              <div class="vr mx-2"></div>

              <div>
                <span>{{'generalDetails.site' | translate}} : </span>
                <span class="header-value" id="siteHeader">{{ generalDetails.sitePlatform }}</span>
              </div>

              <div class="vr mx-2"></div>

              <div>
                <span> {{'generalDetails.platform' | translate}} : </span>
                <span class="header-value" id="platformHeader">{{ generalDetails.flocPlatform ? generalDetails.flocPlatform : 'NA' }}</span>
              </div>

              <div class="vr mx-2"></div>

              <div>
                <span>{{'generalDetails.executionDates' | translate}} : </span>
                <span class="header-value"
                      id="execDateHeader">{{
                    startDate && endDate ?
                      (startDate | date: 'dd/MM/yyyy') + ' to '
                      + (endDate | date: 'dd/MM/yyyy') : 'N/A'
                  }}</span>
              </div>

            </div>
          </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-3">
    <div class="row d-flex">
      <div class="col-2 px-0 scroll-general-details">
        <div class="card">
          <div class="card-body">
            <ul ngbNav class="side-nav ps-0 mb-0 bg-white">
              @for (tab of tabs; track tab.title){
                @if(tab.display){
                  <li [ngbNavItem]="tab.route">

                    @if(!tab?.children){
                      <a [routerLink]="tab.route"
                         [class.active]="selectedTab === tab.route"
                         class="nav-link d-inline-flex justify-content-between justify-items-center align-items-center text-uppercase">
                        @if(isPageInvalid(tab.route)){
                          <span class="material-icons-outlined md-6 ps-2" aria-hidden="true">info</span>
                        }

                        {{ tab.title | translate }}
                      </a>
                    } @else {
                      <ng-container>
                        <button class="btn btn-toggle d-inline-flex justify-content-between text-uppercase"
                                (click)="collapse.toggle()"
                                [ngClass]="{ collapsed: !collapse.collapsed }"
                                [attr.aria-expanded]="!collapse.collapsed">
                          {{ tab.title | translate}}
                          @if(isPageInvalid(tab.route)){
                            <span class="material-icons-outlined md-6 ps-2" aria-hidden="true">info</span>
                          }
                        </button>

                        <div #collapse="ngbCollapse" [ngbCollapse]="!isPrerequisitesSection">

                          <ul class="btn-toggle-nav pb-1 text-capitalize">
                            @for(child of tab.children; track child.title){
                              <li [ngbNavItem]="tab.route + '/' + child.route">
                                <a [routerLink]="tab.route + '/' + child.route"
                                   id="{{child.title}}"
                                   [class.active]="selectedTab === child.route"
                                   class="nav-link d-inline-flex justify-content-between align-items-center">

                                  @if(isPageInvalid(child.route)){
                                    <span class="material-icons-outlined md-6 ps-2" aria-hidden="true">info</span>
                                  }

                                  {{ child.title | translate}}

                                  @if(prerequisites && !isDraftOrReadyToActivate){
                                    <jbid-readiness id="readiness{{ child.route }}"
                                                    [readinessText]="getPrerequisitesGlobalReadiness(child.route)"
                                                    [displayReadinessText]="false"></jbid-readiness>
                                  }
                                </a>
                              </li>
                            }
                          </ul>
                        </div>
                      </ng-container>
                    }
                  </li>
                }
              }

            </ul>
          </div>
        </div>
      </div>

      <div class="col-10 pe-0 scroll-general-details">
        @if (generalDetails){
          <router-outlet></router-outlet>
        }
      </div>
    </div>
  </div>
}

@if(!generalDetails || generalDetails === undefined){
  <div class="container-fluid mt-3">
    <div class="card-body bg-white">
      <p>{{'generalDetails.errorJobCard' | translate}}</p>
    </div>
  </div>
}
