import gql from "graphql-tag";

const CREATE_DISCUSSION_MUTATION = gql`
    mutation createDiscussionMutation($input: DiscussionInput!) {
        createDiscussionMessage(input: $input) {
            id
            jobCardId
            senderName
            messageObject
            dateCreated
            prerequisiteTags
            messageContent
        }
    }
`;

export { CREATE_DISCUSSION_MUTATION };
