import gql from "graphql-tag";

const GET_PREREQUISITES_QUERY = gql`
  query ($id: Int, $affiliate: String) {
    getPrerequisites(id: $id, affiliate: $affiliate) {
      jobId
      jobScopeDTO {
        id
        methodStatement
        methodStatementStatus
        komJra
        aroRloRequired
        rfmNumber
        rfmNumberValue
        rfmTitle
        rfmClass
        rfmEngineeringStatus
        wellIntervention
        wellInterventionStatus
        methodStatementReadiness
        komReadiness
        rfmNumberReadiness
        wellInterventionReadiness
        costBudget
        costBudgetReadiness
        yearlyBudgetRequired
        describeActions
        scop {
          id
          jobCardId
          displayed
          authorizationStatus
          siteVisitCheckListStatus
          criticalLevelAssessmentStatus
          otherDocuments {
            id
            description
            status
            name
            link
          }
          readiness
        }
      }
      impactDTO {
        id
        impactSimops
        impactSimopsStatus
        impactShortfall
        impactShortfallProduction
        impactShortfallInjection
        impactShortfallFlaring
        impactPermitToWork
        impactPermitsStatus
        impactShutdown
        impactFlaring
        impactHotWork
        impactConfinedSpace
        impactConfinedSpaceProtocolStatus
        impactRadiography
        impactRadiographyStatus
        impactDivingRov
        impactDivingRovStatus
        impactHydroPressure
        impactHydroPressureStatus
        hazardousDisplayed
        hazardous{
          id
          hazardousClassifiedArea
          hazardousProcedureStatus
          hazardousDocumentLink
          impactId
        }
        simopsReadiness
        shortFallImpactReadiness
        permitToWorkReadiness
        confinedSpaceReadiness
        radiographyReadiness
        divingRovReadiness
        hydroPressureTestReadiness
        hazardousClassifiedAreaReadiness
        regulatorApprovalReadiness
        regulatorApproval
        regulatorApprovalDesc
        regulatorApprovalStatus
        greenHouseGas
        ghgYearlyBudgetRequired
        describeActions
      }
      materialListDTO {
        materialStatus
        materials {
          id
          materialDescription
          unloadingPoint
          materialQuantityRequested
          materialQuantitySite
          sapOrigin
          materialNumber
          workOrderNumber
          jobCardId
          procurementStatus
          eta
          qaQcCertificate
          affiliate
          itemNumber
          readiness
        }
        fatDTO {
          id
          displayed
          description
          plannedDate
          status
          jobCardId
          readiness
        }
      }
      resourceDTO {
        resourceScaffoldingListDTO {
          id
          displayed
          jobCardId
          description
          status
          jobId
          designSpecificity
          calculationNoteStatus
          schematicStatus
          locationStatus
          dimensionLength
          dimensionWidth
          dimensionHeight
          volume
          readiness
        }
        resourceLiftingListDTO {
          id
          displayed
          jobCardId
          criticalLifting
          liftingPlanStatus
          liftingDescription
          readiness
          slsRequired
          craneRequired
          craneDescription
          liftingProcedureRequired
        }
        resourceTemporaryEquipmentListDTO {
          id
          description
          status
          displayed
          jobCardId
          documentId
          readiness
        }
        resourceIsolationListDTO {
          id
          description
          status
          isolationType
          displayed
          jobCardId
          readiness
        }
        resourceInhibitionListDTO {
          id
          description
          status
          equipmentTag
          displayed
          jobCardId
          readiness
        }
        resourceCoreCrewAssistanceListDTO {
          id
          description
          status
          discipline
          displayed
          jobCardId
          readiness
        }
        resourceContractorListDTO {
          id
          displayed
          contractorActivityVendorJustification
          contractorActivityOrder
          contractorActivityAmount
          contractorActivityServiceOrder
          contractorActivityName
          contractorActivityNumber
          jobCardId
          readiness
        }
        resourceLogisticListDTO {
          id
          description
          status
          displayed
          jobCardId
          readiness
        }
        resourceInvitationDTO {
          id
          displayed
          status
          description
          internationalFlights
          invitationVisaStatus
          jobCardId
          readiness
        }
        resourceCustomPrerequisiteListDTO {
          id
          title
          description
          status
          displayed
          jobCardId
        }
        resourceOnshoreWorksDTO {
          id
          displayed
          jobCardId
          excavationRequired
          readiness
          pipesAndCableIdentificationRequired
          grassCuttingRequired
          securityBridgingDocumentRequired
          communityEngagementThroughCAPDRequired
        }
        resourceCalorifugeRemovalListDTO {
          id
          displayed
          jobCardId
          calorifugeRemovalNeeded
          equipmentTagAndDescription
        }
      }
      documentDTOList {
        id
        type
        link
        name
        section
        sectionIndex
        jobCardId
      }
      mobilizationsDTO {
        id
        bookingGlobalStatus
        offshoreOnshoreCertifications
        medicalSpecificTraining
        hseSiteOnboardingTraining
        resourcesManagementReadiness
      }
    }
  }
`;

export { GET_PREREQUISITES_QUERY };
