import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

export interface YesNoModalContent {
  title: string;
  iconName?: string;
  content: string;
  subContent?: string;
  yesLabel: string;
  noLabel: string;
  showModalPreferences?: boolean;
}

@Component({
  selector: "jbid-yes-no-modal",
  templateUrl: "app-yes-no-modal.component.html",
  styleUrls: ["app-yes-no-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppYesNoModalComponent {
  @Input() title!: string;
  @Input() content!: string;
  @Input() subContent!: string;
  @Input() iconName? = "info";
  @Input() yesLabel = "Yes";
  @Input() noLabel = "No";
  @Input() showModalPreferences = false;
  private modalPreferences = "toNotShowModalAgain";
  toNotShowModalAgain: boolean | undefined;

  constructor(public activeModal: NgbActiveModal) {}

  static open(data: YesNoModalContent, modalService: NgbModal): Observable<boolean> {
    const modalRef = modalService.open(AppYesNoModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.setData(data);

    return from(modalRef.result).pipe(
      map((value) => !!value),
      catchError((_error: any) => {
        return of(false);
      }),
    );
  }
  setData(data: YesNoModalContent) {
    this.title = data.title;
    this.content = data.content;
    this.subContent = data.subContent ?? "";
    this.iconName = data.iconName ?? this.iconName;
    this.yesLabel = data.yesLabel || this.yesLabel;
    this.noLabel = data.noLabel || this.noLabel;
    this.showModalPreferences = data.showModalPreferences ?? this.showModalPreferences;
  }

  confirmAction() {
    if (this.toNotShowModalAgain) {
      localStorage.setItem(this.title, this.modalPreferences);
    }
    this.activeModal.close(true);
  }
}
