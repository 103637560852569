import gql from "graphql-tag";

const SET_JOB_CARD_BREAK_OUT_STATUS = gql`
  mutation ($affiliate: String, $jobCardId: Int, $isBreakout: Boolean){
    setJobCardBreakoutStatus (affiliate:$affiliate, jobCardId:$jobCardId, isBreakout: $isBreakout) {
      id
    }
  }
`;

export { SET_JOB_CARD_BREAK_OUT_STATUS };
