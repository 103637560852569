<div class="modal-header h2">{{'createPopup.jobCardDuplication' | translate}}</div>
<div class="modal-body">
  <p>{{'createPopup.duplicationHeader' | translate}}</p>
  <p>{{'createPopup.duplicationBody' | translate}}</p>
  <form [formGroup]="duplicateFormGroup">
    <div class="form-group py-2">
      <label for="sapWorkOrder" class="form-label">{{'createPopup.sapWorkOrder' | translate}} <span class="text-muted">({{'createPopup.informWO' | translate}})</span></label>
      <input type="text" class="form-control" id="sapWorkOrder" formControlName="sapWorkOrder" [ngClass]="{ 'is-invalid': duplicateFormGroup.get('sapWorkOrder')?.invalid && duplicateFormGroup.get('sapWorkOrder')?.dirty }">
      <div class="text-danger pt-1" *ngIf="invalidWorkOrder">{{INVALID_WORK_ORDER}}</div>
      <div class="text-danger pt-1" *ngIf="workOrderAlreadyUsed">{{WORK_ORDER_ALREADY_USED}}</div>
    </div>
    <div class="form-group py-2">
      <label for="jobCardName" class="form-label">{{'createPopup.jobCArdName' | translate}} <span class="form-mandatory pe-1">*</span> <span class="text-muted">({{'createPopup.title' | translate}})</span></label>
      <input type="text" class="form-control" id="jobCardName" formControlName="jobCardName" required>
    </div>
    <div class="form-group py-2">
      <label for="site" class="form-label">{{'createPopup.site' | translate}} <span class="form-mandatory pe-1">*</span> <span class="text-muted">({{'createPopup.informSite' | translate}})</span></label>
      <input id="typeaheadformat" type="text" id="site" class="form-control" title="site" formControlName="site"
                           [ngClass]="{ 'is-invalid': duplicateFormGroup.get('site')?.invalid && duplicateFormGroup.get('site')?.dirty }"
                           [ngbTypeahead]="search" [resultFormatter]="siteFormatter"/>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" id="cancelButton" (click)="onCancel()">{{'createPopup.cancel' | translate}}</button>
  <button type="button" class="btn btn-primary" id="confirmButton" [disabled]="duplicateFormGroup.invalid" (click)="onDuplicate()">{{'createPopup.duplicate' | translate}}</button>
</div>
