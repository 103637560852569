import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {JobCardMasterService} from "@shared/services/job-card-master/job-card-master.service";
import {MassiveLinkReportDTO} from "@shared/models/job-card-master/job-card-master.model";
import {buildSearchFunction} from "@shared/utils";
import {takeUntil} from "rxjs/operators";
import {RevisionCodeService} from "@shared/services/revision-code/revision-code.service";
import {Subject} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: "jbid-link-job-card-to-job-card-master-by-revcode",
  templateUrl: "./link-job-card-to-job-card-master-by-revcode.component.html",
  styleUrls: ["./link-job-card-to-job-card-master-by-revcode.component.scss"],
})
export class LinkJobCardToJobCardMasterByRevcodeComponent implements OnDestroy, OnInit {
  formGroup: FormGroup<{ revisionCode: FormControl<string | null> }> = new FormGroup({
    revisionCode: new FormControl<string | null>(null, [Validators.required]),
  });
  massiveLinkReport: MassiveLinkReportDTO | undefined = undefined;
  massiveLinkReportError: string | undefined = undefined;

  status: "input" | "loading" | "result" = "input";

  successLinked = 0;
  errorsLinked = 0;
  private destroy = new Subject<void>();
  private affiliate: string | undefined;
  private currentJcmId: number | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private jobCardMasterService: JobCardMasterService,
    private revisionCodeService: RevisionCodeService,
    private router: Router,
  ) {
  }

  revisionCodes: string[] = [];

  searchRevisionCode = buildSearchFunction((term: string) =>
    term ? this.revisionCodes.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10) : [],
  );

  onSubmit() {
    this.status = "loading";
    this.massiveLinkReport = undefined;
    this.massiveLinkReportError = undefined;

    this.jobCardMasterService
      .linkJcByRevisionCode(this.formGroup.value.revisionCode ?? "")
      .subscribe(
        (result: {
          massiveLinkReport: MassiveLinkReportDTO | undefined;
          massiveLinkReportError: string | undefined;
        }) => {
          this.status = "result";
          this.massiveLinkReport = result.massiveLinkReport;
          this.massiveLinkReportError = result.massiveLinkReportError;
        },
      );
  }

  ngOnInit(): void {
    this.revisionCodeService.revisionCodes$.pipe(takeUntil(this.destroy)).subscribe((revisionCodes) => {
      this.revisionCodes = revisionCodes;
    });
    this.jobCardMasterService.generalDetails$
      .pipe(takeUntil(this.destroy))
      .subscribe((generalDetails) => {
        this.currentJcmId = generalDetails.id;
        this.affiliate = generalDetails.affiliate;
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  redirectToReport() {
    this.activeModal.close();
    this.router.navigate([`/job-card-master/detail/${this.affiliate}/${this.currentJcmId}/massive-reports`]);
  }
}
