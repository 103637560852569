export enum Role {
  JOB_PLANNER = "JobPlanners",
  JOB_LEADER = "JobLeaders",
  SUPERINTENDENT = 'Superintendent',
  OIM = 'OIM',
  FIELD_OPERATIONS_MANAGER = 'FieldOperationsManager',
}

export enum Responsability {
   SAP_AUTHORIZED_ROLE = "JBID.SAP.Writer",
   LOCAL_ADMIN = "JBID.Admin",
}
