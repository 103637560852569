import gql from "graphql-tag";

const GET_DISCUSSIONS_QUERY = gql`
    query ($id: Int) {
        getDiscussionMessagesByJobCardId(id: $id) {
            id
            jobCardId
            senderName
            messageObject
            dateCreated
            lastModified
            prerequisiteTags
            messageContent
            deleted
            deleteTime
        }
    }
`;

export {GET_DISCUSSIONS_QUERY};
