import gql from "graphql-tag";

const GET_SITE_INFO_BY_AFFILIATE_QUERY = gql`
  query ($affiliate: String) {
    getSiteInfoByAffiliate(affiliate: $affiliate) {
      siteName
      platforms
      displayName
      isDefault
    }
  }
`;

export { GET_SITE_INFO_BY_AFFILIATE_QUERY };
