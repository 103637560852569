import gql from "graphql-tag";

const GET_AFFILIATES = gql`
  query getAffiliates {
    getAffiliates {
      name
      value
    }
  }
`;

export { GET_AFFILIATES };
