import gql from "graphql-tag";

const CREATE_JOB_CARDS_BY_REVISON_CODE = gql`
  mutation ($affiliate: String, $revisionCode: String) {
    createAllByRevisionCode(affiliate: $affiliate, revisionCode: $revisionCode) {
      totalWorkOrder
      totalJobCardCreated
    }
  }
`;

export { CREATE_JOB_CARDS_BY_REVISON_CODE };
