import {WorkOrder} from "../work-orders/workorder.model";
import { PrerequisitesInput } from '../prerequisites/prerequisites.model';
import { JobCardGeneralDetailsAggregateInput } from './GeneralDetailsAggregate.model';

export enum JobidCardShiftType {
  DAY_SHIFT = "Day shift",
  NIGHT_SHIFT = "Night shift",
  DAY_NIGHT_SHIFT = "Day/Night shift",
}

export enum GeneralStatus {
  DRAFT = "Draft",
  READY_TO_ACTIVATE = "Ready to activate",
  ACTIVE = "Active",
  CLOSED = "Closed",
  CANCELLED = "Cancelled",
}

export const mGeneralStatus: ReadonlyMap<string, string> = new Map(Object.entries(GeneralStatus));

export enum JobGateReadiness {
  DRAFT = "Draft",
  READY_TO_ACTIVATE = "Ready to activate",
  READY_4M = "4M Ready",
  AWAITING_PREP_4M = "4M Awaiting Preparation",
  READY_5W = "5W Ready",
  AWAITING_PREP_5W = "5W Awaiting Preparation",
  AWAITING_PREP_5W_ALERT = "5W Alert Awaiting Preparation",
  READY_2WF = "2WF Ready",
  AWAITING_PREP_2WF = "2WF Awaiting Preparation",
  SITE_EXECUTION_PREPARATION = "Site Execution Preparation",
  SITE_EXECUTION = "Site execution",
  LATE_EXECUTION = "Late execution",
  CANCELLED = "Cancelled",
}

export interface JobidCard {
  id?: number;
  jobTitle?: string;
  workOrder?: WorkOrder;
  generalStatus?: GeneralStatus;
  owner?: string;
  jobAttributes?: string;
  jobLeader?: string;
  shiftType?: string;
  maximumPob?: number;
  lastModified?: Date;
  lastModifiedBy?: string;
  lastRefreshed?: Date;
  siteVisitOnly?: boolean;
}


export interface ActivityInput {
  activityId: string;
  entityId: string;
}

export interface Activity {
  activityId: string;
  id: string;
}

export interface WbsPrimavera {
  code: string;
  name: string;
  objectId: string;
}

export interface JobCardCreationInput {
  workOrderNumber?: number;
  jobTitle?: string;
  sitePlatform?: string;
  metier?: string;
  disciplineCategory?: string;
  discipline?: string;
  siteVisitOnly?: boolean;
  affiliate?: string;
  flocPlatform?: string;
}

export interface JobCardDetailsInput {
  prerequisitesInput?: Partial<PrerequisitesInput>;
  generalDetailsInput?: Partial<JobCardGeneralDetailsAggregateInput>;
}

export interface JobCardDuplicationInput {
  jobCardId : number;
  affiliate: string;
  jobTitle: string;
  workOrderNumber: number | null;
  sitePlatform: string;
}

export interface JobCardDetailTab {
  route: string;
  title: string;
  display: boolean;
  children?: Array<{ route: string; title: string; display: boolean }>;
}
