import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {combineLatestWith, filter, takeUntil} from "rxjs/operators";
import {APP_ROUTES_NAMES} from "../../routes";
import {
  JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES,
  JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES,
  JOB_ID_CARD_DETAIL_ROUTES_NAMES,
  JOB_ID_CARD_ROUTES_NAMES,
} from "../jobid-card.routes";
import {GeneralStatus, JobCardDetailTab} from '../../../shared/models/jobid-card/jobid-card.model';
import { ActionType, JobidCardDetailService } from '../../../shared/services/jobid-card-detail/jobid-card-detail.service';
import { JobidCardDetailHelperService } from '../../../shared/services/jobid-card-detail/jobid-card-detail-helper.service';
import { DiscussionsFacadeService } from '../../../shared/services/discussions/discussions-facade.service';
import { JobCardGeneralDetailsAggregate } from '../../../shared/models/jobid-card/GeneralDetailsAggregate.model';
import {combineLatest, Observable, of, Subject} from "rxjs";
import { Prerequisites } from '../../../shared/models/prerequisites/prerequisites.model';
import { ReadinessModel } from '../../../shared/models/prerequisites/Readiness.model';
import { getDateFromString } from '../../../shared/utils';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { InsightsService } from '../../../shared/services/insights/insights.service';
import { ProfileService } from '../../../shared/services/profile/profile.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DuplicationDialogComponent} from "./duplication-dialog/duplication-dialog.component";
import {PendingActivationModalComponent} from "./pending-activation-modal/pending-activation-modal.component";



@Component({
  selector: "jbid-card-detail",
  templateUrl: "./jobid-card-detail.component.html",
  styleUrls: ["./jobid-card-detail.component.scss"],
})
export class JobidCardDetailComponent implements OnInit, OnDestroy {
  static readonly EMAIL = "email";
  editionDisabled = false;
  titleEditable = false;
  tabs : JobCardDetailTab[] = [];
  selectedTab!: string;
  isPrerequisitesSection!: boolean;
  isDiscussionSection!: boolean;
  selectedStatus$!: Observable<GeneralStatus>;
  generalDetails!: JobCardGeneralDetailsAggregate;
  prerequisites!: Prerequisites;
  readonly = true;
  isDraftOrReadyToActivate = true;
  lastReadinessUpdate!: Date | null;
  startDate!: Date | null;
  endDate!: Date | null;
  private readonly destroy = new Subject<void>();
  mobilizationEnabled = false;
  isHistoryTab = false;
  jcmLink!: string;
  isAdmin = false;
  dependency = "";
  hasDependency = false;
  isLeader = false;
  isJobPlanner = false;
  isDependentOnParent = false;
  isIndependent = false;
  parentLink = "";
  userName = "";
  hasRoutedFromEmail = false;


  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cdRef: ChangeDetectorRef,
    private readonly jobCardDetailService: JobidCardDetailService,
    private readonly detailComponentService: JobidCardDetailHelperService,
    private readonly discussionsService: DiscussionsFacadeService,
    private readonly profileService: ProfileService,
    private readonly authService: AuthService,
    private readonly modalService: NgbModal,
    private readonly insightsService: InsightsService,
  ) {
    this.selectedStatus$ = this.jobCardDetailService.generalStatus$;
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedTab = event.url.split("?")[0].split("/").pop() ?? JOB_ID_CARD_DETAIL_ROUTES_NAMES.GENERAL;
        this.isPrerequisitesSection = event.url.includes(JOB_ID_CARD_DETAIL_ROUTES_NAMES.PREREQUISITES_DOCUMENTS);
        this.isDiscussionSection = event.url.includes(JOB_ID_CARD_DETAIL_ROUTES_NAMES.DISCUSSIONS);
        this.cdRef.detectChanges();
        this.isHistoryTab = this.selectedTab === JOB_ID_CARD_DETAIL_ROUTES_NAMES.HISTORY;
      }
    });
  }

  ngOnInit(): void {
    combineLatest([
      this.authService.jobPlanner$,
      this.profileService.affiliateConfig$
    ]).pipe(takeUntil(this.destroy)).subscribe(([isJobPlanner, affiliateConfig]) => {
      this.isJobPlanner = isJobPlanner;
      this.mobilizationEnabled = affiliateConfig.mobilizationEnabled;
      this.tabs = [
        {
          route: JOB_ID_CARD_DETAIL_ROUTES_NAMES.GENERAL,
          title: "prerequisitesEnum.generalDetails",
          display: true,
        },
        {
          route: JOB_ID_CARD_DETAIL_ROUTES_NAMES.PREREQUISITES_DOCUMENTS,
          title: "prerequisitesEnum.prerequisites",
          display: true,
          children: [
            {
              route: JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES.JOB_SCOPE,
              title: "prerequisitesEnum.jobScope",
              display: true,
            },
            {
              route: JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES.IMPACT,
              title: "prerequisitesEnum.impact",
              display: true,
            },
            {
              route: JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES.MATERIAL,
              title: "prerequisitesEnum.material",
              display: true,
            },
            {
              route: JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES.RESOURCES,
              title: "prerequisitesEnum.resources",
              display: true,
            },
            {
              route: JOB_ID_CARD_DETAIL_PREREQUISITES_DOCUMENTS_ROUTES_NAMES.MOBILIZATION,
              title: "prerequisitesEnum.mobilizations",
              display: this.mobilizationEnabled,
            },
          ],
        },
        {
          route: JOB_ID_CARD_DETAIL_ROUTES_NAMES.PRIMAVERA,
          title: "prerequisitesEnum.primavera",
          display: this.isJobPlanner,
        },
        {
          route: JOB_ID_CARD_DETAIL_ROUTES_NAMES.DISCUSSIONS,
          title: "prerequisitesEnum.discussion",
          display: true,
        },
        {
          route: JOB_ID_CARD_DETAIL_ROUTES_NAMES.HISTORY,
          title: "prerequisitesEnum.history",
          display: true,
        },
      ];
    });

    this.authService.admin$.pipe(takeUntil(this.destroy)).subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });

    this.authService.userName$.pipe(takeUntil(this.destroy)).subscribe((userName) => {
      this.userName = userName;
    });
    this.route.queryParamMap
      .pipe(
        combineLatestWith(this.route.paramMap, this.profileService.affiliate$),
        filter(([_queryParamMap, _paramMap, affiliate]) => affiliate.value !== ""),
        takeUntil(this.destroy),
      )
      .subscribe(([queryParamMap, paramMap, _currentAffiliate]) => {
        const jobId = paramMap.get("id") ?? "";
        const affiliate = paramMap.get("affiliate") ?? "";
        const emailDate = queryParamMap.get("emailDate");
        this.hasRoutedFromEmail = queryParamMap.get("origin") === JobidCardDetailComponent.EMAIL;
        if (this.hasRoutedFromEmail && emailDate) {
          this.insightsService.logEvent(InsightsService.JBID_EMAIL_EVENT, {"emailDate": emailDate, "id": jobId});
        }
        this.profileService.changeAffiliateFromURL(affiliate);
        this.jobCardDetailService.loadGeneralDetails(jobId, affiliate);
        this.jobCardDetailService.resetIsReadyFor2WF();
        this.discussionsService.setJobCardId(jobId);
      });

    this.jobCardDetailService.generalDetails$
      .pipe(filter(Boolean), combineLatestWith(this.profileService.affiliate$), takeUntil(this.destroy))
      .subscribe(([jobCardGeneralDetailsAggregate, affiliate]) => {
        this.setGeneralDetails(jobCardGeneralDetailsAggregate);
        this.jcmLink = `/job-card-master/detail/${affiliate.value}/${this.generalDetails?.jobCardMasterId}/general`;
        this.parentLink = `/jobid-card/detail/${affiliate.value}/${this.generalDetails?.parentId}/general`;
      });
    this.jobCardDetailService.prerequisites$.subscribe((prerequisites: Prerequisites) => {
      this.prerequisites = prerequisites;
    });

    this.jobCardDetailService.isModeReadOnly$.pipe(takeUntil(this.destroy)).subscribe((isReadOnly) => {
      this.readonly = isReadOnly;
    });
  }

  ngOnDestroy(): void {
    this.jobCardDetailService.generalDetailsFormService.resetForm();
    this.destroy.next();
    this.destroy.complete();
  }

  isPageInvalid(routeName: string) {
    return this.detailComponentService.isPageInvalid(routeName);
  }

  setGeneralDetails(jobCardGeneralDetails: JobCardGeneralDetailsAggregate) {
    if (this.generalDetails && !this.generalDetails.activationPending && jobCardGeneralDetails.activationPending) {
      this.modalService.open(PendingActivationModalComponent, { centered: true });
    }
    this.generalDetails = jobCardGeneralDetails;
    if (jobCardGeneralDetails.lastUpdateReadiness > 0) {
      this.lastReadinessUpdate = new Date(jobCardGeneralDetails.lastUpdateReadiness);
    } else {
      this.lastReadinessUpdate = null;
    }
    this.startDate = getDateFromString(jobCardGeneralDetails.startDate?.toString() || null);
    this.endDate = getDateFromString(jobCardGeneralDetails.endDate?.toString() || null);
    const selectedStatus = jobCardGeneralDetails?.generalStatus || GeneralStatus.DRAFT;
    this.editionDisabled = selectedStatus === GeneralStatus.CLOSED || selectedStatus === GeneralStatus.CANCELLED;
    this.isDraftOrReadyToActivate = JobidCardDetailService.isGeneralStatusDraftOrReadyToActivate(selectedStatus);
    this.changeGeneralStatus(selectedStatus.toString());
    this.hasDependency =
      (this.generalDetails.parentId !== null && this.generalDetails.parentId !== undefined) ||
      this.generalDetails.isLeader === true;
    this.dependency = this.generalDetails.isLeader === true ? "Leader" : "Dependent";

    this.isLeader = this.generalDetails.isLeader === true;
    this.isDependentOnParent = this.generalDetails.parentId !== null && this.generalDetails.parentId !== undefined;
    this.isIndependent = !this.isLeader && !this.isDependentOnParent;
    this.titleEditable =
      this.isDraftOrReadyToActivate &&
      !jobCardGeneralDetails.fromSap &&
      this.userName === this.generalDetails.jobLeader;
  }

  changeGeneralStatus(newGeneralStatus: string) {
    this.jobCardDetailService.changeGeneralStatus(<GeneralStatus>newGeneralStatus);
  }

  actionHandler(action: ActionType) {
    switch (action) {
      case "delete": {
        this.jobCardDetailService
          .handleJobCardDeletion()
          .pipe(filter((deletionDone) => deletionDone))
          .subscribe(() => {
            this.returnToJobCardList();
          });
        break;
      }

      case "edit": {
        this.jobCardDetailService.changeViewMode("edit");
        break;
      }

      case "validate": {
        this.jobCardDetailService.handleJobCardUpdate();
        break;
      }

      case "duplicate": {
        this.openJobCardDuplicationPopup().then((result) => {
          this.router
            .navigate([
              `/${APP_ROUTES_NAMES.JOB_ID_CARD}/detail/${this.profileService.getAffiliate().value}/${result}/${
                JOB_ID_CARD_DETAIL_ROUTES_NAMES.GENERAL
              }`,
            ])
            .catch((error) => {
              console.error(error);
            });
        });
        break;
      }

      case "cancel":
      default: {
        this.jobCardDetailService.handleJobCardCancel();
        break;
      }
    }
  }

  returnToJobCardList() {
    this.router.navigate(["/" + APP_ROUTES_NAMES.JOB_ID_CARD + "/" + JOB_ID_CARD_ROUTES_NAMES.LIST]);
  }

  getPrerequisitesGlobalReadiness(routeName: string): string | undefined {
    let readinessList: Array<string | undefined> = [];

    if (routeName === JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.JOB_SCOPE) {
      const jobScopeDTO = this.prerequisites?.jobScopeDTO;
      readinessList = [
        this.checkReadinessOnProperties(jobScopeDTO?.methodStatement, jobScopeDTO?.methodStatementReadiness),
        this.checkReadinessOnProperties(jobScopeDTO?.komJra, jobScopeDTO?.komReadiness),
        this.checkReadinessOnProperties(jobScopeDTO?.rfmNumber, jobScopeDTO?.rfmNumberReadiness),
        this.checkReadinessOnProperties(jobScopeDTO?.wellIntervention, jobScopeDTO?.wellInterventionReadiness),
        this.checkReadinessOnProperties(jobScopeDTO?.costBudget, jobScopeDTO?.costBudgetReadiness),
      ];
    } else if (routeName === JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.IMPACT) {
      const impactDTO = this.prerequisites?.impactDTO;
      readinessList = [
        this.checkReadinessOnProperties(impactDTO?.impactPermitToWork, impactDTO?.permitToWorkReadiness),
        this.checkReadinessOnProperties(impactDTO?.impactConfinedSpace, impactDTO?.confinedSpaceReadiness),
        this.checkReadinessOnProperties(impactDTO?.impactRadiography, impactDTO?.radiographyReadiness),
        this.checkReadinessOnProperties(impactDTO?.impactDivingRov, impactDTO?.divingRovReadiness),
        this.checkReadinessOnProperties(impactDTO?.impactHydroPressure, impactDTO?.hydroPressureTestReadiness),
        this.checkReadinessOnProperties(impactDTO?.regulatorApproval, impactDTO?.regulatorApprovalReadiness),
        this.checkReadinessOnProperties(impactDTO?.impactShortfall, impactDTO?.shortFallImpactReadiness),
        this.checkReadinessOnProperties(impactDTO?.impactSimops, impactDTO?.simopsReadiness),
        this.checkReadinessOnProperties(impactDTO?.hazardousDisplayed, impactDTO?.hazardousClassifiedAreaReadiness),
      ];
    } else if (routeName === JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.MATERIAL) {
      return ReadinessModel.globalReadiness(this.prerequisites?.materialListDTO.materials);
    } else if (routeName === JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.RESOURCES) {
      const resourceDTO = this.prerequisites?.resourceDTO;
      const scaffoldingList = resourceDTO.resourceScaffoldingListDTO;
      const liftingList = resourceDTO?.resourceLiftingListDTO;
      const temporaryEquipmentList = resourceDTO?.resourceTemporaryEquipmentListDTO;
      const isolationList = resourceDTO?.resourceIsolationListDTO;
      const inhibitionList = resourceDTO?.resourceInhibitionListDTO;
      const coreCrewList = resourceDTO?.resourceCoreCrewAssistanceListDTO;
      const contractorList = resourceDTO?.resourceContractorListDTO;
      const visaReadiness = resourceDTO?.resourceInvitationDTO;
      const logisticsList = resourceDTO?.resourceLogisticListDTO;

      readinessList = [
        ReadinessModel.globalReadinessAndCheckDisplayed(scaffoldingList),
        ReadinessModel.globalReadinessAndCheckDisplayed(liftingList),
        ReadinessModel.globalReadinessAndCheckDisplayed(temporaryEquipmentList),
        ReadinessModel.globalReadinessAndCheckDisplayed(isolationList),
        ReadinessModel.globalReadinessAndCheckDisplayed(inhibitionList),
        ReadinessModel.globalReadinessAndCheckDisplayed(coreCrewList),
        ReadinessModel.globalReadinessAndCheckDisplayed(contractorList),
        ReadinessModel.globalReadinessAndCheckDisplayed(logisticsList),
        ReadinessModel.globalReadinessAndCheckDisplayed(visaReadiness),
      ];
    } else if (routeName === JOB_ID_CARD_DETAIL_ALL_ROUTES_NAMES.MOBILIZATIONS && this.mobilizationEnabled) {
      readinessList = [this.prerequisites.mobilizationsDTO?.resourcesManagementReadiness];
    }
    return ReadinessModel.getGlobalReadiness(readinessList);
  }

  private checkReadinessOnProperties(active: boolean | undefined, readiness: string | undefined) {
    return active ? readiness : undefined;
  }

  private openJobCardDuplicationPopup(): Promise<any> {
    const modal = this.modalService.open(DuplicationDialogComponent, { centered: true });
    return modal.result;
  }

  truncate(text: string | undefined): string {
    if (!text) {
      return "";
    }
    return text.length > 15 ? text.substring(0, 15) + "..." : text;
  }

  protected readonly of = of;
}
