import gql from "graphql-tag";

const GET_JCM_GENERAL_DETAILS_QUERY = gql`
  query ($affiliate: String, $id: Int) {
    getJobCardMasterById(affiliate: $affiliate, id: $id) {
      id
      title
      affiliate
      mainSite
      sites
      startDate
      endDate
      owner
      creationDate
      lastModified
      isCampaign
      generalStatus
      actualStatus
      shortfall
      shortfallWaterInjection
      budgets
      pob
      specificAccommodation
      isShutDownDuringCampaign
      shutDownDate
      shutDownDuration
      revisionCode
      isScopeFrozen
    }
  }
`;

export { GET_JCM_GENERAL_DETAILS_QUERY };
